import { Injectable, OnDestroy } from '@angular/core';
import { AuthGuardService } from 'src/app/guards/auth-guard.service';
import { SvStorageService } from 'src/app/services/sv-storage-listener.service';
import { Subject } from 'rxjs';
import { SvFlightLeg, SvFlightLegKey } from 'src/app/model/sv-flight-leg';
import { isNullOrUndefined } from 'src/datatable/utils';
import { SvDialogService } from 'src/app/services/services-dialog/sv-dialog.service';
import { SvUtilities } from 'src/app/helper/sv-utilities';
import * as _ from 'lodash';
import { debounceTime } from 'rxjs/operators';
// import { isEmpty } from 'rxjs/operators';
// import { differenceWith, pull, isEqual } from 'lodash';
// import { forOwn } from 'lodash/forOwn';
// or
// import forOwn from 'lodash/forOwn';

@Injectable({ providedIn: 'root' })
export class SvReminderService implements OnDestroy {

    private openDialogSubject = new Subject<SvFlightLeg>();
    public openDialog$ = this.openDialogSubject.asObservable();

    // variables for reminders that do not need to be displayed yet
    private reminderUpdateInterval: any;
    private pendingReminders = new Array<SvReminderFlight>();
    private pendingRemoveSubject = new Subject<SvReminderFlight>();
    public pendingRemove$ = this.pendingRemoveSubject.asObservable();
    private pendingAddSubject = new Subject<SvReminderFlight>();
    public pendingAdd$ = this.pendingAddSubject.asObservable();
    // variables for reminders that need to be displayed
    private activeReminders = new Array<SvReminderFlight>();
    private activeRemoveSubject = new Subject<SvReminderFlight>();
    public activeRemove$ = this.activeRemoveSubject.asObservable();
    private activeAddSubject = new Subject<SvReminderFlight>();
    public activeAdd$ = this.activeAddSubject.asObservable();
    // there will only be removes and addes for reminders; the worst case-senario for a message that had the note updated is the
    // toast will be removed and then it will be added to pending because the time will always change if a reminder is updated

    private reminderRemovedSubject = new Subject<SvFlightLegKey>();
    public reminderRemoved$ = this.reminderRemovedSubject.asObservable();
    private reminderAddedSubject = new Subject<SvFlightLegKey>();
    public reminderAdded$ = this.reminderAddedSubject.asObservable();


    constructor(private authGuardService: AuthGuardService,
        private svDialogService: SvDialogService,
        private storageService: SvStorageService) {
    }
    ngOnDestroy() {
        this.openDialogSubject.complete();
        this.pendingRemoveSubject.complete();
        this.pendingAddSubject.complete();
        this.activeRemoveSubject.complete();
        this.activeAddSubject.complete();
        this.reminderRemovedSubject.complete();

        this.stopWsInterval();
    }
    startWsInterval() {
        // do nothing if the timer has already been started
        if (isNullOrUndefined(this.reminderUpdateInterval)) {
            // cal the first time
            this.updateReminders();
            // set the interval for subsequent calls
            this.resetWsInterval(30_000);
        }
    }
    private resetWsInterval(interval: number) {
        // console.log(`changing interval to ${interval}`);
        // do nothing if the timer has already been started
        this.stopWsInterval();
        this.reminderUpdateInterval = setInterval(() => { this.updateReminders(); }, interval);
    }
    public updateReminders() {
        this.svDialogService.retrieveReminders(this.authGuardService.svUserInfo.empId).pipe(debounceTime(250)).subscribe(
            (data) => {
                // console.log('reminders', data);

                // only process updates if the returned object is valid
                if (!isNullOrUndefined(data)) {
                    // check if the active reminders has data before anything is modified
                    const prevData = this.activeReminders.length > 0;
                    // process pending reminders
                    this.updateRecords(data.pendingReminders, this.pendingReminders, this.pendingRemoveSubject, this.pendingAddSubject);
                    // process active reminders
                    this.updateRecords(data.activeReminders, this.activeReminders, this.activeRemoveSubject, this.activeAddSubject);
                    // check if the active reminders have data after everything has been modified
                    const curData = this.activeReminders.length > 0;

                    if (prevData && !curData) {
                        // change the interval back to 30 seconds if the active reminders previously had data, but now they do not
                        this.resetWsInterval(30_000);
                    } else if (!prevData && curData) {
                        // change the interval to 10 seconds if the active reminders previously did not have data, but now they do
                        this.resetWsInterval(10_000);
                    }
                }

                // 1. clear toast messages no longer needed
                //    this.messageService.clear('flight key')
                // 2. update the reminders variable
                //    this.reminders = data.activeReminders
                // 3. add additional toast messages
                //    this.messageService.add('flight key')
            },
            (error) => { console.log('reminders error', error); },
            () => { /*console.log('retrieved reminders');*/ }
        );
    }
    private updateRecords(latestReminders: SvReminderFlight[],
        reminders: Array<SvReminderFlight>,
        removeSubject: Subject<SvReminderFlight>,
        addSubject: Subject<SvReminderFlight>) {

        if (SvUtilities.isEmpty(latestReminders) && SvUtilities.isEmpty(reminders)) {
            // there are no current or previous reminders; therefore do nothing
        } else {
            // these are the values in reminders, but not in latestReminders; these have been removed
            // const removedReminders = _.differenceWith(reminders, latestReminders);
            _.differenceWith(reminders, latestReminders, _.isEqual).forEach(value => {
                // console.log('removing reminder', value);
                // remove the element from reminders
                _.pull(reminders, value);
                // notify the element has been removed
                removeSubject.next(value);
            });
            // these are the values in latestReminders, but not in reminders; these are new
            // const addedReminders = _.differenceWith(latestReminders, reminders);
            _.differenceWith(latestReminders, reminders, _.isEqual).forEach(value => {
                // console.log('adding reminder', value);
                // add the element to reminders
                reminders.push(value);
                // notify the element has been added
                addSubject.next(value);
            });
        }
    }
    private stopWsInterval() {
        if (!isNullOrUndefined(this.reminderUpdateInterval)) {
            clearInterval(this.reminderUpdateInterval);
            this.reminderUpdateInterval = null;
        }
    }

    public openReminderDialog(flight: SvFlightLeg) {
        this.openDialogSubject.next(flight);
    }
    public reminderSetForFlight(flightKey: SvFlightLegKey): boolean {
        // check if this flight has a pending reminder
        for (const reminder of this.pendingReminders) { if (_.isEqual(reminder.flight, flightKey)) { return true; } }
        // check if this flight has an active reminder
        for (const reminder of this.activeReminders) { if (_.isEqual(reminder.flight, flightKey)) { return true; } }
        // no reminder is set for this flight
        return false;
    }


    public reminderRemoved(flight: SvFlightLegKey) {
        this.reminderRemovedSubject.next(flight);
    }
    public reminderAdded(flight: SvFlightLegKey) {
        this.reminderAddedSubject.next(flight);
    }
}

/* objects to communicate with the backend */

export class SvReminderData {
    activeReminders: SvReminderFlight[];
    pendingReminders: SvReminderFlight[];
}

export class SvCreateReminder {
    flight: SvFlightLegKey;
    note: string;
    minutes: number;
    empId: number;

    constructor(flight: SvFlightLegKey, note: string, minutes: number, empId: number) {
        this.flight = flight;
        this.note = note;
        this.minutes = minutes;
        this.empId = empId;
    }
}

export class SvReminderFlight {
    flight: SvFlightLegKey;
    note: string;
    reminderTime: number;
    loading: boolean;

    /* this value is not used by the backend */
    working: boolean;

    constructor(flight: SvFlightLegKey, note: string, reminderTime: number) {
        this.flight = flight;
        this.note = note;
        this.reminderTime = reminderTime;
    }
}

export class SvReminderAckFlight {
    flight: SvFlightLegKey;
    empId: number;

    constructor(flight: SvFlightLegKey, empId: number) {
        this.flight = flight;
        this.empId = empId;
    }
}
