import { Component } from '@angular/core';

@Component({
  selector: 'sv-content',
  templateUrl: './sv-content.component.html'
})
export class SvContentComponent {

  constructor() { }
}
