import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { WS_BASE } from '../../services-ws/sv-ws-constants';
import { SvFlightLegKey } from 'src/app/model/sv-flight-leg';
import { SvReminderAckFlight, SvReminderData, SvCreateReminder, SvReminderFlight } from 'src/app/components/sv-reminder/sv-reminder.services';
import { SvSupervisorNoteFlight } from 'src/app/dialog/sv-supervisor-note-dialog';
import { SvTransferFlightsRequest, SvTransferStatus, SvTransferHistory, SvRestoreFlightsRequest } from 'src/app/model/sv-transfer-models';
import { isNullOrUndefined } from 'util';
import { map } from 'rxjs/operators';

const WS_ROUTE_TEMPERATURE = '/temperature-controller';

const WS_ROUTE_REMINDER_BASE = '/reminder-controller';
const WS_ROUTE_REMINDER_RETRIEVE_SINGLE = '/retrieve-single';
const WS_ROUTE_REMINDER_RETRIEVE_ALL = '/retrieve-all';
const WS_ROUTE_REMINDER_CREATE = '/create';
const WS_ROUTE_REMINDER_REMOVE = '/remove';
const WS_ROUTE_REMINDER_SNOOZE = '/snooze';
const WS_ROUTE_REMINDER_ACKNOWLEDGE = '/acknowledge';

const WS_ROUTE_SUPERVISOR_NOTE_BASE = '/supervisor-note-controller';
const WS_ROUTE_RSUPERVISOR_NOTE_SAVE = '/save';

const WS_ROUTE_TRANSFER_BASE = '/transfer-flights';
const WS_ROUTE_TRANSFER_START = '/transfer-start';
const WS_ROUTE_RESTORE_START = '/restore-start';
const WS_ROUTE_TRANSFER_STATUS_HISTORY_ID = '/transfer-status-history-id';
const WS_ROUTE_TRANSFER_STATUS_EMPLOYEE_ID = '/transfer-status-employee-id';
const WS_ROUTE_RESTORE_STATUS_HISTORY_ID = '/restore-status-history-id';
const WS_ROUTE_TRANSFER_HISTORY = '/fetch-history';


@Injectable({ providedIn: 'root' })
export class SvDialogService {

  constructor(private http: HttpClient) { }

  public retrieveTemperature(station: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('station', station);

    const dataRequest = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }), params: httpParams };

    // TODO: Change this to be passed as a subscription with callbacks
    return this.http.get<any>(`${WS_BASE}${WS_ROUTE_TEMPERATURE}`, dataRequest);
  }

  public retrieveReminder(flight: SvFlightLegKey, empId: number) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('empId', empId.toString());
    httpParams = httpParams.append('flightNumber', flight.flightNumber.toString());
    httpParams = httpParams.append('depStation', flight.depStation);
    httpParams = httpParams.append('originDate', flight.originDate.toString());
    httpParams = httpParams.append('dupDepNumber', flight.dupDepNumber.toString());

    const dataRequest = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }), params: httpParams };
    return this.http.get<SvReminderFlight>(`${WS_BASE}${WS_ROUTE_REMINDER_BASE}${WS_ROUTE_REMINDER_RETRIEVE_SINGLE}`, dataRequest);
  }
  public retrieveReminders(empId: number) {
    let httpParams = new HttpParams();
    // TODO send flight information
    // httpParams = httpParams.append('flight', JSON.stringify(flight));
    httpParams = httpParams.append('empId', empId.toString());

    const dataRequest = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }), params: httpParams };
    return this.http.get<SvReminderData>(`${WS_BASE}${WS_ROUTE_REMINDER_BASE}${WS_ROUTE_REMINDER_RETRIEVE_ALL}`, dataRequest);
  }
  public removeReminder(reminder: SvCreateReminder) {
    const dataRequest = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this.http.post<SvCreateReminder>(`${WS_BASE}${WS_ROUTE_REMINDER_BASE}${WS_ROUTE_REMINDER_REMOVE}`, reminder, dataRequest);
  }
  public createReminder(reminder: SvCreateReminder) {
    const dataRequest = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this.http.post<SvCreateReminder>(`${WS_BASE}${WS_ROUTE_REMINDER_BASE}${WS_ROUTE_REMINDER_CREATE}`, reminder, dataRequest);
  }
  public snoozeReminder(reminder: SvCreateReminder) {
    const dataRequest = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this.http.post<SvCreateReminder>(`${WS_BASE}${WS_ROUTE_REMINDER_BASE}${WS_ROUTE_REMINDER_SNOOZE}`, reminder, dataRequest);
  }
  public acknowledgeReminder(flight: SvReminderAckFlight) {
    const dataRequest = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this.http.post<SvReminderAckFlight>(`${WS_BASE}${WS_ROUTE_REMINDER_BASE}${WS_ROUTE_REMINDER_ACKNOWLEDGE}`, flight, dataRequest);
  }

  public saveSupervisorNote(flight: SvSupervisorNoteFlight) {
    const dataRequest = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this.http.post<SvReminderAckFlight>(`${WS_BASE}${WS_ROUTE_SUPERVISOR_NOTE_BASE}${WS_ROUTE_RSUPERVISOR_NOTE_SAVE}`, flight, dataRequest);
  }


  /* **** methods to perform initial & restore transfer and to view transfer history **** */

  public fetchTransferHistory(empId: number) {
    const httpParams = new HttpParams().append('employeeId', empId.toString());
    const dataRequest = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }), params: httpParams };
    return this.http.get<SvTransferHistory[]>(`${WS_BASE}${WS_ROUTE_TRANSFER_BASE}${WS_ROUTE_TRANSFER_HISTORY}`, dataRequest);
  }
  public transferFlights(flightRequest: SvTransferFlightsRequest) {
    const dataRequest = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this.http.post<number>(`${WS_BASE}${WS_ROUTE_TRANSFER_BASE}${WS_ROUTE_TRANSFER_START}`, flightRequest, dataRequest);
  }
  public transferStatusEmployeeId(employeeId: number, startTime?: number) {
    // set the employee id and start time
    const httpParams = new HttpParams().append('employeeId', employeeId.toString()).append('startTime', startTime.toString());
    const dataRequest = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }), params: httpParams };
    return this.http.get<SvTransferStatus>(`${WS_BASE}${WS_ROUTE_TRANSFER_BASE}${WS_ROUTE_TRANSFER_STATUS_EMPLOYEE_ID}`, dataRequest);
  }
  public transferStatusHistoryId(employeeId: number, transferId: number) {
    // set the transfer history id
    const httpParams = new HttpParams().append('employeeId', employeeId.toString()).append('transferHistoryId', transferId.toString());
    const dataRequest = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }), params: httpParams };
    return this.http.get<SvTransferStatus>(`${WS_BASE}${WS_ROUTE_TRANSFER_BASE}${WS_ROUTE_TRANSFER_STATUS_HISTORY_ID}`, dataRequest);
  }
  public restoreFlights(restoreRequest: SvRestoreFlightsRequest) {
    const dataRequest = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this.http.post<void>(`${WS_BASE}${WS_ROUTE_TRANSFER_BASE}${WS_ROUTE_RESTORE_START}`, restoreRequest, dataRequest);
  }
  public restoreStatusHistoryId(employeeId: number, transferHistoryId: number) {
    const httpParams = new HttpParams().append('employeeId', employeeId.toString()).append('transferHistoryId', transferHistoryId.toString());
    const dataRequest = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }), params: httpParams };
    return this.http.get<SvTransferStatus>(`${WS_BASE}${WS_ROUTE_TRANSFER_BASE}${WS_ROUTE_RESTORE_STATUS_HISTORY_ID}`, dataRequest);
  }
}
