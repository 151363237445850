import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';


@Injectable({ providedIn: 'root' })
export class SvOpenTransferFlightService {

  private openTransferDialogSource = new Subject();
  openedTransferDialog$ = this.openTransferDialogSource.asObservable();

  constructor() { }

  openTransferDialog() { this.openTransferDialogSource.next(); }
}
