
import { HttpParams } from '@angular/common/http';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { isNullOrUndefined } from 'util';
import { connectableObservableDescriptor } from 'rxjs/internal/observable/ConnectableObservable';
import { DomSanitizer } from '@angular/platform-browser';
import { SecurityContext } from '@angular/core';

export const ROUTE_CONSTANTS = {
    maxBeforeEtd: 1440,
    minBeforeEtd: 0,
    maxAfterEtd: 1440,
    minAfterEtd: 0,
    defaultBeforeEtd: 120,
    defaultAfterEtd: 0,
    /** TODO add all default values for desk, departure station; should just be '' */
    defaultInputField: '',
    minFlightNumber: 1,
    maxFlightNumber: 9999,
    defaultStartFlightNumber: 1,
    defaultEndFlightNumber: 9999,
    defaultDelayMinutes: 0,
    defaultSortColumn: 'ETD',
    defaultSortProperty: 'latestDepTime',
    defaultSortDirection: 'asc',
    defaultShowCanceled: false,
    defaultShowRowBlock: false, //TRKR851
    defaultShowFltKeys: false, //TRKR870
    defaultShowMELs: false, //TRKR872
    defaultRowColor: ''
};

export class SvRouteData {
    etdBefore: number;
    etdAfter: number;
    desks: string;
    departureStation: string;
    arrivalStation: string;
    equipmentType: string;
    equipmentTail: string;
    flightNumberStart: number;
    flightNumberEnd: number;
    delayMinutes: number;
    sortColumn: string;
    sortProperty: string;
    sortDirection: string;
    showCanceled: boolean;
    showRowBlock: boolean; //TRKR851
    showFltKeys: boolean; //TRKR870
    showMELs: boolean; //TRKR872
    rowColor: string;
}

class RouteId {
    httpId: string;
    navigationId: string;
    defaultConstant: string;
 
    constructor(httpId: string, navigationId?: string, defaultConstant?: string) {
        this.httpId = httpId;
        if (navigationId) { this.navigationId = navigationId; }
        if (defaultConstant) { this.defaultConstant = defaultConstant; }
    }
}

const ROUTE_ID = {
    etdBefore: new RouteId('etdBef', '1', 'defaultBeforeEtd'),
    etdAfter: new RouteId('etdAft', '2', 'defaultAfterEtd'),
    desks: new RouteId('desks', '3', 'defaultInputField'),
    departureStation: new RouteId('depSta', '4', 'defaultInputField'),
    arrivalStation: new RouteId('arrSta', '5', 'defaultInputField'),
    equipmentType: new RouteId('eqpType', '6', 'defaultInputField'),
    equipmentTail: new RouteId('eqpTail', '7', 'defaultInputField'),
    flightNumberStart: new RouteId('fltStart', '8', 'defaultStartFlightNumber'),
    flightNumberEnd: new RouteId('fltEnd', '9', 'defaultEndFlightNumber'),
    delayMinutes: new RouteId('delMin', '10', 'defaultDelayMinutes'),
    sortColumn: new RouteId('sortCol', '11', 'defaultSortColumn'),
    sortProperty: new RouteId('sortProp', '12', 'defaultSortProperty'),
    sortDirection: new RouteId('sortDir', '13', 'defaultSortDirection'),
    showCanceled: new RouteId('showCxd', '14', 'defaultShowCanceled'),
    rowColor: new RouteId('rowColor', '15', 'defaultRowColor'),
    showRowBlock: new RouteId('showRblk', '16', 'defaultShowRowBlock'), //TRKR851
    showFltKeys: new RouteId('showFkeys', '17', 'defaultShowFltKeys'), //TRKR851
    showMELs: new RouteId('showMELs', '18', 'defaultShowMELs'), //TRKR872
    flightStartIndex: new RouteId('flightStartIndex'),
    flightRowCount: new RouteId('flightRowCount'),
    flightTimezone: new RouteId('flightTimezone')
};

export class SvRouteParser {

    public static buildFlightHttpParams(routeSnapshot: ActivatedRouteSnapshot,
        flightStartIndex: number, flightRowCount: number, flightTimezone: string): HttpParams {

        let requestParams = SvRouteParser.buildHttpParams(routeSnapshot);
        // add additional flight parameters
        requestParams = requestParams.set(ROUTE_ID.flightStartIndex.httpId, flightStartIndex.toString());
        requestParams = requestParams.set(ROUTE_ID.flightRowCount.httpId, flightRowCount.toString());
        requestParams = requestParams.set(ROUTE_ID.flightTimezone.httpId, flightTimezone);

        // console.log('Build HTTP Params');
        // console.dir(requestParams);

        return requestParams;
    }
    /**
     * Two simple ways to create parameters:
     *
     * 1.
     * let requestParams = new HttpParams();
     * requestParams = requestParams.set('email', 'emailId');
     * requestParams = requestParams.set('password', 'password');
     *
     * 2.
     * const requestParams = new HttpParams({
     *   fromObject: {
     *     'email': 'emailId',
     *     'password': 'password'
     *   }
     * });
     */
    private static buildHttpParams(routeSnapshot: ActivatedRouteSnapshot): HttpParams {
        const svRouteData = this.parseRoute(routeSnapshot);

        let requestParams = new HttpParams();
        for (const key in ROUTE_ID) {
            if (!isNullOrUndefined(key)) {
                const value = svRouteData[key];
                if (!isNullOrUndefined(value)) {
                    requestParams = requestParams.set(ROUTE_ID[key].httpId, value.toString());
                }
            }
        }
        // console.log('Build HTTP Params');
        // console.dir(requestParams);

        return requestParams;
    }

    public static parseRoute(routeSnapshot?: ActivatedRouteSnapshot): SvRouteData {
        // console.log('routeSnapshot', routeSnapshot);
        const routeSnapshotMap = routeSnapshot ? routeSnapshot.queryParams : undefined;

        const svRouteData = new SvRouteData();
        for (const key in ROUTE_ID) {
            if (!isNullOrUndefined(key)) {
                const routeIdKey = ROUTE_ID[key];
                // only add if a default value exists
                if (!isNullOrUndefined(routeIdKey.defaultConstant)) {
                    if (!isNullOrUndefined(routeSnapshotMap)) {
                        svRouteData[key] = this.getValue(routeSnapshotMap[routeIdKey.navigationId], ROUTE_CONSTANTS[routeIdKey.defaultConstant]);
                    } else {
                        svRouteData[key] = this.getValue(undefined, ROUTE_CONSTANTS[routeIdKey.defaultConstant]);
                    }
                }
            }
        }
        // console.log('Parse Route Data');
        // console.dir(svRouteData);

        return svRouteData;
    }
    private static getValue(routeValue: string, defaultValue: any): any {
        // console.log('getValue', routeValue, typeof routeValue, defaultValue, typeof defaultValue);
        // check if the default value is a number
        if (typeof defaultValue === 'number') {
            if (!isNullOrUndefined(routeValue)) { return parseInt(routeValue, 10); }
        }
        // check if the default value is a boolean
        if (typeof defaultValue === 'boolean') {
            if (!isNullOrUndefined(routeValue)) {
                if (typeof routeValue === 'boolean') { return routeValue; }
                else if (typeof routeValue === 'string') { return routeValue === 'true'; }
            }
        }
        else if (!isNullOrUndefined(routeValue)) { return routeValue; }
        return defaultValue;
    }

	//
	// Commented out under DXC-coverity
	//

    //   public static createUrl(svRouteData: SvRouteData, router: Router): string {
    //     const navigationParams = this.builNavigationCommand(svRouteData);
    //     console.log('==== navigation params', navigationParams);
    //     const url = router.createUrlTree([], { queryParams: navigationParams }).toString();
    //     console.log('==== navigation url', url);
    //     return url;
    //           }


	//
	// DXC-Coverity
	//
      public static createUrl(svRouteData: SvRouteData, router: Router, sanitizer: DomSanitizer): string {

        const navigationParams = this.builNavigationCommand(svRouteData);
        const sanitizedParams = this.sanitizeParams(navigationParams);

        //console.log('.navigationParams: ', navigationParams);
        
        const url = router.createUrlTree([], { queryParams: sanitizedParams }).toString();
        console.log('>> navigation url before: ', url);
								
		    const regex = /%252C/gi; //parametersIssue
		    const urlConverted = url.replace(regex, '%2C');		

//parametersIssue  const sanitizedUrl = sanitizer.sanitize(SecurityContext.URL , url) ; 
		    const sanitizedUrl = sanitizer.sanitize(SecurityContext.URL , urlConverted) ; //parametersIssue 
        console.log('>> sanitizedUrl: ', sanitizedUrl);
        //console.log('navigation url after', this.trustedDashboardUrl);

        return sanitizedUrl ;
      }

      // Function to sanitize and filter parameters
      private static sanitizeParams(params) {
        const sanitizedParams = {};

        for (const key in params) {
          if (params.hasOwnProperty(key)) {
            const value = params[key];

            sanitizedParams[key] = encodeURIComponent(value);
          }
        }

        return sanitizedParams;
      }

      private static builNavigationCommand(svRouteData: SvRouteData): {} {
        // set the filter parameters for navigation
        const navigationVar: any = {};
        for (const key in ROUTE_ID) {
            if (!isNullOrUndefined(key)) {
                const routeIdKey = ROUTE_ID[key];
                // only add if a default value exists
                if (!isNullOrUndefined(routeIdKey.defaultConstant)) {
                    const value = svRouteData[key];
                    // console.log(`Navigation ${key} - ${value}`);
                    if (!isNullOrUndefined(value) && value !== ROUTE_CONSTANTS[routeIdKey.defaultConstant]) {
                        navigationVar[routeIdKey.navigationId] = value;
                    }
                }
            }
        }
        // console.log('Navigation Command');
        // console.dir(navigationVar);

        return navigationVar;
    }
}
