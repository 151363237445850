import { Component } from '@angular/core';

@Component({
  selector: 'sv-footer',
  templateUrl: './sv-footer.component.html'
})
export class SvFooterComponent {

  constructor() { }
}
