import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { WS_BASE } from './sv-ws-constants';
import { Router } from '@angular/router';
import { PingAuthenticationService } from '@techops-ui/ping-authentication';  //TRKR828a

const WS_ROUTE = '/pingfederate-controller/logout';

@Injectable({ providedIn: 'root' })
export class SvLogOut {

  constructor(private http: HttpClient,
    private authService: PingAuthenticationService, //TRKR828a
    private router: Router) { }

  logout() {
    console.log('SvLogOut');
    
    // ----------- Commented out under TRKR828a (pingfederate integration) -----------
    // const dataRequest = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    // this.http.get<any>(`${WS_BASE}${WS_ROUTE}`, dataRequest).subscribe(
    //   data => {
    //     console.log('sv-logout-success', data);
    //     localStorage.clear();
    //     // go to the SSO page to complete the logout
    //     if (data && data.value) { window.location.href = data.value; }
    //     // just refresh the page
    //     else { window.location.reload(); }
    //   },
    //   error => { console.log('sv-logout-error', error); },
    //   () => { console.log('sv-logout-complete'); }
    // );

    
    this.authService.startSignOut(); //TRKR828a
    
  }
}
