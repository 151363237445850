import { Pipe, PipeTransform } from '@angular/core';
import { formatDate, DecimalPipe } from '@angular/common';
import { SvTimeZoneService } from 'src/app/services-ws/sv-timezone.service';
import { isUndefined, isNullOrUndefined } from 'util';

/**
 * Returns a string formatted date object.
 * Usage:
 *   value | svDateStrPipe
 * Example:
 *   {{ new Date() | svDateStrPipe }}
 *   returns : formatted date
*/
@Pipe({ name: 'svLimitsNumberPipe' })
export class SvLimitsNumberPipe implements PipeTransform {

  constructor(private decimalPipe: DecimalPipe) { }

  transform(value: number): string {
    if (!isNullOrUndefined(value)) {
      if (value === 0) { return '.0'; }
      return this.decimalPipe.transform(value, '0.1-1');
    }

    return '';
  }
}

@Pipe({ name: 'svNoCommaNumberPipe' })
export class SvNoCommaNumberPipe implements PipeTransform {

  constructor(private decimalPipe: DecimalPipe) { }

  transform(value: number): string {
    if (!isNullOrUndefined(value)) {
      return this.decimalPipe.transform(value, '4.0-0').replace(/,/g, '');
    }

    return '';
  }
}
