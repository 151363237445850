import { Component, HostListener, ViewChild, ElementRef, AfterViewInit, SecurityContext } from '@angular/core';
import { Router, ActivatedRouteSnapshot, UrlTree } from '@angular/router';
import { SvRouteService } from 'src/app/services/sv-route.service';
import { ROUTE_CONSTANTS, SvRouteParser, SvRouteData } from 'src/app/helper/sv-route-parser';
import { SvDoubleSliderEtd } from 'src/app/model/sv-double-slider-etd';
import { SvDoubleSliderFlightNumber } from 'src/app/model/sv-double-slider-flight-number';
import { FormControl, Validators } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MenuItem } from 'primeng/api';
import { isNullOrUndefined, isNull } from 'util';
import { SvFlightColorService } from 'src/app/services-ws/sv-flight-color.service';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { AppRoutingModule } from 'src/app/app-routing.module';

export class ImmediateErrorStateMatcher implements ErrorStateMatcher {
  // isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
  isErrorState(control: FormControl | null): boolean {
    return !!(control && control.invalid && (control.dirty || control.touched));
  }
}

const DESK_REGEX = /^[0-9]{1,3}$/; /* RegExp('^[0-9]{1,3}$'); */
const DESK_PREFIX = 'L00';

@Component({
  selector: 'sv-sidebar',
  templateUrl: './sv-sidebar.component.html'
})
export class SvSidebarComponent {

  filterMenuitems: MenuItem[];

  /* variables for tracking classic filters */
  classicDeskFilterControl: FormControl;
  classicDeskFilterMatcher: ImmediateErrorStateMatcher;
  classicDepStationFilterControl: FormControl;
  classicDepStationFilterMatcher: ImmediateErrorStateMatcher;
  classicArrStationFilterControl: FormControl;
  classicArrStationFilterMatcher: ImmediateErrorStateMatcher;
  classicEquipTypeFilterControl: FormControl;
  classicEquipTypeFilterMatcher: ImmediateErrorStateMatcher;
  classicEquipTailFilterControl: FormControl;
  classicEquipTailFilterMatcher: ImmediateErrorStateMatcher;

  /* variables for tracking modern filters */
  svDoubleSliderEtd: SvDoubleSliderEtd;
  svDoubleSliderFlightNumber: SvDoubleSliderFlightNumber;

  delayMinutes: number;
  showCanceled: boolean;
  showRowBlock: boolean; //TRKR851
  showFltKeys: boolean; //TRKR870
  showMELs: boolean; //TRKR872

  selectedColors: string[] = [];

  @ViewChild('hiddenInputFocus') hiddenInputFocus: ElementRef;

  constructor(
  	private sanitizer: DomSanitizer, //DXC-Coverity
  	private titleService: Title, private router: Router, private svRouteService: SvRouteService, public svFlightColorService: SvFlightColorService) {
                
    // be notified of changes to the URL and reset the filter criteria
    this.svRouteService.activationEnded$
      .subscribe(snapshot => {
        // console.log('activation end sidbar');
        // console.dir(snapshot);

        // TODO only reset if the values have not been initialized before to prevent resetting the filters when the sorting has changed
        this.resetFilter(snapshot);
      });
    this.resetFilter();

    this.filterMenuitems = [
      // { label: 'Open in Same Window', icon: 'pi pi-external-link-square', command: () => { this.filterResults(true); } },
      { label: 'Open in New Tab', icon: 'pi pi-external-link', command: () => { this.filterResults(true); } }
    ];
  }

  resetFilterSelected() {
    this.resetFilter();
    this.filterResults();
  }
  private resetFilter(routeSnapshot?: ActivatedRouteSnapshot) {
    // get snapshot from the service if the reset icon was selected from the web
    // if (!routeSnapshot) { routeSnapshot = this.svRouteService.activationEndSnapshot; }
    // console.dir(routeSnapshot.queryParams);

    // call common method in this class to parse the URL
    const svRouteData = SvRouteParser.parseRoute(routeSnapshot);
    // console.log('resetFilter', routeSnapshot, svRouteData);
    // console.dir(svRouteData);

    // reset the filters based on the parsed data
    this.classicDeskFilterControl = new FormControl(svRouteData.desks, [Validators.pattern('[\\w\\s,;]*')]);
    this.classicDeskFilterMatcher = new ImmediateErrorStateMatcher();
    this.classicDepStationFilterControl = new FormControl(svRouteData.departureStation, [Validators.pattern('[\\w\\s,;]*')]);
    this.classicDepStationFilterMatcher = new ImmediateErrorStateMatcher();
    this.classicArrStationFilterControl = new FormControl(svRouteData.arrivalStation, [Validators.pattern('[\\w\\s,;]*')]);
    this.classicArrStationFilterMatcher = new ImmediateErrorStateMatcher();
    this.classicEquipTypeFilterControl = new FormControl(svRouteData.equipmentType, [Validators.pattern('[\\w\\s,;]*')]);
    this.classicEquipTypeFilterMatcher = new ImmediateErrorStateMatcher();
    this.classicEquipTailFilterControl = new FormControl(svRouteData.equipmentTail, [Validators.pattern('[\\w\\s,;]*')]);
    this.classicEquipTailFilterMatcher = new ImmediateErrorStateMatcher();

    this.svDoubleSliderEtd = new SvDoubleSliderEtd(svRouteData.etdBefore, ROUTE_CONSTANTS.minBeforeEtd, ROUTE_CONSTANTS.maxBeforeEtd,
      svRouteData.etdAfter, ROUTE_CONSTANTS.minAfterEtd, ROUTE_CONSTANTS.maxAfterEtd, this.filterSliderCallback);
    this.svDoubleSliderFlightNumber = new SvDoubleSliderFlightNumber(svRouteData.flightNumberStart, ROUTE_CONSTANTS.minFlightNumber,
      svRouteData.flightNumberEnd, ROUTE_CONSTANTS.maxFlightNumber);

    this.delayMinutes = svRouteData.delayMinutes;
    this.showCanceled = svRouteData.showCanceled;
    this.showRowBlock = svRouteData.showRowBlock; //TRKR851
    this.showFltKeys = svRouteData.showFltKeys; //TRKR870
    this.showMELs = svRouteData.showMELs; //TRKR872
    this.selectedColors = this.parseArrayFilter(svRouteData.rowColor);

    // check if the title can/should be updated
    if (routeSnapshot && routeSnapshot.routeConfig && routeSnapshot.routeConfig.path) {
      // console.log('filter title ', svRouteData, routeSnapshot);
      const pageTitle = AppRoutingModule.getTitleByPath(routeSnapshot.routeConfig.path);
      // console.log('title check', pageTitle, routeSnapshot.routeConfig.path);
      if (pageTitle) {
        // try to update the title with one of the filters
        let newTitle: string;
        newTitle = this.updateTitleText(svRouteData.desks, 'Desk(s)', newTitle);
        newTitle = this.updateTitleText(svRouteData.departureStation, 'Dep(s)', newTitle);
        newTitle = this.updateTitleText(svRouteData.arrivalStation, 'Arv(s)', newTitle);
        newTitle = this.updateTitleText(svRouteData.equipmentType, 'Equip(s)', newTitle);
        newTitle = this.updateTitleText(svRouteData.equipmentTail, 'Tail(s)', newTitle);
        // update the title if it was modified
        this.updateTitle(newTitle, pageTitle);
      }
    }
  }
  private updateTitleText(text: string, filterSuffix: string, newTitle: string): string {
    // console.log('getTitleText', text, filterSuffix, newTitle);
    if (isNullOrUndefined(text) || text === '') { return newTitle; }
    // console.log('found newTitle', text, filterSuffix, newTitle);
    return (newTitle ? newTitle + '; ' : '') + text + ' ' + filterSuffix;
  }
  private updateTitle(newTitle: string, pageTitle: string) {
    if (!isNullOrUndefined(newTitle)) {
      const prefixLength = 50 - pageTitle.length - 6; /* there 6 characters added between newTitle and pageTitle */
      if (newTitle.length > prefixLength) { newTitle = newTitle.substr(0, prefixLength) + '...'; }
      this.titleService.setTitle(newTitle + ' - ' + pageTitle);
    }
  }

  removeInvalidCharactersDesk(forceFormat?: boolean) {
    this.removeInvalidCharacters(this.classicDeskFilterControl, forceFormat, this.deskConverter);
  }
  private deskConverter = (value: string): string => {
    // console.log(`deskConverter ${value}`);
    if (DESK_REGEX.test(value)) {
      // console.log(`converting ${value}`);
      return DESK_PREFIX.substr(0, 4 - value.length) + value;
    }

    return value;
  }
  removeInvalidCharactersDepStation(forceFormat?: boolean) {
    this.removeInvalidCharacters(this.classicDepStationFilterControl, forceFormat);
  }
  removeInvalidCharactersArrStation(forceFormat?: boolean) {
    this.removeInvalidCharacters(this.classicArrStationFilterControl, forceFormat);
  }
  removeInvalidCharactersEquipType(forceFormat?: boolean) {
    this.removeInvalidCharacters(this.classicEquipTypeFilterControl, forceFormat);
  }
  removeInvalidCharactersEquipTail(forceFormat?: boolean) {
    this.removeInvalidCharacters(this.classicEquipTailFilterControl, forceFormat);
  }
  private removeInvalidCharacters(formControl: FormControl, forceFormat?: boolean, converterCallback?: (string) => string) {
    // console.log('removeInvalidCharacters', formControl, forceFormat, converterCallback);
    if (formControl.invalid || forceFormat) {
      const formValue: string = formControl.value;
      // const regexMatch: RegExpMatchArray = formValue.match('[\\w]+');
      const regexMatch: string[] = formValue.match(/[\w]+/g);
      // console.dir(regexMatch);
      let newValue = '';
      if (regexMatch) {
        regexMatch.forEach(element => {
          const convertedElement = converterCallback ? converterCallback(element) : element;
          if (!newValue) { newValue = convertedElement; }
          else { newValue += ',' + convertedElement; }
        });
      }
      // convert all characters to uppercase
      formControl.setValue(newValue.toUpperCase());
    }
  }

  validateDelayMinutes() {
    let resetDelay = false;
    if (this.delayMinutes) {
      const delayMinutesStr = this.delayMinutes.toString();
      // console.log(this.delayMinutes);
      // console.log(delayMinutesStr);
      if (delayMinutesStr) {
        const regexMatch = delayMinutesStr.trim().match(/\d/g);
        if (regexMatch) {
          // append all the numbers to create the updated delay
          let newValue = '';
          regexMatch.forEach(element => {
            if (!newValue) { newValue = element; }
            else { newValue += element; }
          });
          this.delayMinutes = Number.parseInt(newValue, 10);
        } else {
          // reset the delay since the input does not contain any numbers
          resetDelay = true;
        }
      } else { resetDelay = true; }
    } else { resetDelay = true; }

    if (resetDelay) { this.delayMinutes = ROUTE_CONSTANTS.defaultDelayMinutes; }
  }

  manageColorFilter(selectedColor: string) {
    // console.log(`selected color: ${selectedColor}`);
    const selectedIndex = this.selectedColors.indexOf(selectedColor);
    if (selectedIndex > -1) { this.selectedColors.splice(selectedIndex, 1); }
    else { this.selectedColors.push(selectedColor); }
    // console.log('selected colors:', this.selectedColors);
  }
  getColorTooltip(hexValue: string): string {
    // #{{uniqueColor}}
    if (!isNullOrUndefined(hexValue)) {
      switch (hexValue.toUpperCase()) {
        case '000000': return 'Normal flight';
        case '0000C0': return 'Flight is disabled';
        case 'FF0000': return 'Alert, task or error condition exists';
        case '00AA00': return 'Flight is closed out';
        case 'CC33FF': return 'Capped or restricted flight';
        case '828282': return 'Flight is inactive or not yet initialized';
        case 'FF8000': return 'Flight is currently being worked by ALP';
        case '8080FF': return 'Wide body';
        case '7B3F00': return 'Diverted/Stubbed flight';
      }
    }
  }

  /** global listener for when a user presses enter while focus is on an element */
  @HostListener('keydown.enter', ['$event'])
  keydownEnter(event) {
    this.keydown(event, false);
  }
  @HostListener('keydown.control.enter', ['$event'])
  keydownCtrlEnter(event) {
    this.keydown(event, true);
  }
  private keydown(event, newWindow: boolean) {
    // get the active input
    const activeElem: any = document.activeElement;
    // move focus out of the input so the rules can fix any invalid input
    this.hiddenInputFocus.nativeElement.focus();
    // filter the results
    this.filterResults(newWindow);
    // move focus back to the original element if it exists
    // ensure the element and the focus function exists before trying to call it
    if (!isNullOrUndefined(activeElem) && !isNullOrUndefined(activeElem.focus)) { activeElem.focus(); }
  }

  private filterSliderCallback = () => {
    this.filterResults();
  }
  filterResults(newWindow?: boolean) {
    // format all the inputs
    this.removeInvalidCharactersDesk(true);
    this.removeInvalidCharactersDepStation(true);
    this.removeInvalidCharactersArrStation(true);
    this.removeInvalidCharactersEquipType(true);
    this.removeInvalidCharactersEquipTail(true);

    const svRouteData = new SvRouteData();
    svRouteData.etdBefore = this.svDoubleSliderEtd.etdBeforeInput;
    svRouteData.etdAfter = this.svDoubleSliderEtd.etdAfterInput;
    svRouteData.desks = this.classicDeskFilterControl.value;
    svRouteData.departureStation = this.classicDepStationFilterControl.value;
    svRouteData.arrivalStation = this.classicArrStationFilterControl.value;
    svRouteData.equipmentType = this.classicEquipTypeFilterControl.value;
    svRouteData.equipmentTail = this.classicEquipTailFilterControl.value;
    svRouteData.flightNumberStart = this.svDoubleSliderFlightNumber.startInput;
    svRouteData.flightNumberEnd = this.svDoubleSliderFlightNumber.endInput;
    // svRouteData.sortColumn: string;
    // svRouteData.sortDirection: string;
    svRouteData.delayMinutes = this.delayMinutes;
    svRouteData.showCanceled = this.showCanceled;
    svRouteData.showRowBlock = this.showRowBlock; //TRKR851
    svRouteData.showFltKeys = this.showFltKeys; //TRKR870
    svRouteData.showMELs = this.showMELs; //TRKR872
    svRouteData.rowColor = this.createArrayFilter(this.selectedColors);
    // console.dir(svRouteData);

    // use the current path when filtering; just exclude instead of specifying the current path
    // this.router.navigate([`/${this.svRouteService.routeSnapshot.routeConfig.path}`, [navigationVar]]);
    // this.router.navigate(SvRouteParser.builNavigationCommand(svRouteData));

    // const navigationFilterResult = SvRouteParser.builNavigationCommand(svRouteData);
    // console.log('navigationFilterResult', svRouteData, navigationFilterResult);

 //DXC-Coverity   const routeStr = SvRouteParser.createUrl(svRouteData, this.router);
    const routeStr = SvRouteParser.createUrl(svRouteData, this.router, this.sanitizer); //DXC-Coverity
    // const routeStr = this.router.createUrlTree(SvRouteParser.builNavigationCommand(svRouteData)).toString();
    if (!isNullOrUndefined(newWindow) && newWindow) { window.open(routeStr, '_blank').focus(); }
    else { this.router.navigateByUrl(routeStr); }
  }

  private createArrayFilter(values: string[]): string {
    let result = '';
    if (!isNullOrUndefined(values)) {
      values.forEach(value => { result = (result === '') ? value : result.concat(`,${value}`); });
    }
    return result;
  }

  private parseArrayFilter(values: string): string[] {
    const result = [];
    if (!isNullOrUndefined(values)) {
      // do not add null, undefined, or empty elements
      values.split(/[\s,;]+/).forEach(value => { if (!isNullOrUndefined(value) && value !== '') { result.push(value); } });
    }
    return result;
  }
}
