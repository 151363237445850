import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class SvOpenTransferHistoryService {
  private openTransferHistoryDialogSource = new Subject();
  openedTransferHistoryDialog$ = this.openTransferHistoryDialogSource.asObservable();

  constructor() {}

  openTransferHistoryDialog() {
    this.openTransferHistoryDialogSource.next();
  }
}
