import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot } from '@angular/router';
import { AuthGuardService } from './auth-guard.service';
import { SvUserRole } from '../model/sv-user-info';
import { of, Observable } from 'rxjs';
import { isNullOrUndefined } from 'util';

@Injectable({ providedIn: 'root' })
export class RoleGuardService implements CanActivate {

  constructor(public authGuardService: AuthGuardService, private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> | boolean {
    // on the data property
    const role: SvUserRole = route.data.role;
    //TRKR828a return this.authGuardService.validateUser(
      return this.authGuardService.validateUserWithPingFederate(  //TRKR828a

      // successfully retrieved user information
      (): boolean => {
        if (!this.hasAccessSingle(role)) {
          this.router.navigate(['/errorunauthorizeduseraccess'], { skipLocationChange: true });
          return false;
        }
        return true;
      },
      // received error retrieving user information
      (): Observable<boolean> => {
        return of(true); //TRKR825 - temporary1
        // TODO how to handle situation where user role could not be retrieved
        this.router.navigate(['/errorretrievinguserinfo'], { skipLocationChange: true });
        return of(false);
      });
  }

  hasAccessSingle(checkRole: SvUserRole): boolean {
    //TRKR828a return true; //TRKR825 - temporary2
    console.log('made it to hasAccessSingle, checkRole=' + checkRole + '; authGuardService.svUserInfo.role=' + this.authGuardService.svUserInfo.role); //TRKR828a
    if (isNullOrUndefined(checkRole)) { return false; }
    // SUPPORT, ADMIN, LEAD, and USER can access pages/functionality restricted to USER access
    if (checkRole === SvUserRole.USER)  { return this.hasAccessMultiple([SvUserRole.SUPPORT, SvUserRole.ADMIN, SvUserRole.LEAD, SvUserRole.USER]); } //TRKR839 added Support
    // only SUPPORT, ADMIN and LEAD can access pages/functionality restricted to LEAD access
    if (checkRole === SvUserRole.LEAD)  { return this.hasAccessMultiple([SvUserRole.SUPPORT, SvUserRole.ADMIN, SvUserRole.LEAD]); } //TRKR839 added Support
    // only SUPPORT and ADMIN can access pages/functionality restricted to ADMIN access
    if (checkRole === SvUserRole.ADMIN) { return this.hasAccessMultiple([SvUserRole.SUPPORT, SvUserRole.ADMIN]); } //TRKR839 added Support
    }
    
  private hasAccessMultiple(allowedRoles: SvUserRole[]): boolean {
    // no roles have been allowed access to this content
    if (isNullOrUndefined(allowedRoles)) { return false; }
    // only allow the defined rows to view this content
    // console.log('allowed roles', allowedRoles);
    // console.log('user roles', [this.authGuardService.svUserInfo.role]);

    // the includes method is not supported by IE11
    // return allowedRoles.includes(this.authGuardService.svUserInfo.role);
    return allowedRoles.indexOf(this.authGuardService.svUserInfo.role) >= 0;
  }
}
