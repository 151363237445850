import { SvDialogService } from '../services/services-dialog/sv-dialog.service';
import { SvFlightLegKey, SvFlightLeg } from '../model/sv-flight-leg';
import { SvTransferFlightsRequest, SvTransferHistory, SvTransferHistoryFlight, SvRestoreFlightsRequest, TransferStatus } from '../model/sv-transfer-models';
import { AuthGuardService } from '../guards/auth-guard.service';
import { MessageService } from 'primeng/api';
import { formatDate } from '@angular/common';
import { timer, Subscription } from 'rxjs';
import { isNullOrUndefined } from 'util';
import * as _ from 'lodash';
import { Pipe, PipeTransform } from '@angular/core';
import { SvTimeZoneService } from '../services-ws/sv-timezone.service';
import { SvUtilities } from '../helper/sv-utilities';
import { SvUserInfo, SvUserRole } from 'src/app/model/sv-user-info'; //TRKR839


export class SvTransferFlightHistoryDialog {

  visible: boolean;
  header = 'Transfer History';

  retrievingHistory: boolean;
  transferHistories: SvTransferHistory[] = [];
  selectedHistory = new SvTransferHistory([]);

  // TODO change lastStatusUpdate and restoreStatusCompleted to be specific to the historyId and not apply to all
  private lastStatusUpdate: number;
  private statusTimer: Subscription;
  private restoreStatusCompleted = false;

  constructor(private svDialogService: SvDialogService, private timeZoneService: SvTimeZoneService,
    private authGuardService: AuthGuardService, private messageService: MessageService) { }

  openTransferHistoryDialog() {
    // stop the previous timer
    this.stopStatusTimer();
    // set the dialog as visible
    this.visible = true;
    // retrieve the transfer history
    this.getTransferHistory();
  }
  private getTransferHistory() {
    this.retrievingHistory = true;
    // reset histories before retrieving update
    this.transferHistories = [];
    this.selectedHistory = new SvTransferHistory([]);
    // get updated histories
    this.svDialogService.fetchTransferHistory(this.authGuardService.svUserInfo.empId).subscribe(
      (data) => {
        console.log('retrieved flight history', data);
        this.transferHistories = data;
        if (!isNullOrUndefined(this.transferHistories) && this.transferHistories.length > 0) {
          this.selectHistory(this.transferHistories[0].id);
        }
      },
      (error) => { console.log('error retrieving flight history', error); },
      () => { this.retrievingHistory = false; }
    );
  }

  selectHistory(historyId: number) {
    // find is not supported by any version of Internet Explorer
    // this.selectedHistory = this.transferHistories.find(transferHistory => transferHistory.id === historyId);
    // console.log('searching for selected history', historyId);
    this.selectedHistory = this.transferHistories.filter(transferHistory => transferHistory.id === historyId)[0];
    // console.log('filtered selected history', this.selectedHistory);
  }
  getImage(restoreFlightStatus: TransferStatus) {
    if (!isNullOrUndefined(restoreFlightStatus)) {
      switch (restoreFlightStatus) {
        case TransferStatus.PENDING_TRANSFER:
        case TransferStatus.PENDING_RESTORE:
          return 'loader.gif';
        case TransferStatus.TRANSFERRED:
          return 'restore.svg';
        case TransferStatus.RESTORE_ERROR:
          return 'restore_error.svg';
        case TransferStatus.TRANSFER_ERROR:
          return 'clear.svg';
        case TransferStatus.RESTORED:
          return 'check_circle.svg';
      }
    }

    return 'transfer_unknown.png';
  }
  getImageTooltip(flightHistory: SvTransferHistoryFlight) {
    if (!isNullOrUndefined(flightHistory.currentStatus)) {
      switch (flightHistory.currentStatus) {
        case TransferStatus.TRANSFERRED:
          return 'restore flight';
        case TransferStatus.PENDING_TRANSFER:
        case TransferStatus.PENDING_RESTORE:
        case TransferStatus.RESTORED:
          return flightHistory.transferStatusDetails;
        case TransferStatus.TRANSFER_ERROR:
        case TransferStatus.RESTORE_ERROR:
          if (isNullOrUndefined(flightHistory.wxyuResponse)) { return flightHistory.transferStatusDetails; }
          return flightHistory.wxyuResponse;
      }
    }
  }
  getImageTooltipClass(flightHistory: SvTransferHistoryFlight) {
    if (flightHistory.currentStatus === TransferStatus.TRANSFER_ERROR && !isNullOrUndefined(flightHistory.wxyuResponse)) {
      return 'wxyu-response';
    }
  }


  restoreAllFlights(historyId: number) {
    // restore all valid flights in this batch
    this.restoreFlights(historyId, this.selectedHistory.flights.filter(flight => this.isValidRestoreFlight(flight)));
  }
  restoreFlight(historyId: number, flight: SvTransferHistoryFlight) {
    console.log('restoreFlight', historyId, flight);
    if (this.isValidRestoreFlight(flight)) { this.restoreFlights(historyId, [flight]); }
  }
  private isValidRestoreFlight(flight: SvTransferHistoryFlight): boolean {
    // restore flights that have transferred or failed during a previous restore attempt
    return flight.currentStatus === TransferStatus.TRANSFERRED || flight.currentStatus === TransferStatus.RESTORE_ERROR;
  }
  private restoreFlights(historyId: number, flights: SvTransferHistoryFlight[]) {
    console.log('restore flights', flights);
    // change the displayed icon for the flights that will be restored

//TRKR839
if(SvUserRole.SUPPORT === this.authGuardService.svUserInfo.role){
  this.messageService.add({ severity: 'info', detail: 'Not allowed this role' });
 }else{
    if (flights.length === 0) {
      // TODO prevent this from happening by disabling the restore all if there are not any valid flights to restore;
      //  also disable the restore all while a restore is currently in progress
      // this.messageService.add({ severity: 'warn', summary: 'Warning', detail: 'No flights to restore' });
      this.messageService.add({ severity: 'info', detail: 'No flights to restore' });
    } else {
      // const restoreFlightKeys: SvTransferFlight[] = [];
      flights.forEach(flight => {
        // set all the statuses to pending
        flight.currentStatus = TransferStatus.PENDING_RESTORE;
        // add the flights keys to be transferred
        // restoreFlightKeys.push(new SvTransferFlight(
        //   flight.pk.uniqueFlightPK.depStation, flight.pk.uniqueFlightPK.flightNumber,
        //   flight.pk.uniqueFlightPK.originDate, flight.pk.uniqueFlightPK.dupDepNumber,
        //   flight.arrStation, flight.previousDesk));
      });
      // TODO complete logic to restore these flights

      this.lastStatusUpdate = Date.now();
      const restoreRequest = new SvRestoreFlightsRequest(historyId, this.authGuardService.svUserInfo.empId, flights);
      console.log('restore flights', restoreRequest);
      // start the timer to get the status updates
      this.startStatusTimer(historyId, flights, false);
      this.svDialogService.restoreFlights(restoreRequest).subscribe(
        () => { console.log('restore success'); },
        (error) => {
          console.log('restore error', error);
          this.stopStatusTimer();
          // check the status one more time
          this.updateRestoreStatus(historyId, flights, true);
        },
        () => {
          console.log('restore completed');
          this.stopStatusTimer();
          // check the status one more time
          this.updateRestoreStatus(historyId, flights, true);
        }
      );
    }
  }
  }
  private updateRestoreStatus(historyId: number, restoreFlights: SvTransferHistoryFlight[], restoreComplete: boolean) {
    this.svDialogService.restoreStatusHistoryId(this.authGuardService.svUserInfo.empId, historyId).subscribe(
      (value) => {
        console.log('restore status', value);
        for (const restoreFlight of restoreFlights) {
          // only update flights that still have a pending status
          if (restoreFlight.currentStatus === TransferStatus.PENDING_RESTORE) {
            for (const flight of value.flights) {
              // only process if the database status is set
              if (!isNullOrUndefined(flight.currentStatus)) {
                // find the matching flight
                if (SvUtilities.isSameFlight(restoreFlight.pk.uniqueFlightPK, flight.pk.uniqueFlightPK, this.timeZoneService)) {
                  restoreFlight.wxyuEntry = flight.wxyuEntry;
                  restoreFlight.wxyuResponse = flight.wxyuResponse;
                  restoreFlight.transferStatusDetails = flight.transferStatusDetails;
                  restoreFlight.currentStatus = flight.currentStatus;
                  // if (flight.transferStatus) { restoreFlight.currentStatus = TransferStatus.RESTORED; }
                  // else { restoreFlight.currentStatus = TransferStatus.RESTORE_ERROR; }
                  this.lastStatusUpdate = Date.now();
                  // console.log('found matching flight');
                  break;
                }
              }
            }
          }
        }
      },
      (error) => { console.log('restore status error', error); },
      () => {
        // console.log('restore status completed');
        let completed = true, allFailed = true, allSuccess = true;
        for (const restoreFlight of restoreFlights) {
          if (restoreFlight.currentStatus === TransferStatus.PENDING_RESTORE) { completed = false; }
          else if (restoreFlight.currentStatus === TransferStatus.RESTORED) { allFailed = false; }
          else { allSuccess = false; }
        }
        //  stop checking for updates if everything completed or if it has been 1 minute since the last time a status changed
        if (completed) {
          this.restoreStatusCompleted = true;
          this.stopStatusTimer();
          if (allSuccess) { this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Restore completed' }); }
          else if (allFailed) { this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Restore failed' }); }
          else { this.messageService.add({ severity: 'warn', summary: 'Warning', detail: 'Unable to restore all flights' }); }
        } else if (Date.now() - 60000 > this.lastStatusUpdate || restoreComplete) {
          this.stopStatusTimer();
          // change the status for all the pending flights to display the question mark
          for (const restoreFlight of restoreFlights) {
            if (restoreFlight.currentStatus === TransferStatus.PENDING_RESTORE) { restoreFlight.currentStatus = TransferStatus.UNKNOWN; }
          }
          this.messageService.add({ severity: 'info', detail: 'Unable to retrieve the remaining statuses' });
        }
      }
    );
  }
  private startStatusTimer(historyId: number, flights: SvTransferHistoryFlight[], restoreComplete: boolean) {
    // stop the previous time
    this.stopStatusTimer();
    // create a new timer
    this.statusTimer = timer(1000, 1000).subscribe(() => {
      this.updateRestoreStatus(historyId, flights, restoreComplete);
    });
  }
  private stopStatusTimer() {
    if (!isNullOrUndefined(this.statusTimer)) {
      console.log('stopping timer');
      this.statusTimer.unsubscribe();
      this.statusTimer = null;
      console.log('stopped timer');
    }
  }
}

@Pipe({ name: 'transferBatchIdPipe' })
export class TransferBatchIdPipe implements PipeTransform {
  constructor(public timeZoneService: SvTimeZoneService) { }
  transform(transferHistory: SvTransferHistory): string {
    if (isNullOrUndefined(transferHistory) || isNullOrUndefined(transferHistory.datetime)) { return 'N/A'; }
    return formatDate(transferHistory.datetime, 'ddMMMyy HHmm', this.timeZoneService.locale, this.timeZoneService.localTimeZone);
  }
}
