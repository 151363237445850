import { Component, ViewChild, AfterViewInit } from '@angular/core';
import { SvSidebarToggleService } from 'src/app/services/sv-sidebar-toggle.service';
import { MatDrawer } from '@angular/material/sidenav';

@Component({
  selector: 'sv-body',
  templateUrl: './sv-body.component.html'
})
export class SvBodyComponent implements AfterViewInit {

  @ViewChild('matDrawSidenav') sidenavComponent: MatDrawer;

  constructor(private svSidebarToggleService: SvSidebarToggleService) { }

  ngAfterViewInit() {
    this.svSidebarToggleService.sidebarToggled$.subscribe(() => this.sidenavComponent.toggle());
  }
}
