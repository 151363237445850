import { SvFlightLeg, SvFlightLegKey } from 'src/app/model/sv-flight-leg';
import { isNullOrUndefined } from 'util';
import { SvDialogService } from 'src/app/services/services-dialog/sv-dialog.service';
import { HttpErrorResponse } from '@angular/common/http';
import { SvStorageService } from 'src/app/services/sv-storage-listener.service';
import { SvReminderFlight, SvReminderData, SvCreateReminder, SvReminderService } from './sv-reminder.services';
import { Options, LabelType } from 'ng5-slider';
import { Renderer2 } from '@angular/core';

const MIN_REMINDER_MINUTES = 5;

export class SvReminderDialog {

    visible: boolean;
    mainFlight: SvFlightLeg;
    flight: SvFlightLegKey;
    loading: boolean;
    loadError: boolean;
    positionLeft: number;
    positionTop: number;

    remindNote: string;
    working: boolean;
    alreadyExists: boolean;

    // slider information
    sliderValue = 10;
    sliderOptions: Options = {
        floor: MIN_REMINDER_MINUTES,
        ceil: 60,
        step: 1,
        translate: (value: number, label: LabelType): string => {
            switch (label) {
                case LabelType.Low:
                    if (value === 60) { return '1 hr'; }
                    if (value > 60) {
                        const timeLeft = value - 60;
                        return '1 hr' + (timeLeft > 0 ? `${timeLeft} min` : '');
                    }
                    return value + ' min';
                // case LabelType.High:
                //     return value ;
                // case LabelType.Floor:
                //   return Math.abs(value) + 'min before ETD';
                // case LabelType.Ceil:
                //   return value + 'min after ETD';
                default:
                    return '';
            }
        }
    };

    constructor(private svDialogService: SvDialogService, private reminderService: SvReminderService, private empId: number) { }

    // this.storageService.changes.subscribe(data => {
    //   console.log('SvSidebarComponent local storage changes', data);
    // });

    open(mainFlight: SvFlightLeg) {
        this.mainFlight = mainFlight;
        this.flight = mainFlight.flightKey;
        // this.loading = true;
        this.working = false;
        this.sliderValue = 10;
        this.remindNote = undefined;
        this.loading = true;
        this.visible = true;
        this.alreadyExists = false;
        // this.svDialogService.retrieveReminders(this.authGuardService.svUserInfo.empId).pipe(debounceTime(250)).subscribe(

        // TODO need to cancel the previous subscription to prevent a new open from saving data to the wrong flight

        this.svDialogService.retrieveReminder(this.flight, this.empId).subscribe(
            (data) => {
                // console.log('reminder retrieve', data);
                if (!isNullOrUndefined(data)) {
                    // this reminder exists
                    const tempSliderValue = (data.reminderTime - Date.now()) / 60_000;
                    this.sliderValue = (tempSliderValue < MIN_REMINDER_MINUTES) ? MIN_REMINDER_MINUTES : tempSliderValue;
                    this.remindNote = data.note;
                    this.alreadyExists = true;
                }
            },
            (error: HttpErrorResponse) => {
                console.log('reminder retrieve error', error, this.flight);
                this.loadError = true;
            },
            () => { this.loading = false; }
        );
    }

    createReminder() {
        // console.log(`create for ${minutes} minutes`, reminder);
        const create = new SvCreateReminder(this.flight, this.remindNote, this.sliderValue, this.empId);
        // const element = this.renderer.selectRootElement('.reminder-dialog .transfer-button.ui-button-raised.ui-button-info');
        // console.log('found renderer2 element', element);
        this.working = true;
        this.svDialogService.createReminder(create).subscribe(
            // TODO display a message if the create failed
            data => {
                if (!isNullOrUndefined(data)) {
                    // console.log(`flight successfully created ${create.loading}`, create, data);
                    this.closeDialog();
                    this.reminderService.updateReminders();
                    this.mainFlight.hasReminder = true;
                } else {
                    // console.log('create failed', create, data);
                }
            },
            error => { console.log('create error', create, error); },
            () => { this.working = false; }
        );
    }

    removeReminder() {
        // console.log('remove reminder');
        const remove = new SvCreateReminder(this.flight, this.remindNote, this.sliderValue, this.empId);
        this.working = true;
        this.svDialogService.removeReminder(remove).subscribe(
            // TODO display a message if the remove failed
            data => {
                if (!isNullOrUndefined(data)) {
                    // console.log(`flight successfully removed ${create.loading}`, create, data);
                    this.closeDialog();
                    this.reminderService.updateReminders();
                    this.mainFlight.hasReminder = false;
                } else {
                    // console.log('create failed', create, data);
                }
            },
            error => { console.log('remove error', remove, error); },
            () => { this.working = false; }
        );
    }

    closeDialog() {
        this.visible = false;
    }
}
