import { Component } from '@angular/core';
import { SvRouteService } from '../../services/sv-route.service';
import { SvSidebarToggleService } from 'src/app/services/sv-sidebar-toggle.service';
import { SvOpenTransferFlightService } from 'src/app/services/sv-open-transfer-flight.service';
import { SvOpenTransferHistoryService } from 'src/app/services/sv-open-transfer-history.service';
import { RoleGuardService } from 'src/app/guards/role-guard.service';
import { SvUserRole } from 'src/app/model/sv-user-info';
import { SvUserManagementService } from 'src/app/services/sv-user-management.service';

@Component({
  selector: 'sv-header',
  templateUrl: './sv-header.component.html'
})
export class SvHeaderComponent {
  // routePath = 'n/a';
  allowedToTransfer = this.roleGuardService.hasAccessSingle(SvUserRole.LEAD);
  userHasUserMgtAccess = this.roleGuardService.hasAccessSingle(SvUserRole.ADMIN);


  constructor(private svRouteService: SvRouteService, private svSidebarToggleService: SvSidebarToggleService
    , private svOpenTransferFlightService: SvOpenTransferFlightService, private svOpenTransferHistoryService: SvOpenTransferHistoryService,
    private roleGuardService: RoleGuardService, private svUserManagementService: SvUserManagementService) {
    // this.svRouteService.activationEnded$.subscribe((snapshot) => {
    //   /** TODO update header options */
    //   if (snapshot.routeConfig.path) { this.routePath = snapshot.routeConfig.path; }
    // });
  }

  toggleSidebar() {
    this.svSidebarToggleService.toggleSidebar();
  }

  openTransferDialog() { this.svOpenTransferFlightService.openTransferDialog(); }

  openUserMgmtDialog() { this.svUserManagementService.openUserManagement(); }

  openTransferHistoryDialog() { this.svOpenTransferHistoryService.openTransferHistoryDialog(); }

}
