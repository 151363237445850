import { SvDialogService } from '../services/services-dialog/sv-dialog.service';
import { SvFlightLegKey, SvFlightLeg } from '../model/sv-flight-leg';
import { SvTransferFlightsRequest, SvTransferFlight, TransferStatus, SvTransferStatus } from '../model/sv-transfer-models';
import { AuthGuardService } from '../guards/auth-guard.service';
import { timer, Subscription, Observable } from 'rxjs';
import { OnDestroy } from '@angular/core';
import { isNullOrUndefined, isNull } from 'util';
import * as _ from 'lodash';
import { DatePipe } from '@angular/common';
import { SvTimeZoneService } from '../services-ws/sv-timezone.service';
import { SvUtilities } from '../helper/sv-utilities';

export class SvTransferFlightDialog {

    visible: boolean;
    header: string;
    transferId = 0;
    transferToDesk: string;
    transferComment: string;
    transferStarted = false;
    transferStatusCompleted = false;
    positionLeft: number;
    positionTop: number;

    transferFlights: TransferFlight[] = [];
    transferMessages = [];

    deskOptions = [
        'LD01', 'LD02', 'LD03', 'LD04',
        'L001', 'L002', 'L003', 'L004', 'L005', 'L006', 'L007', 'L008', 'L009', 'L010',
        'L011', 'L012', 'L013', 'L014', 'L015', 'L016', 'L017', 'L018', 'L019', 'L020',
        'L021', 'L022', 'L023', 'L024', 'L025', 'L026', 'L027', 'L028', 'L029', 'L030',
        'L031', 'L032', 'L033', 'L034', 'L035', 'L036', 'L037', 'L038', 'L039', 'L040',
        'L041', 'L042', 'L043', 'L044', 'L045', 'L046', 'L047', 'L048', 'L049', 'L050',
        'L051', 'L052', 'L053', 'L054', 'L055', 'L056', 'L057', 'L058', 'L059', 'L060',
        'L061', 'L062', 'L063', 'L064', 'L065', 'L066', 'L067', 'L068', 'L069', 'L070',
        'L071', 'L072', 'L073', 'L074', 'L075', 'L076', 'L077', 'L078', 'L079', 'L080',
        'L081', 'L082', 'L083', 'L084', 'L085', 'L086', 'L087', 'L088', 'L089', 'L090',
        'L091', 'L092', 'L093', 'L094', 'L095', 'L096', 'L097', 'L098', 'L099', 'L100',
    ];
    lastStatusUpdate: number;
    statusTimer: Subscription;

    constructor(private svDialogService: SvDialogService, private timeZoneService: SvTimeZoneService, flightLegs: SvFlightLeg[], private transferCount: number,
        private authGuardService: AuthGuardService, private transferSelectedCallback: (flightKey: SvFlightLegKey) => void) {
        flightLegs.forEach(flight => { this.transferFlights.push(new TransferFlight(flight)); });
    }

    openTransferDialog() {
        this.visible = true;
        this.header = `Transfer Flights #${this.transferCount}`;
    }
    removeFlight(flight) {
        // console.log('removing flight', flight);
        if (this.transferFlights.length > 1) {
            const removeIndex = this.transferFlights.indexOf(flight);
            if (removeIndex < 0) { this.transferMessages = [{ severity: 'error', summary: 'Error', detail: 'Error removing flight' }]; }
            else { this.transferFlights.splice(removeIndex, 1); }
        } else {
            this.transferMessages = [{ severity: 'warn', summary: 'Warning', detail: 'This list must have at least one flight' }];
        }
    }

    startFlightTransfer() {
        // console.log('transferring flights', this.transferFlights);
        const startTime = Date.now();
        const transferFlightKeys: SvTransferFlight[] = [];
        this.transferFlights.forEach(flight => {
            // this changes the icon
            flight.status = TransferStatus.PENDING_TRANSFER;
            // add the flights keys to be transferred
            transferFlightKeys.push(new SvTransferFlight(
                flight.flightLeg.flightKey.depStation, flight.flightLeg.flightKey.flightNumber,
                flight.flightLeg.flightKey.originDate, flight.flightLeg.flightKey.dupDepNumber,
                flight.flightLeg.arrStation, flight.flightLeg.loadDesk));
        });
        this.transferStarted = true;

        this.lastStatusUpdate = Date.now();
        const flightRequest = new SvTransferFlightsRequest(
            this.authGuardService.svUserInfo.empId,
            startTime, this.transferToDesk, this.transferComment, transferFlightKeys);
        // start the timer to get the status updates
        this.startStatusTimer(this.authGuardService.svUserInfo.empId, false, startTime);
        this.svDialogService.transferFlights(flightRequest).subscribe(
            (response) => {
                console.log('transfer success', response);
                this.transferId = response;
            },
            (error) => {
                console.log('transfer error', this.transferId, error);
                this.stopStatusTimer();
                //  get status one more time if it is not complete
                if (!this.transferStatusCompleted) { this.updateTransferStatus(this.transferId, true); }
            },
            () => {
                console.log('transfer completed', this.transferId);
                this.stopStatusTimer();
                //  get status one more time if it is not complete
                if (!this.transferStatusCompleted) { this.updateTransferStatus(this.transferId, true); }
            }
        );

        // start checking the status for these flights
        // this.checkStatus();
    }
    private updateTransferStatus(transferId: number, transferComplete: boolean, startTime?: number) {
        let statusResponse: Observable<SvTransferStatus>;
        if (isNullOrUndefined(startTime)) { statusResponse = this.svDialogService.transferStatusHistoryId(this.authGuardService.svUserInfo.empId, transferId); }
        else { statusResponse = this.svDialogService.transferStatusEmployeeId(transferId, startTime); }
        statusResponse.subscribe(
            (value) => {
                console.log('transfer status', value);
                for (const transferFlight of this.transferFlights) {
                    // console.log('transferFlight of this.transferFlights', transferFlight);
                    // find the matching database flight
                    if (transferFlight.status === TransferStatus.PENDING_TRANSFER) {
                        // only update flights that still have a pending status
                        for (const flight of value.flights) {
                            // console.log('flight of value.flights', flight);
                            // only process if the database status is set
                            if (!isNullOrUndefined(flight.currentStatus)) {
                                if (SvUtilities.isSameFlight(flight.pk.uniqueFlightPK, transferFlight.flightLeg.flightKey, this.timeZoneService)) {
                                    transferFlight.fosEntry = flight.wxyuEntry;
                                    transferFlight.fosResponse = flight.wxyuResponse;
                                    transferFlight.details = flight.transferStatusDetails;
                                    transferFlight.status = flight.currentStatus;
                                    // if (flight.transferStatus) { transferFlight.status = TransferStatus.TRANSFERRED; }
                                    // else { transferFlight.status = TransferStatus.TRANSFER_ERROR; }
                                    //  deselect this flight
                                    this.transferSelectedCallback(transferFlight.flightLeg.flightKey);
                                    this.lastStatusUpdate = Date.now();
                                    // console.log('found matching flight');
                                    break;
                                }
                            }
                        }
                    }
                }
            },
            (error) => { console.log('transfer status error', error); },
            () => {
                // console.log('transfer status completed');
                let completed = true, allFailed = true, allSuccess = true;
                for (const transferFlight of this.transferFlights) {
                    // find the matching database flight
                    if (transferFlight.status === TransferStatus.PENDING_TRANSFER) { completed = false; }
                    else if (transferFlight.status === TransferStatus.TRANSFERRED) { allFailed = false; }
                    else { allSuccess = false; }
                }
                //  stop checking for updates if everything completed or if it has been 1 minute since the last time a status changed
                if (completed) {
                    this.transferStatusCompleted = true;
                    this.stopStatusTimer();
                    if (allSuccess) { this.transferMessages = [{ severity: 'success', summary: 'Success', detail: 'Transfers completed' }]; }
                    else if (allFailed) { this.transferMessages = [{ severity: 'error', summary: 'Error', detail: 'All transfers failed' }]; }
                    else { this.transferMessages = [{ severity: 'warn', summary: 'Warning', detail: 'Some transfers failed' }]; }
                } else if (Date.now() - 60000 > this.lastStatusUpdate || transferComplete) {
                    this.stopStatusTimer();
                    // TODO change to deselect all flights when the user starts the transfer
                    // change the status for all the pending flights to display the question mark and deselect these flights
                    for (const transferFlight of this.transferFlights) {
                        if (transferFlight.status === TransferStatus.PENDING_TRANSFER) {
                            transferFlight.status = TransferStatus.UNKNOWN;
                            // deselect this flights since the transfer completed
                            this.transferSelectedCallback(transferFlight.flightLeg.flightKey);
                        }
                    }
                    this.transferMessages = [{ severity: 'info', detail: 'Unable to retrieve the remaining statuses' }];
                }
            }
        );
    }
    private startStatusTimer(transferId: number, transferComplete: boolean, startTime?: number) {
        // stop the previous time
        this.stopStatusTimer();
        // create a new timer
        this.statusTimer = timer(1000, 1000).subscribe(() => {
            this.updateTransferStatus(transferId, transferComplete, startTime);
        });
    }
    private stopStatusTimer() {
        if (!isNullOrUndefined(this.statusTimer)) {
            this.statusTimer.unsubscribe();
            this.statusTimer = null;
        }
    }

    closeDialog() {
        this.stopStatusTimer();
        this.visible = false;
    }

    public getImage(transferFlightStatus: TransferStatus) {
        if (!isNullOrUndefined(transferFlightStatus)) {
            switch (transferFlightStatus) {
                case TransferStatus.PENDING_TRANSFER:
                    // case TransferStatus.PENDING_RESTORE:
                    return 'pending.gif';
                case TransferStatus.TRANSFERRED:
                    // case TransferStatus.RESTORED:
                    return 'success.png';
                case TransferStatus.TRANSFER_ERROR:
                    // case TransferStatus.RESTORE_ERROR:
                    return 'failed.png';
            }
        }

        return 'unknown.png';
    }
}

export class TransferFlight {
    status: TransferStatus;
    details: string;
    fosEntry: string;
    fosResponse: string;
    constructor(public flightLeg: SvFlightLeg) { }
}
