import { EventEmitter } from '@angular/core';
import { Options, LabelType, PointerType } from 'ng5-slider';
import { isNullOrUndefined } from 'util';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

const SLIDER_FACTOR = 4;

export class SvDoubleSliderEtd {

    defaultBeforeEtd: number;
    minBeforeEtd: number;
    maxBeforeEtd: number;
    defaultAfterEtd: number;
    minAfterEtd: number;
    maxAfterEtd: number;

    etdBeforeValue: number;
    etdBeforeInput: number;
    etdAfterValue: number;
    etdAfterInput: number;
    etdOptions: Options;
    etdManualRefresh = new EventEmitter<void>();

    // use debounce to control calling  the filter callback
    private filterRefreshSubject = new Subject();
    private filterRefreshObservable$ = this.filterRefreshSubject.asObservable();

    constructor(defaultBeforeEtd: number, minBeforeEtd: number, maxBeforeEtd: number
        , defaultAfterEtd: number, minAfterEtd: number, maxAfterEtd: number, private filterSliderCallback: () => void) {

        // console.log(`constructor defaultBeforeEtd=${defaultBeforeEtd},minBeforeEtd=${minBeforeEtd},maxBeforeEtd=${maxBeforeEtd}
        // defaultAfterEtd=${defaultAfterEtd},minAfterEtd=${minAfterEtd},maxAfterEtd=${maxAfterEtd}`);

        // setup the filter callback
        this.filterRefreshObservable$.pipe(debounceTime(100)).subscribe(() => { this.filterSliderCallback(); });

        this.defaultBeforeEtd = defaultBeforeEtd;
        this.minBeforeEtd = minBeforeEtd;
        this.maxBeforeEtd = maxBeforeEtd;
        this.defaultAfterEtd = defaultAfterEtd;
        this.minAfterEtd = minAfterEtd;
        this.maxAfterEtd = maxAfterEtd;

        this.etdBeforeValue = -this.defaultBeforeEtd;
        this.etdBeforeInput = this.defaultBeforeEtd;
        this.etdAfterValue = this.defaultAfterEtd;
        this.etdAfterInput = this.defaultAfterEtd;
        this.etdOptions = {
            floor: -this.maxBeforeEtd,
            ceil: this.maxAfterEtd,
            step: 1,
            noSwitching: true,
            ticksArray: [0],
            // autoHideLimitLabels: false,
            // hidePointerLabels: true,
            // onlyBindHandles: true,
            getLegend: (value: number): string => {
                if (value === 0) { return 'ETD'; }
            },
            // ticksTooltip: (v: number): string => 'ETD',
            getSelectionBarColor: (minValue: number, maxValue: number): string => {
                if (minValue > this.minBeforeEtd || maxValue < this.minAfterEtd) { return 'red'; }
            },
            translate: (value: number, label: LabelType): string => {
                switch (label) {
                    case LabelType.Low:
                        // return -value + 'min';
                        if (value === 0) { return '0'; }
                        if (value === -this.maxBeforeEtd) { return 'MAX'; }
                        if (value > 0) { return value + ' A'; }
                        return -value + ' B';
                    case LabelType.High:
                        // return value + 'min';
                        if (value === 0) { return '0'; }
                        if (value === this.maxAfterEtd) { return 'MAX'; }
                        if (value < 0) { return -value + ' B'; }
                        return value + ' A';
                    // case LabelType.Floor:
                    //   return Math.abs(value) + 'min before ETD';
                    // case LabelType.Ceil:
                    //   return value + 'min after ETD';
                    default:
                        return '';
                }
            },
            // export declare type CombineLabelsFunction = (minLabel: string, maxLabel: string) => string;
            combineLabels: (minLabel: string, maxLabel: string): string => {
                return minLabel === maxLabel ? minLabel : `${minLabel} to ${maxLabel}`;
            },
            // add functionality to change the pointer color
            getPointerColor: (value: number, pointerType: PointerType): string => {
                switch (pointerType) {
                    case PointerType.Min:
                        if (value > minBeforeEtd) { return 'red'; }
                        break;
                    case PointerType.Max:
                        if (value < minAfterEtd) { return 'red'; }
                        break;
                }
            },
            customValueToPosition: (val: number, minVal: number, maxVal: number) => {
                // console.log(`ValueToPosition val=${val},minVal=${minVal},maxVal=${maxVal}`);
                let tempVal = this.numRoot(val); if (val < 0) { tempVal = -tempVal; }
                let tempMinVal = this.numRoot(minVal); if (minVal < 0) { tempMinVal = -tempMinVal; }
                let tempMaxVal = this.numRoot(maxVal); if (maxVal < 0) { tempMaxVal = -tempMaxVal; }
                const percent = (tempVal - tempMinVal) / (tempMaxVal - tempMinVal);
                // console.log(`ValueToPosition percent=${percent}`);

                // let valCheck = Math.pow(percent * (tempMaxVal - tempMinVal) + tempMinVal, SLIDER_FACTOR);
                // if (percent < .5) { valCheck = -valCheck; }
                // // percent = (val - minVal) / (maxVal - minVal);
                // console.log(`ValueToPosition valCheck=${valCheck}`);

                return percent;
            },
            customPositionToValue: (percent: number, minVal: number, maxVal: number) => {
                // console.log(`PositionToValue percent=${percent},minVal=${minVal},maxVal=${maxVal}`);
                let tempMinVal = this.numRoot(minVal); if (minVal < 0) { tempMinVal = -tempMinVal; }
                let tempMaxVal = this.numRoot(maxVal); if (maxVal < 0) { tempMaxVal = -tempMaxVal; }
                let val = Math.pow(Math.abs(percent * (tempMaxVal - tempMinVal) + tempMinVal), SLIDER_FACTOR);
                if (percent < .5) { val = -val; }
                // console.log(`PositionToValue val=${val}`);

                return val;
            }
        };
    }
    private numRoot(value: number) {
        return Math.pow(Math.abs(value), 1 / SLIDER_FACTOR);
    }



    etdBeforeInputModelChange() {
        // console.log(`etdBeforeInputModelChange B etdBeforeValue=${this.etdBeforeValue}`);
        // only update the slider values for this type of change
        if (!isNullOrUndefined(this.etdBeforeInput) && this.etdBeforeInput >= this.minBeforeEtd && this.etdBeforeInput <= this.maxBeforeEtd) {
            // update the slider value only if this number is valid
            this.etdBeforeValue = -this.etdBeforeInput;
        }
        // console.log(`etdBeforeInputModelChange A etdBeforeValue=${this.etdBeforeValue}`);
    }
    etdBeforeInputChange() {
        // console.log(`etdBeforeInputChange B etdBeforeValue=${this.etdBeforeValue}`);
        if (isNullOrUndefined(this.etdBeforeInput)) { this.etdBeforeInput = this.defaultBeforeEtd; }
        else if (this.etdBeforeInput < this.minBeforeEtd) { this.etdBeforeInput = this.minBeforeEtd; }
        else if (this.etdBeforeInput > this.maxBeforeEtd) { this.etdBeforeInput = this.maxBeforeEtd; }
        // set the slider value
        this.etdBeforeValue = -this.etdBeforeInput;
        // console.log(`etdBeforeInputChange A etdBeforeValue=${this.etdBeforeValue}`);
    }
    etdAfterInputModelChange() {
        // console.log(`etdAfterInputModelChange B etdAfterValue=${this.etdAfterValue}`);
        // only update the slider values for this type of change
        if (!isNullOrUndefined(this.etdAfterInput) && this.etdAfterInput >= this.minAfterEtd && this.etdAfterInput <= this.maxAfterEtd) {
            // update the slider value only if this number is valid
            this.etdAfterValue = this.etdAfterInput;
        }
        // console.log(`etdAfterInputModelChange A etdAfterValue=${this.etdAfterValue}`);
    }
    etdAfterInputChange() {
        // console.log(`etdAfterInputChange B etdAfterValue=${this.etdAfterValue}`);
        if (isNullOrUndefined(this.etdAfterInput)) { this.etdAfterInput = this.defaultAfterEtd; }
        else if (this.etdAfterInput < this.minAfterEtd) { this.etdAfterInput = this.minAfterEtd; }
        else if (this.etdAfterInput > this.maxAfterEtd) { this.etdAfterInput = this.maxAfterEtd; }
        // set the slider value
        this.etdAfterValue = this.etdAfterInput;
        // console.log(`etdAfterInputChange A etdAfterValue=${this.etdAfterValue}`);
    }
    etdSliderChange() {
        // console.log(`etdSliderChange B etdBeforeInput=${this.etdBeforeInput},etdAfterInput=${this.etdAfterInput}`);
        this.etdBeforeInput = Math.abs(this.etdBeforeValue);
        this.etdAfterInput = this.etdAfterValue;
        // console.log(`etdSliderChange A etdBeforeInput=${this.etdBeforeInput},etdAfterInput=${this.etdAfterInput}`);
    }
    etdSliderChangeEnd() {
        // console.log(`etdSliderChangeEnd B etdBeforeValue=${this.etdBeforeValue},etdAfterValue=${this.etdAfterValue}`);
        let emitChange = false;
        if (this.etdBeforeValue > this.minBeforeEtd) {
            this.etdBeforeValue = this.minBeforeEtd;
            this.etdBeforeInput = this.etdBeforeValue;
            emitChange = true;
        }
        if (this.etdAfterValue < this.minAfterEtd) {
            this.etdAfterValue = this.minAfterEtd;
            this.etdAfterInput = this.etdAfterValue;
            emitChange = true;
        }
        if (emitChange) { this.etdManualRefresh.emit(); }
        // console.log(`etdSliderChangeEnd A etdBeforeValue=${this.etdBeforeValue},etdAfterValue=${this.etdAfterValue}`);

        // use a subject to debounce when this method is called
        console.log('refresh the filter');
        this.filterRefreshSubject.next();
    }
}
