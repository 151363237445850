import {
  Component, Input, ElementRef, Output, EventEmitter, Renderer2, NgZone,
  OnInit, OnDestroy, HostBinding, ChangeDetectionStrategy
} from '@angular/core';

import { MouseEvent } from '../../events';
import { Subscription, Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'datatable-scroller',
  template: `
    <ng-content></ng-content>
  `,
  host: {
    class: 'datatable-scroll'
  },
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ScrollerComponent implements OnInit, OnDestroy {

  @Input() scrollbarV: boolean = false;
  @Input() scrollbarH: boolean = false;

  @HostBinding('style.height.px')
  @Input() scrollHeight: number;

  @HostBinding('style.width.px')
  @Input() scrollWidth: number;

  @Output() scroll: EventEmitter<any> = new EventEmitter();

  scrollYPos: number = 0;
  scrollXPos: number = 0;
  prevScrollYPos: number = 0;
  prevScrollXPos: number = 0;
  element: any;
  parentElement: any;
  onScrollListener: any;

  private scrollSubject$: Subject<any>;
  private scrollSubscription: Subscription;

  constructor(private ngZone: NgZone, element: ElementRef, private renderer: Renderer2) {
    this.element = element.nativeElement;
  }

  ngOnInit(): void {
    // manual bind so we don't always listen
    if (this.scrollbarV || this.scrollbarH) {
      const renderer = this.renderer;
      this.parentElement = renderer.parentNode(renderer.parentNode(this.element));
      this.parentElement.addEventListener('scroll', this.onScrolled.bind(this));

      this.scrollSubject$ = new Subject();
      this.scrollSubscription = this.scrollSubject$.pipe(debounceTime(50))
        .subscribe((event) => { this.scrollTable(event); });
    }
  }

  ngOnDestroy(): void {
    if (this.scrollbarV || this.scrollbarH) {
      this.parentElement.removeEventListener('scroll', this.onScrolled.bind(this));
      this.scrollSubscription.unsubscribe();
      this.scrollSubscription = null;
      this.scrollSubject$ = null;
    }
  }

  setOffset(offsetY: number): void {
    if (this.parentElement) {
      this.parentElement.scrollTop = offsetY;
    }
  }

  onScrolled(event: MouseEvent): void {
    const dom: Element = <Element>event.currentTarget;
    this.scrollSubject$.next({ scrollTop: dom.scrollTop, scrollLeft: dom.scrollLeft });
  }

  private scrollTable(data: any) {
    requestAnimationFrame(() => {
      this.scrollYPos = data.scrollTop;
      this.scrollXPos = data.scrollLeft;
      this.updateOffset();
    });
  }

  updateOffset(): void {
    // console.log('ScrollerComponent updateOffset');
    let direction: string;
    if (this.scrollYPos < this.prevScrollYPos) {
      direction = 'down';
    } else if (this.scrollYPos > this.prevScrollYPos) {
      direction = 'up';
    }

    this.scroll.emit({
      direction,
      scrollYPos: this.scrollYPos,
      scrollXPos: this.scrollXPos
    });

    this.prevScrollYPos = this.scrollYPos;
    this.prevScrollXPos = this.scrollXPos;
  }

}
