import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class SvSidebarToggleService {

  private sidebarToggleSource = new Subject();
  sidebarToggled$ = this.sidebarToggleSource.asObservable();

  private sidebarTogglingSource = new Subject();
  sidebarToggling$ = this.sidebarTogglingSource.asObservable();

  constructor() { }

  toggleSidebar() { this.sidebarToggleSource.next(); }

  togglingSidebar() { this.sidebarTogglingSource.next(); }
}
