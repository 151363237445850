import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { SvTimeZone } from '../model/sv-timezone';
import { isNullOrUndefined } from 'util';
import { WS_BASE } from './sv-ws-constants';

const WS_ROUTE = '/timezone-controller/time-zone';

@Injectable({ providedIn: 'root' })
export class SvTimeZoneService {

  locale: string;
  localTimeZone: string;
  localTimeZoneDisplay: string;

  constructor(private http: HttpClient) {
    const timeInfo: Intl.ResolvedDateTimeFormatOptions = Intl.DateTimeFormat().resolvedOptions();
    this.locale = timeInfo.locale;
    this.localTimeZone = timeInfo.timeZone;
    this.localTimeZoneDisplay = '';
    this.retrieveTimeZone();
  }

  private retrieveTimeZone() {
    // create the request needed for the web service call
    const httpParams = new HttpParams().set('locale', this.locale).set('timeZone', this.localTimeZone);
    const dataRequest = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }), params: httpParams };

    // call the web service to get timeZone information
    this.http.get<SvTimeZone>(`${WS_BASE}${WS_ROUTE}`, dataRequest).subscribe(
      (data) => {
        // console.log(data);
        // console.dir(data);
        if (!isNullOrUndefined(data.timezone)) { this.localTimeZone = data.timezone; }
        if (!isNullOrUndefined(data.timezoneDisplay)) { this.localTimeZoneDisplay = data.timezoneDisplay; }
      },
      (error: HttpErrorResponse) => {
        // using function() causes error because "this" is lost. using () => instead preserves this within the function
        // retry every 10 seconds
        setTimeout(() => { this.retrieveTimeZone(); }, 10000);
      },
      // () => { },
    );
  }
}
