import { Pipe, PipeTransform } from '@angular/core';
import { isUndefined, isNullOrUndefined } from 'util';


@Pipe({ name: 'svFosPipe' })
export class SvFosPipe implements PipeTransform {

  transform(value: string): string {
    if (isNullOrUndefined(value)) { return 'N/A'; }
    return value;
  }
}
