import { Component } from '@angular/core';
import { SvSidebarToggleService } from 'src/app/services/sv-sidebar-toggle.service';

@Component({
  selector: 'sv-header-flight-view',
  templateUrl: './sv-header-flight-view.component.html'
})
export class SvHeaderFlightViewComponent {

  constructor() { }
}
