import { Component } from '@angular/core';
import { AuthGuardService } from 'src/app/guards/auth-guard.service';

@Component({
  selector: 'sv-header-welcome',
  templateUrl: './sv-header-welcome.component.html'
})
export class SvHeaderWelcomeComponent {

  constructor(public userInfoService: AuthGuardService) { }
}
