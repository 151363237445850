import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { SvContentDeskLoadComponent } from './components/sv-body/sv-content/sv-content-desk-load/sv-content-desk-load.component';
import { SvFlightWindowComponent } from './components/sv-flight-window/sv-flight-window.component';
import { SvUnauthorizedComponent } from './components/sv-unauthorized/sv-unauthorized';
import { PingAuthGuard } from '@techops-ui/ping-authentication'; //TRKR828a
import { AuthGuardService } from './guards/auth-guard.service';
import { RoleGuardService } from './guards/role-guard.service';
import { SvUserRole } from './model/sv-user-info';
import { SvFlightViewRouterComponent } from './routers/sv-flight-view-router.component';
import { SvRetrieveErrorComponent } from './components/sv-retrieve-error/sv-retrieve-error';
import { TITLE_SUFFIX } from './services-ws/sv-ws-constants';

// all routes are case-sensitive
const routes: Routes = [
  /** body routing */
  {
    path: 'deskload',
    // TODO change this class to use the correct routing so user information is correctly retrieved
    component: SvContentDeskLoadComponent,
    // component: SvDeskLoadRouterComponent,
    canActivate: [RoleGuardService],
    data: {
      title: 'Desk Load' + TITLE_SUFFIX,
      // TODO no one is allowed to access this page since it does not exist right now
      // role: SvUserRole.LEAD
    }
  },
  // {
  //   path: 'flightview',
  //   component: SvContentFlightViewComponent,
  //   canActivate: [AuthGuardService],
  //   data: { title: 'Flight View' + TITLE_SUFFIX }
  // },
  {
    path: 'flightview',
    component: SvFlightViewRouterComponent,
  
   //TRKR828a  canActivate: [AuthGuardService], //TRKR828a

    canActivate: [PingAuthGuard, AuthGuardService], //TRKR828a
    data: { title: 'Flight View' + TITLE_SUFFIX }
  },

  /** single flight view **/
  {
    path: 'flightinfo',
    component: SvFlightWindowComponent,
    canActivate: [RoleGuardService],
    data: {
      title: 'Flight Info' + TITLE_SUFFIX,
      role: SvUserRole.LEAD
    }
  },

  /** unauthorized access **/
  {
    path: 'errorunauthorizeduseraccess',
    component: SvUnauthorizedComponent,
    data: { title: 'Unauthorized' + TITLE_SUFFIX }
  },

  /** unable to retrieve user information **/
  {
    path: 'errorretrievinguserinfo',
    component: SvRetrieveErrorComponent,
    data: { title: 'User Error' + TITLE_SUFFIX }
  },

  /** redirect to the flight view */
  { path: '', pathMatch: 'full', redirectTo: '/flightview' },

  /** page not found (NEED TO CREATE THIS PAGE) */
  // { path: '**', component: PageNotFoundComponent }
];

@NgModule({
//TRKR828a imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload',useHash:false })], //TRKR828a
  // imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload', enableTracing: true })], // debugging purposes only
  exports: [RouterModule]
})
export class AppRoutingModule {

  public static getTitleByPath(path: string): string {
    if (path) {
      for (const route of routes) {
        if (route.path && route.data && route.data.title && route.path === path) {
          return route.data.title;
        }
      }
    }

    return null;
  }
}
