export class SvPageRequest {
    /** the number of pages to request before the current page */
    pageBack: number;
    /** the number of pages to request after the current page */
    pageForward: number;
    /** the current page number */
    pageNumber: number;
    /** the number of elements on each page */
    pageRowCount: number;

    constructor(pageBack: number, pageForward: number) {
        this.pageBack = pageBack;
        this.pageForward = pageForward;
    }

    public toString() {
        return `SvPageRequest (pageBack: ${this.pageBack}, pageForward: ${this.pageForward}, `
            + `pageNumber: ${this.pageNumber}, pageRowCount: ${this.pageRowCount})`;
    }
}
