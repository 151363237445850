import { Pipe, PipeTransform } from '@angular/core';
import { Injectable } from '@angular/core';


@Injectable({ providedIn: 'root' })
export class SvFlightStoreService {

  // private sidebarToggleSource = new Subject();
  // sidebarToggled$ = this.sidebarToggleSource.asObservable();

  // private sidebarTogglingSource = new Subject();
  // sidebarToggling$ = this.sidebarTogglingSource.asObservable();

  // constructor() { }

  // toggleSidebar() { this.sidebarToggleSource.next(); }

  // togglingSidebar() { this.sidebarTogglingSource.next(); }
}


@Pipe({ name: 'svFlightStoreCurPage' })
export class SvFlightStoreCurPage implements PipeTransform {

  constructor(private svFlightStoreService: SvFlightStoreService) { }

  // transform(curPage: number): number {
  //   console.log(`SvFlightStoreCurPage curPage ${curPage}`);
  //   return curPage;
  // }
  transform(curPage: any): any {
    // console.log(`SvFlightStoreCurPage curPage ${curPage}`);
    return curPage;
  }
}
@Pipe({ name: 'svFlightStoreTotalPages' })
export class SvFlightStoreTotalPages implements PipeTransform {

  constructor(private svFlightStoreService: SvFlightStoreService) { }

  transform(value: number): number {
    const totalPages = Math.ceil(value);
    // console.log(`SvFlightStoreTotalPages value ${value} ; totalPages ${totalPages}`);
    return totalPages;
  }
}
