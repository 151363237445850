import { Component } from '@angular/core';
import { SvLogOut } from 'src/app/services-ws/sv-log-out';

@Component({
  selector: 'sv-header-logout',
  templateUrl: './sv-header-logout.component.html'
})
export class SvHeaderLogoutComponent {

  constructor(private svLogOut: SvLogOut) { }

  public logoutUser() {
    console.log('logout user');
    this.svLogOut.logout();
  }
}
