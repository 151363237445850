import 'hammerjs';
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

//TRKR828a - Commented out due to pingfederate integration
// if (environment.production) {
//   enableProdMode();
// }
// platformBrowserDynamic().bootstrapModule(AppModule)
//   .catch(err => console.error(err));




//-------------------------------------
// TRKR828a - Pingfederate integration
//-------------------------------------
import 'isomorphic-fetch';
import { PingAuthenticationConfig, PING_CONFIG } from '@techops-ui/ping-authentication';

fetch('./assets/app_config.json')
  .then((response) => response.json())
  .then((config: PingAuthenticationConfig) => {
    console.log('checking environment: ' + config.environment.production + "-" + config.idle_threshold );
    if (config.environment.production) {
      console.log('PROD enviroment');
      enableProdMode();
    }
    platformBrowserDynamic([{ provide: PING_CONFIG, useValue: config }])
      .bootstrapModule(AppModule)
      .catch((err) => console.error(err));
  });
