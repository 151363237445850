import { SvFlightLeg, SvFlightLegKey } from '../model/sv-flight-leg';
import { isNullOrUndefined } from 'util';
import { DatePipe } from '@angular/common';
import { SvTimeZoneService } from '../services-ws/sv-timezone.service';

export class SvUtilities {

    // public static getUniqueStr(flight: SvFlightLeg): string {
    //     return this.getUniqueKeyStr(flight.flightKey);
    // }

    public static getUniqueKeyStr(flightKey: SvFlightLegKey): string {
        return `${flightKey.depStation}_${flightKey.dupDepNumber}_${flightKey.flightNumber}_${flightKey.originDate}`;
    }

    static isEmpty<T>(value: T[]): boolean {
        return isNullOrUndefined(value) || value.length === 0;
    }

    public static isSameFlight(flightObj1: SvFlightLegKey, flightObj2: SvFlightLegKey, timeZoneService: SvTimeZoneService) {
        // console.log('comparing flights', flightObj1, flightObj2
        // , flightObj1 === flightObj2
        // , flightObj1.depStation === flightObj2.depStation
        // , flightObj1.dupDepNumber === flightObj2.dupDepNumber
        // , flightObj1.flightNumber === flightObj2.flightNumber
        // , flightObj1.originDate === flightObj2.originDate
        // );

        // the duplicate departure number might be a number or string; therefore, convert both to string to compare
        const dupDepNum1 = isNullOrUndefined(flightObj1.dupDepNumber) ? undefined : flightObj1.dupDepNumber.toString();
        const dupDepNum2 = isNullOrUndefined(flightObj2.dupDepNumber) ? undefined : flightObj2.dupDepNumber.toString();
        // console.log('comparing duplicate departure numbers', dupDepNum1, dupDepNum2, dupDepNum1 === dupDepNum2);

        // the dates might be a date object or long representation; therefore, convert both to string to compare
        const datePipe = new DatePipe(timeZoneService.locale);
        const date1Val = datePipe.transform(flightObj1.originDate, 'yyyy-MM-dd', '+0000');
        const date1Va2 = datePipe.transform(flightObj2.originDate, 'yyyy-MM-dd', '+0000');
        // console.log('comparing dates', date1Val, date1Va2, date1Val === date1Va2);

        return flightObj1.depStation === flightObj2.depStation && dupDepNum1 === dupDepNum2
            && flightObj1.flightNumber === flightObj2.flightNumber && date1Val === date1Va2;
    }
}
