import { Component, ViewChild, AfterViewInit  } from '@angular/core';
import { SvSidebarToggleService } from 'src/app/services/sv-sidebar-toggle.service';
import { MatDrawer } from '@angular/material/sidenav';

@Component({
  selector: 'sv-flight-view-router',
  templateUrl: './sv-flight-view-router.component.html'
})
export class SvFlightViewRouterComponent { 
  @ViewChild('matDrawSidenav') sidenavComponent: MatDrawer;

  constructor(private svSidebarToggleService: SvSidebarToggleService) { }

  ngAfterViewInit() {
    this.svSidebarToggleService.sidebarToggled$.subscribe(() => this.sidenavComponent.toggle());
  }
}
