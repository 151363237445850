import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { SvTimeZone } from '../model/sv-timezone';
import { isNullOrUndefined } from 'util';
import { WS_BASE } from './sv-ws-constants';

const WS_ROUTE = '/flight-leg-controller/retrieve-colors';

@Injectable({ providedIn: 'root' })
export class SvFlightColorService {

  uniqueColors: string[] = undefined;

  constructor(private http: HttpClient) {
    this.retrieveUniqueColors();
  }

  public loadingColors() {
    return isNullOrUndefined(this.uniqueColors);
  }

  private retrieveUniqueColors() {
    // create the request needed for the web service call
    const dataRequest = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };

    // create the request needed for the web service call
    this.http.get<string[]>(`${WS_BASE}${WS_ROUTE}`, dataRequest).subscribe(
      (data) => {
        if (!isNullOrUndefined(data)) { this.uniqueColors = data; }
        else { this.retry(); }
      },
      (error: HttpErrorResponse) => {
        // using function() causes error because "this" is lost. using () => instead preserves this within the function
        // retry every 5 seconds
        this.retry();
      }
    );
  }
  private retry() {
    setTimeout(() => { this.retrieveUniqueColors(); }, 5000);
  }
}
