export enum SvUserRole {
    USER = 'USER',
    LEAD = 'LEAD',
    SUPPORT = 'SUPPORT',    //TRKR828a
    ADMIN = 'ADMIN'
}
export class SvUserInfo {
    lastName: string;
    firstName: string;
    email: string;
    empId: number;
    role: SvUserRole;
    loginDurationMillis: number;

    constructor(empId: number, firstName: string, role: SvUserRole) {
        this.firstName = firstName;
        this.empId = empId;
        this.role = role;
    }
}

export class SvUserLogout {
    redirectUrl: string;

    constructor(redirectUrl: string) {
        this.redirectUrl = redirectUrl;
    }
}
