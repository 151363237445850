// import { SvFlightPageData, SvFlightLeg } from '../model/sv-flight-leg';
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Subscription, Observable } from 'rxjs';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { WS_BASE } from './sv-ws-constants';

const WS_FLIGHT_ROUTE = '/flight-info-controller/main-details';
const WS_REMARK_ROUTE = '/flight-info-controller/remarks';

@Injectable({ providedIn: 'root' })
export class SvSingleFlightService {

  constructor(private http: HttpClient) { }

  public retrieveFlightInfo(flightNumber, depStation, originDate, dupDepNumber): Observable<any> {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('flightNumber', flightNumber);
    httpParams = httpParams.append('depStation', depStation);
    httpParams = httpParams.append('originDate', originDate);
    httpParams = httpParams.append('dupDepNumber', dupDepNumber);

    const dataRequest = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }), params: httpParams };

    // TODO: Change this to be passed as a subscription with callbacks
    return this.http.get<any>(`${WS_BASE}${WS_FLIGHT_ROUTE}`, dataRequest);
  }

  public retrieveRemarkData(flightNumber, depStation, originDate, dupDepNumber): Observable<any> {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('flightNumber', flightNumber);
    httpParams = httpParams.append('depStation', depStation);
    httpParams = httpParams.append('originDate', originDate);
    httpParams = httpParams.append('dupDepNumber', dupDepNumber);

    const dataRequest = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }), params: httpParams };

    // TODO: Change this to be passed as a subscription with callbacks
    return this.http.get<any>(`${WS_BASE}${WS_REMARK_ROUTE}`, dataRequest);
  }
}
