import { Pipe, PipeTransform } from '@angular/core';
import { isUndefined } from 'util';


@Pipe({ name: 'svHtmlSpacePipe' })
export class SvHtmlSpacePipe implements PipeTransform {

  transform(value: string): string {
    if (!isUndefined(value)) { return value.replace(/ /g, '&#160;'); }
    return '';
  }
}
