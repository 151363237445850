import { Component, HostListener } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { SvRouteService } from './services/sv-route.service';

@Component({
  selector: 'sv-root',
  templateUrl: './app.component.html',
})
export class AppComponent {

  // private disableTemplate = false;

  constructor(private titleService: Title, private svRouteService: SvRouteService, ) {
    this.svRouteService.activationStarted$.subscribe((snapshot) => {
      // set the title if it exists
      if (snapshot.data.title) { this.titleService.setTitle(snapshot.data.title); }
      // if (snapshot.url[0].path === 'flightinfo') { this.disableTemplate = true; }
    });
  }
}
