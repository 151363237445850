import { Component, OnInit, OnDestroy, Renderer2, AfterViewInit, ViewChild, ElementRef, ChangeDetectionStrategy } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { SvFlightCargoStatus } from 'src/app/model/sv-flight-leg';
import { SvSingleFlightService } from './../../services-ws/sv-single-flight.service';
import { Subscription, timer } from 'rxjs';
import { HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { isNullOrUndefined } from 'util';
import { registerLocaleData } from '@angular/common';
import { SvTimeZoneService } from 'src/app/services-ws/sv-timezone.service';

@Component({
  // selector: 'app-sv-flight-window',
  templateUrl: './sv-flight-window.component.html',
  // styleUrls: ['./sv-flight-window.component.scss'],
  // changeDetection: ChangeDetectionStrategy.Default
})
export class SvFlightWindowComponent implements AfterViewInit {

  private flightInfoSubscription: Subscription;
  private flightRemarksSubscription: Subscription;

  eowExceptionsHeader = 'CODE   ACT     MIN     MAX     STD  DESCRIPTION';
  unitRemarksHeader = 'LN LOC  NBR  COMTY  WEIGHT DEST  ID/TYPE    PRI  REMARKS';

  // private cgParentBarWidth: number;

  // /* styling for the ZFW CG bar */
  // zfwCgParentBar: any;
  // private zfwCgOptimalWidth: number;
  // private zfwCgOptimalLeft: number;
  // zfwCgOptimalRange: any;
  // private zfwCgActualLeft: number;
  // zfwCgActualValue: any;

  // /* styling for the TOW CG bar */
  // towCgParentBar: any;
  // private towCgOptimalWidth: number;
  // private towCgOptimalLeft: number;
  // towCgOptimalRange: any;
  // private towCgActualLeft: number;
  // towCgActualValue: any;

  // update this variable when the data has been retrieved
  rowColor = '000000';
  mainHrStyle: any;
  alertStyle: any;


  mainFlight: any;
  remarksValue: string;
  unitRemarksValue: string;
  unitRemarksNotes: string;
  hasRestrictions: boolean;
  lastUpdateTime: number;
  lastUpdateTimeClass: string;
  lastUpdateTimeSubscription: Subscription;
  loadingFlight = true;
  loadingFlightError: string;
  loadingRemark = true;
  loadingRemarkError: boolean;

  constructor(private titleService: Title,
    private activatedRoute: ActivatedRoute,
    private singleFlightService: SvSingleFlightService,
    public svTimeZoneService: SvTimeZoneService,
    private renderer: Renderer2) {

    const { flightNumber, depStation } = this.activatedRoute.snapshot.queryParams;
    this.titleService.setTitle(`Flight Info - ${flightNumber}/${depStation}`);

    // this.titleService.setTitle('Flight Info ' + flightNumber);
    this.renderer.addClass(document.body, 'flight-window');
    this.resetData();
    this.updateFields();
  }
  ngAfterViewInit() {
    this.retrieveFlightInfo();
  }
  closeWindow() {
    console.log('closing window', window);
    window.close();
  }
  updateFlightInfo() {
    console.log('updating data');
    this.retrieveFlightInfo();
  }
  private retrieveFlightInfo() {
    this.resetData();

    // retrieve the latest flight details
    const { flightNumber, depStation, originDate, dupDepNumber } = this.activatedRoute.snapshot.queryParams;
    this.getMainFlight(flightNumber, depStation, originDate, dupDepNumber);
  }

  private getMainFlight(flightNumber, depStation, originDate, dupDepNumber) {
    // cancel the previous subscription
    if (this.flightInfoSubscription) { this.flightInfoSubscription.unsubscribe(); }

    // create the request needed for the web service call
    this.flightInfoSubscription = this.singleFlightService.retrieveFlightInfo(flightNumber, depStation, originDate, dupDepNumber).subscribe(
      (data) => {
        console.log('FLIGHT:', data);
        this.mainFlight = data;

        if (isNullOrUndefined(this.mainFlight) || isNullOrUndefined(this.mainFlight.wbdcDetails)) {
          this.loadingFlightError = 'error occurred retrieving data';
        } else if (isNullOrUndefined(this.mainFlight.wbdcDetails.flight)) {
          if (isNullOrUndefined(this.mainFlight.wbdcDetails.fltError)) {
            this.loadingFlightError = 'error occurred retrieving data';
          } else {
            this.loadingFlightError = this.mainFlight.wbdcDetails.fltError;
          }
        } else {
          this.hasRestrictions = this.mainFlight.wbiDetails.passengerCount || this.mainFlight.wbiDetails.standbyFrtUnits
            || this.mainFlight.wbiDetails.mtow || this.mainFlight.wbiDetails.maxZerofuelWeight || this.mainFlight.wbiDetails.cargoWeight
            || this.mainFlight.wbiDetails.ttlFrtWeight || this.mainFlight.wbiDetails.maxRampWeight || this.mainFlight.wbiDetails.remarks;

          /* retrieve remarks only if the response was sucessful */
          this.getRemarks(flightNumber, depStation, originDate, dupDepNumber);

          // this.computeCgData();
          // update the row color
          if (!isNullOrUndefined(this.mainFlight.rowColor)) {
            this.rowColor = this.mainFlight.rowColor;
          }

          // update the range information for the web
          this.updateFields();
          this.lastUpdateTime = Date.now();
          this.lastUpdateTimeClass = undefined;

          // cancel the last update time subscription
          if (!isNullOrUndefined(this.lastUpdateTimeSubscription)) { this.lastUpdateTimeSubscription.unsubscribe(); }
          // do not need to check every minute, just wait until the first color change should occur (add a few milliseconds to ensure the first check will work)
          // this.lastUpdateTimeSubscription = timer(480010, 60000).subscribe(() => {
          this.lastUpdateTimeSubscription = timer(600010, 60000).subscribe(() => {
            const nowTime = Date.now();
            if (nowTime - 600000 > this.lastUpdateTime) { // check if it has been 10 minutes since the last update
              this.lastUpdateTimeClass = 'error'; // change the color of the update time if it has not been updated in a while
              this.lastUpdateTimeSubscription.unsubscribe();  // unsubscribe since this is the last check that is needed
            }
            // else if (nowTime - 480000 > this.lastUpdateTime) { // check if it has been 8 minutes since the last update
            //   this.lastUpdateTimeClass = 'warning';
            // }
          });
        }
        this.loadingFlight = false;
      },

      (error: HttpErrorResponse) => {
        console.log('FLIGHT error:', error);
        this.loadingFlight = false;
        this.loadingFlightError = error.message;
      },
    );
  }
  private getRemarks(flightNumber, depStation, originDate, dupDepNumber) {
    // cancel the previous subscription
    if (this.flightRemarksSubscription) { this.flightRemarksSubscription.unsubscribe(); }

    this.flightRemarksSubscription = this.singleFlightService.retrieveRemarkData(flightNumber, depStation, originDate, dupDepNumber)
      .subscribe(
        (data) => {
          console.log('REMARKS:', data);

          // get the values from this object
          if (isNullOrUndefined(data)) {
            this.loadingRemarkError = true;
          } else {
            if (!isNullOrUndefined(data.remarks)) { this.remarksValue = data.remarks; }
            if (!isNullOrUndefined(data.wbrDetails)) {
              if (!isNullOrUndefined(data.wbrDetails.unitRemarks)) { this.unitRemarksValue = data.wbrDetails.unitRemarks; }
              if (!isNullOrUndefined(data.wbrDetails.notes)) { this.unitRemarksNotes = data.wbrDetails.notes; }
            }
          }

          this.loadingRemark = false;
        },

        (error: HttpErrorResponse) => {
          console.log('REMARKS error:', error);
          this.loadingRemark = false;
          this.loadingRemarkError = true;
        }
      );
  }

  // private computeCgData() {
  // // calculate the ZFW ranges
  // const zfwMin = this.mainFlight.wbdcDetails.zfwFwd;
  // const zfwMax = this.mainFlight.wbdcDetails.zfwAft;
  // const oldZfwOptimalMin = this.mainFlight.wbdcDetails.optimalZfwFwdRange;
  // const oldZfwOptimalMax = this.mainFlight.wbdcDetails.optimalZfwAftRange;
  // const oldZfwActual = this.mainFlight.wbdcDetails.zfwCg;
  // this.zfwCgOptimalLeft = this.covertRange(oldZfwOptimalMin, zfwMin, zfwMax, 0, this.cgParentBarWidth);
  // const zfwCgOptimalMax = this.covertRange(oldZfwOptimalMax, zfwMin, zfwMax, 0, this.cgParentBarWidth);
  // this.zfwCgActualLeft = this.covertRange(oldZfwActual, zfwMin, zfwMax, 0, this.cgParentBarWidth);
  // this.zfwCgOptimalWidth = zfwCgOptimalMax - this.zfwCgOptimalLeft;
  // console.log(`oldZfwOptimalMin:${oldZfwOptimalMin} --> zfwCgOptimalLeft:${this.zfwCgOptimalLeft}`);
  // console.log(`oldZfwOptimalMax:${oldZfwOptimalMax} --> zfwCgOptimalMax:${zfwCgOptimalMax}`);
  // console.log(`oldZfwActual:${oldZfwActual} --> zfwCgActualLeft:${this.zfwCgActualLeft}`);
  // console.log(`zfwCgOptimalWidth:${this.zfwCgOptimalWidth}`);

  // // calculate the TOW ranges
  // const towMin = this.mainFlight.wbdcDetails.towFwd;
  // const towMax = this.mainFlight.wbdcDetails.towAft;
  // const oldTowOptimalMin = this.mainFlight.wbdcDetails.optimalTowFwdRange;
  // const oldTowOptimalMax = this.mainFlight.wbdcDetails.optimalTowAftRange;
  // const oldTowActual = this.mainFlight.wbdcDetails.towCg;
  // this.towCgOptimalLeft = this.covertRange(oldTowOptimalMin, towMin, towMax, 0, this.cgParentBarWidth);
  // const towCgOptimalMax = this.covertRange(oldTowOptimalMax, towMin, towMax, 0, this.cgParentBarWidth);
  // this.towCgActualLeft = this.covertRange(oldTowActual, towMin, towMax, 0, this.cgParentBarWidth);
  // this.towCgOptimalWidth = towCgOptimalMax - this.towCgOptimalLeft;
  // console.log(`oldTowOptimalMin:${oldTowOptimalMin} --> towCgOptimalLeft:${this.towCgOptimalLeft}`);
  // console.log(`oldTowOptimalMax:${oldTowOptimalMax} --> towCgOptimalMax:${towCgOptimalMax}`);
  // console.log(`oldTowActual:${oldTowActual} --> towCgActualLeft:${this.towCgActualLeft}`);
  // console.log(`towCgOptimalWidth:${this.towCgOptimalWidth}`);
  // }
  // private covertRange(oldValue: number, oldMin: number, oldMax: number, newMin: number, newMax: number) {
  //   const oldRange = (oldMax - oldMin);
  //   let newValue;
  //   if (oldRange === 0) {
  //     newValue = newMin;
  //   } else {
  //     const newRange = (newMax - newMin);
  //     newValue = (((oldValue - oldMin) * newRange) / oldRange) + newMin;
  //   }

  //   return newValue;
  // }

  private resetData() {
    // TODO - do not update the screen if the flight is locked or an error occurs

    /* reset all the values */
    // this.cgParentBarWidth = 300;
    // /* styling for the ZFW CG bar */
    // this.zfwCgParentBar = {};
    // this.zfwCgOptimalWidth = 0;
    // this.zfwCgOptimalLeft = 0;
    // this.zfwCgOptimalRange = {};
    // this.zfwCgActualLeft = 0;
    // this.zfwCgActualValue = {};
    // /* styling for the TOW CG bar */
    // this.towCgParentBar = {};
    // this.towCgOptimalWidth = 0;
    // this.towCgOptimalLeft = 0;
    // this.towCgOptimalRange = {};
    // this.towCgActualLeft = 0;
    // this.towCgActualValue = {};

    // update this variable when the data has been retrieved
    this.rowColor = '000000';
    this.mainHrStyle = {};
    this.alertStyle = {};
    this.mainFlight = undefined;
    this.remarksValue = undefined;
    this.hasRestrictions = undefined;
    this.unitRemarksValue = undefined;
    this.unitRemarksNotes = undefined;
    // lastUpdateTime = undefined;
    this.loadingFlight = true;
    this.loadingFlightError = undefined;
    this.loadingRemark = true;
    this.loadingRemarkError = undefined;
  }
  private updateFields() {
    // this.zfwCgParentBar = { 'width': `${this.cgParentBarWidth}px` };
    // this.zfwCgOptimalRange = { 'width': `${this.zfwCgOptimalWidth}px`, 'left': `${this.zfwCgOptimalLeft}px` };
    // this.zfwCgActualValue = { 'width': '6px', 'left': `${this.zfwCgActualLeft}px` };

    // this.towCgParentBar = { 'width': `${this.cgParentBarWidth}px` };
    // this.towCgOptimalRange = { 'width': `${this.towCgOptimalWidth}px`, 'left': `${this.towCgOptimalLeft}px` };
    // this.towCgActualValue = { 'width': '6px', 'left': `${this.towCgActualLeft}px` };

    // this.mainHrStyle = { 'border': `2px solid #${this.rowColor}` };
    this.mainHrStyle = { 'border': `1px solid black` };
    this.alertStyle = { 'color': `#${this.rowColor}` };
  }

  getCargoTooltip(svFlightCargoStatus: SvFlightCargoStatus): string {
    if (svFlightCargoStatus === SvFlightCargoStatus.BOTH) { return 'WBP & DRP finalized'; } // TRKR872
    if (svFlightCargoStatus === SvFlightCargoStatus.DRP) { return 'DRP finalized'; }
    if (svFlightCargoStatus === SvFlightCargoStatus.FINALIZED) { return 'cargo finalized'; }
  }

  getCargoStatus(svFlightCargoStatus: SvFlightCargoStatus): string {
    if (svFlightCargoStatus === SvFlightCargoStatus.BOTH) { return 'WBP & DRP Final'; } // TRKR872
    if (svFlightCargoStatus === SvFlightCargoStatus.DRP) { return 'DRP Final'; }
    if (svFlightCargoStatus === SvFlightCargoStatus.FINALIZED) { return 'DRP & WBP Final'; }
  }
}
