import { NgModule } from '@angular/core';
import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
// this module is needed for the ngModel binding required for the PrimeNG SliderModule range to work
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// import module for ngx-table processing
/** moved the ngx-datatable project to this workspace in order to customize scrolling and other features */
import { NgxDatatableModule } from 'src/datatable';

// TRKR828a - Pingfederate integration
import { PingAuthenticationModule, PingAuthenticationConfig, Log } from '@techops-ui/ping-authentication';

// import module for routing
import { AppRoutingModule } from './app-routing.module';
// Ng5 slider module
import { Ng5SliderModule } from 'ng5-slider';
// PrimeNG imports
import { DialogModule } from 'primeng/dialog';
// import { SliderModule } from 'primeng/slider';
// import { InputTextModule } from 'primeng/inputtext';
// import { SpinnerModule } from 'primeng/spinner';
import { InputMaskModule } from 'primeng/inputmask';
import { ButtonModule } from 'primeng/button';
// import { MenuModule } from 'primeng/menu';
import { PanelModule } from 'primeng/panel';
import { MultiSelectModule } from 'primeng/multiselect';
import { ListboxModule } from 'primeng/listbox';
// import { DropdownModule } from 'primeng/dropdown';
import { SpinnerModule } from 'primeng/spinner';
// import { FieldsetModule } from 'primeng/fieldset';
import { TabViewModule } from 'primeng/tabview';
import { SplitButtonModule } from 'primeng/splitbutton';
import { CheckboxModule } from 'primeng/checkbox';
import { AccordionModule } from 'primeng/accordion';
import { ToastModule } from 'primeng/toast';
import { MessagesModule } from 'primeng/messages';

import { MatSidenavModule } from '@angular/material/sidenav';
// import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
// import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDialogModule } from '@angular/material/dialog';

import { AppComponent } from './app.component';
import { SvMainHeaderTopComponent } from './components/sv-header/sv-main-header-top/sv-main-header-top.component';
import { SvMainHeaderCompleteComponent } from './components/sv-header/sv-main-header-complete/sv-main-header-complete.component';
import { SvBodyComponent } from './components/sv-body/sv-body.component';
import { SvContentComponent } from './components/sv-body/sv-content/sv-content.component';
import { SvContentFlightViewComponent } from './components/sv-body/sv-content/sv-content-flight-view/sv-content-flight-view.component';
import { SvFlightViewRouterComponent } from './routers/sv-flight-view-router.component';
import { SvContentDeskLoadComponent } from './components/sv-body/sv-content/sv-content-desk-load/sv-content-desk-load.component';
import { SvSidebarComponent } from './components/sv-body/sv-sidebar/sv-sidebar.component';
import { SvHeaderComponent } from './components/sv-header/sv-header.component';
import { SvFooterComponent } from './components/sv-footer/sv-footer.component';
import { SvHeaderLinksComponent } from './components/sv-header/sv-header-links/sv-header-links.component';
import { SvHeaderTimeComponent } from './components/sv-header/sv-header-time/sv-header-time.component';
import { SvHeaderLogoutComponent } from './components/sv-header/sv-header-logout/sv-header-logout.component';
import { SvHeaderWelcomeComponent } from './components/sv-header/sv-header-welcome/sv-header-welcome.component';
import { SvHeaderFlightViewComponent } from './components/sv-header/sv-header-options/sv-header-flight-view/sv-header-flight-view.component';
import { SvHeaderDeskLoadComponent } from './components/sv-header/sv-header-options/sv-header-desk-load/sv-header-desk-load.component';
import { SvDateStrPipe } from './pipes/sv-date.pipe';
import { SvHtmlSpacePipe } from './pipes/sv-html-space.pipe';
import { SvFosPipe } from './pipes/sv-fos.pipe';
import { SvLimitsNumberPipe, SvNoCommaNumberPipe } from './pipes/sv-limits-number.pipe';
import { SvFlightStoreCurPage, SvFlightStoreTotalPages, SvFlightStoreService } from './helper/sv-flight-store';
import { SvUnauthorizedComponent } from './components/sv-unauthorized/sv-unauthorized';
import { SvRetrieveErrorComponent } from './components/sv-retrieve-error/sv-retrieve-error';
import { SvReminderComponent } from './components/sv-reminder/sv-reminder.component';

import { SvLogOut } from './services-ws/sv-log-out';
import { SvFlightLegService } from './services-ws/sv-flight-leg.service';
import { SvRouteService } from './services/sv-route.service';
import { SvTimeZoneService } from './services-ws/sv-timezone.service';
import { SvFlightColorService } from './services-ws/sv-flight-color.service';
import { SvSidebarToggleService } from './services/sv-sidebar-toggle.service';
import { SvOpenTransferFlightService } from './services/sv-open-transfer-flight.service';
import { SvDialogService } from './services/services-dialog/sv-dialog.service';
import { SvStorageService } from './services/sv-storage-listener.service';
import { SvReminderService } from './components/sv-reminder/sv-reminder.services';

import { SvFlightWindowComponent } from './components/sv-flight-window/sv-flight-window.component';
import { SvUserManagementService } from './services/sv-user-management.service';


import { DecimalPipe } from '@angular/common';
import { TransferBatchIdPipe } from './dialog/sv-transfer-flight-history-dialog';

@NgModule({
  declarations: [
    AppComponent,
    SvMainHeaderTopComponent,
    SvMainHeaderCompleteComponent,
    SvBodyComponent,
    SvContentComponent,
    SvContentFlightViewComponent,
    SvFlightViewRouterComponent,
    SvContentDeskLoadComponent,
    SvSidebarComponent,
    SvHeaderComponent,
    SvFooterComponent,
    SvHeaderLinksComponent,
    SvHeaderTimeComponent,
    SvHeaderLogoutComponent,
    SvHeaderWelcomeComponent,
    SvHeaderFlightViewComponent,
    SvHeaderDeskLoadComponent,
    SvDateStrPipe,
    SvHtmlSpacePipe,
    TransferBatchIdPipe,
    SvFosPipe,
    SvLimitsNumberPipe,
    SvNoCommaNumberPipe,
    SvFlightStoreCurPage,
    SvFlightStoreTotalPages,
    SvFlightWindowComponent,
    SvUnauthorizedComponent,
    SvRetrieveErrorComponent,
    SvReminderComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    //TRKR828a - Pingfederate integration --------------
    PingAuthenticationModule.forRoot({
      post_logout_redirect_uri: encodeURIComponent(`${window.location.origin}/#/signout-callback`) }),
    AppRoutingModule,
    NgxDatatableModule,
    Ng5SliderModule,
    DialogModule,
    InputMaskModule,
    ButtonModule,
    PanelModule,
    MultiSelectModule,
    ListboxModule,
    SpinnerModule,
    TabViewModule,
    SplitButtonModule,
    CheckboxModule,
    AccordionModule,
    ToastModule,
    MessagesModule,
    MatSidenavModule,
    // FieldsetModule,
    MatFormFieldModule,
    MatInputModule,
    // MatIconModule,
    MatTooltipModule,
    MatDialogModule,
    HttpClientModule
  ],
  exports: [],
  providers: [
    Title,
    SvLogOut,
    SvFlightLegService,
    SvRouteService,
    SvTimeZoneService,
    SvFlightColorService,
    SvSidebarToggleService,
    SvOpenTransferFlightService,
    SvFlightStoreService,
    DecimalPipe,
    SvDialogService,
    SvStorageService,
    SvReminderService,
    SvUserManagementService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
