import { Pipe, PipeTransform } from '@angular/core';
import { formatDate } from '@angular/common';
import { SvTimeZoneService } from 'src/app/services-ws/sv-timezone.service';
import { isUndefined } from 'util';

/**
 * Returns a string formatted date object.
 * Usage:
 *   value | svDateStrPipe
 * Example:
 *   {{ new Date() | svDateStrPipe }}
 *   returns : formatted date
*/
@Pipe({ name: 'svDateStrPipe' })
export class SvDateStrPipe implements PipeTransform {

  constructor(private svTimeZoneService: SvTimeZoneService) { }

  transform(value: number, format: string, timeZone?: string): string {
    if (!isUndefined(value) && !isUndefined(format)) {
      return formatDate(value, format, this.svTimeZoneService.locale, !isUndefined(timeZone) ? timeZone : this.svTimeZoneService.localTimeZone);
    }

    return 'n/a';
  }
}
