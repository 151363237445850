import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { SvFlightPageData, SvFlightLeg } from '../model/sv-flight-leg';
import { Subscription, Observable } from 'rxjs';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { WS_BASE } from './sv-ws-constants';
import { isNullOrUndefined } from 'util';

const WS_ROUTE = '/flight-leg-controller/filter-data';

@Injectable({ providedIn: 'root' })
export class SvFlightLegService {

  /** subscription for retrieving flight data */
  private flightSubscription: Subscription;

  constructor(private http: HttpClient) { }

  public retrieveFlights(httpParams: HttpParams,
    callbackData: (flightData: SvFlightPageData, dataKey: Date, error?: HttpErrorResponse) => boolean,
    callbackSuccess: (dataKey: Date) => any,
    callbackFailure: (error: HttpErrorResponse, dataKey: Date) => any,
    callbackComplete: (dataKey: Date) => any, dataKey: Date): void {

    // cancel the previous subscription
    if (this.flightSubscription) { this.flightSubscription.unsubscribe(); }

    const dataRequest = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }), params: httpParams };
    // create the request needed for the web service call
    this.flightSubscription = this.http.get<SvFlightPageData>(`${WS_BASE}${WS_ROUTE}`, dataRequest).subscribe(
      (data) => {
        if (callbackData(data, dataKey)) {
          callbackSuccess(dataKey);
          callbackComplete(dataKey);
        }
      },
      (error: HttpErrorResponse) => {
        console.log('FLIGHT LEG error', error);
        if (callbackData(undefined, dataKey, error)) {
          callbackFailure(error, dataKey);
          callbackComplete(dataKey);
        }
      }
    );
  }
}
