export enum SvFlightCargoStatus {
    FINALIZED = 'FINALIZED',
    DRP = 'DRP',
    BOTH = 'BOTH', /** TRKR872 */
    NONE = 'NONE'
}
export enum SvFlightLockType {
    SOME = 'SOME',
    ALL = 'ALL',
    NONE = 'NONE'
}
export enum SvFlighRowFontColor {
    BLACK = 'BLACK',
    BROWN = 'BROWN',
    BLUE_DARK = 'BLUE_DARK',
    BLUE_LIGHT = 'BLUE_LIGHT',
    GRAY = 'GRAY',
    GREEN = 'GREEN',
    ORANGE = 'ORANGE',
    PURPLE = 'PURPLE',
    YELLOW = 'YELLOW',
    RED = 'RED'
}

export class SvFlightPageData {
    /** the total number of elements */
    totalRowCount: number;
    /** TRKR872 - last message update */
    lastMsgUpdt: number;
    /** the index to start the pageResults */
    startIndex: number;
    /** the items for this page */
    pageResults: SvFlightLeg[];

    // TRKR872 constructor(totalRowCount: number, startIndex: number, pageResults: SvFlightLeg[]) {
    constructor(totalRowCount: number, lastMsgUpdt: number, startIndex: number, pageResults: SvFlightLeg[]) {    // TRKR872
        this.totalRowCount = totalRowCount;
        this.lastMsgUpdt = lastMsgUpdt; // TRKR872
        this.startIndex = startIndex;
        this.pageResults = pageResults;
    }
}

//TRKR825a - Added fuelInfo 
export class SvFlightLeg {
    // identify a unique flight
    flightKey: SvFlightLegKey;
    // day this flight originated
    originDayStr: string;
    // column 1
    loadDesk: string;
    // column 2
    passengersFinalized: boolean;
    cargoStatus: SvFlightCargoStatus;
    fuelReleased: boolean;
    crewRestSeats: boolean;
    // column 3
    flightMelInfo: string;
    // column 5
    arrStation: string;
    // column 6
    schedDepTime: string;
    // column 7
    latestDepTime: string;
    // column 8
    autoReleaseDisabled: boolean;
    phaseStatus: string;
    lockType: SvFlightLockType;
    // column 9
    equipInfo: string;
    // column 10
    runwayFieldCondition: string;
    // column 11
    temperatureInfo: string;
    // column 12
    passengerInfo: string;
    // column 13
    fuelInfo: string;
    // column 14
    cargoInfo: string;
    // column 15
    spreadInfo: string;
    // column 16
    mtowInfo: string;
    // column 17
    equipTail: string;
    // column 18
    fma: string;
    // column 19
    alert: string;
    // column 20
    userNote: string;
    // column 21
    supervisorNote: string;

    // this is used to display a popup
    wbdcResponse: string;

    // this is used to determine if a flight has a reminder (not returned from the backend)
    hasReminder: boolean;

    // used for formatting the table
    rowFontColor: SvFlighRowFontColor;
    rowStyleColor: string;
    rowStyle: string;
    rowSeparator: boolean;
    alpRowBlockInd: boolean; //TRKR851
}

export class SvFlightLegKey {
    // column 4
    depStation: string;
    // not displayed
    flightNumber: number;
    originDate: number;
    dupDepNumber: number;
}
