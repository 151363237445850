import { Component } from '@angular/core';
import { timer } from 'rxjs';
import { SvTimeZoneService } from 'src/app/services-ws/sv-timezone.service';

@Component({
  selector: 'sv-header-time',
  templateUrl: './sv-header-time.component.html'
})
export class SvHeaderTimeComponent {

  headerDateFormat = 'HH:mm:ss EEE MMM dd';
  localDate: Date;

  constructor(public timeZoneService: SvTimeZoneService) {
    //  update the time every second and start without a delay
    timer(0, 1000).subscribe(() => { this.localDate = new Date(); });
  }
}
