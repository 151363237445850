import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, Subject, of, timer } from 'rxjs';
import { SvUserInfo, SvUserRole } from '../model/sv-user-info';
import { HttpHeaders, HttpClient, HttpErrorResponse } from '@angular/common/http';
import { WS_BASE } from '../services-ws/sv-ws-constants';
import { map, catchError } from 'rxjs/operators';
import { isNullOrUndefined } from 'util';
import { SvLogOut } from '../services-ws/sv-log-out';
import { PingAuthenticationService } from '@techops-ui/ping-authentication';  //TRKR828a
import { PingAuthenticationConfig, PING_CONFIG } from '@techops-ui/ping-authentication'; //TRKR839



var loginDuration = 0;

fetch('./assets/app_config.json')
  .then((response) => response.json())
  .then((config: PingAuthenticationConfig) => {
    console.log('config login duration value: ' + config.environment.loginDurationMinutes );
    loginDuration = config.environment.loginDurationMinutes;
  });



const USER_INFO_KEY = 'user-info';
const USER_SMIDENTITY = 'SMIDENTITY';
const USER_SMSESSION = 'SMSESSION';
const LOGIN_TIME = 'LOGIN_TIME';
const DEFAULT_TIMEOUT = 1800000;
const WS_ROUTE = '/pingfederate-controller/login';
const WS_USER_MANAGEMENT_BASE = '/user-info-details';           //TRKR828a
const WS_USER_MANAGEMENT_RETRIEVE_ALL = '/retrieve-all-users';  //TRKR828a

var empId:string; //TRKR828a

@Injectable({ providedIn: 'root' })
export class AuthGuardService implements CanActivate {

  svUserInfo: SvUserInfo;
  private logoutTimeout: any;


  isLoggedIn$: Observable<boolean>; //TRKR828a
  firstName$: Observable<string>;   //TRKR828a
  uId$: Observable<string>;         //TRKR828a


  constructor(private http: HttpClient,
    private pingAuthService: PingAuthenticationService, //TRKR828a
    private router: Router,                             //TRKR828a
    private svLogOut: SvLogOut) { }



  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
  //TRKR828a  return this.validateUser(
  return this.validateUserWithPingFederate(  //TRKR828a
      // successfully retrieved user information
      (): boolean => {
        return true;
      },
      // received error retrieving user information
      (): Observable<boolean> => {
        return of(true);
      });
  }

//
// TRKR828 - Pingfederate integration
//
public validateUserWithPingFederate(callbackSuccess: () => boolean,
callbackFailure: (error) => Observable<boolean>): Observable<boolean> | boolean {
    console.log('getting user details...')
     this.isLoggedIn$ = this.pingAuthService.loggedIn$;
     var empName:string
    if(this.isLoggedIn$){
      console.log('user is logged in');
      // TRKR872 this.firstName$ = this.pingAuthService.profile$.pipe(map(u => u.given_name));
      this.firstName$ = this.pingAuthService.profile$.pipe(map(u => u.last_name)); // TRKR872
      this.uId$ = this.pingAuthService.profile$.pipe(map(u => u.uid));
      this.firstName$.subscribe(
        data => empName = data,
        error => console.log(error)
      );
      this.uId$.subscribe(
       data => empId = data,
       error => console.log(error)
     );
     console.log('User Name = ' + empName);
     console.log('User Id   = ' + empId);


    
  let list: User[];
  var role:SvUserRole;
  return this.http.get<User[]>(`${WS_BASE}${WS_USER_MANAGEMENT_BASE}${WS_USER_MANAGEMENT_RETRIEVE_ALL}`).pipe(
    map(data => {
      // need to ensure the correct data has been returned
      if (!isNullOrUndefined(data)) {
        list = data
        //console.log('> valid USER INFO :', data);
        list.forEach(function (value) {
          if(value.employeeId == Number(empId)){
            role = <SvUserRole> value.userRole;
            console.log('after enum: ' + role);
          }
        });
      
        //
        // ID not found in Users table, default role as 'USER' 
        //
        if(isNullOrUndefined(role)){
          role = SvUserRole.USER;
        }
        if(isNullOrUndefined(this.svUserInfo)){
          this.svUserInfo = new SvUserInfo(Number(empId), empName, role); 
          this.svUserInfo.loginDurationMillis = loginDuration*60*1000;
          localStorage.setItem(LOGIN_TIME, Date.now().toString());
          this.setupLogoutTimer(this.svUserInfo.loginDurationMillis);

        }

      } else {
         console.log('> Unable to read users from DB', data);
         this.router.navigate(['/errorretrievinguserinfo']);
      }
      // return true;
      return callbackSuccess();
    }), catchError((error) => {
      console.log('> USER INFO error', error);
      // return of(true);
      return callbackFailure(error);
    })
  );
}

}























  public validateUser(callbackSuccess: () => boolean,
    callbackFailure: (error) => Observable<boolean>): Observable<boolean> | boolean {

    // user details have already been retrieved and set
    if (!isNullOrUndefined(this.svUserInfo)) { return callbackSuccess(); }

    // console.log('site cookies', document.cookie);
    // console.log('local storage', localStorage);
    // this regular expresion is copied directly from the site https://developer.mozilla.org/en-US/docs/Web/API/Document/cookie
    // console.log('document cookies', document.cookie);
    const documentCookie = document.cookie;
    const cookieSmIdentity = documentCookie.replace(/(?:(?:^|.*;\s*)SMIDENTITY\s*\=\s*([^;]*).*$)|^.*$/, '$1');
    const cookieSmSession = documentCookie.replace(/(?:(?:^|.*;\s*)SMSESSION\s*\=\s*([^;]*).*$)|^.*$/, '$1');
    console.log('session cookies', {
      // 'cookieSessionId': cookieSessionId,
      // 'cookieVcapId': cookieVcapId,
      'cookieSmIdentity': cookieSmIdentity,
      'cookieSmSession': cookieSmSession
    });

    // TODO switch to the RegExp object so the variable name can be used
    // const cookieSessionIdTest = document.cookie.replace(new RegExp(`(?:(?:^|.*;\\s*)${USER_JSESSIONID}\\s*\\=\\s*([^;]*).*$)|^.*$`), '$1');
    const storageSmIdentity = localStorage.getItem(USER_SMIDENTITY);
    const storageSmSession = localStorage.getItem(USER_SMSESSION);
    const userInfoJson = localStorage.getItem(USER_INFO_KEY);

    if (cookieSmIdentity === storageSmIdentity && cookieSmSession === storageSmSession && !isNullOrUndefined(userInfoJson)) {
      const data: SvUserInfo = JSON.parse(userInfoJson);
      if (!isNullOrUndefined(data) && !isNullOrUndefined(data.empId) && !isNullOrUndefined(data.role)) {
        this.svUserInfo = data;
        this.setupLogoutTimer(this.svUserInfo.loginDurationMillis);
        return callbackSuccess();
      }
    }

    console.log('retrieve user information');
    return this.retrieveUserInfo(cookieSmIdentity, cookieSmSession, callbackSuccess, callbackFailure);
  }

  private retrieveUserInfo(cookieSmIdentity: string, cookieSmSession: string,
    callbackSuccess: () => boolean,
    callbackFailure: (error) => Observable<boolean>): Observable<boolean> {

    // remove all values from the local storage
    localStorage.clear();

    // create a user with the default role
    this.svUserInfo = new SvUserInfo(-1, '', SvUserRole.USER);

    // retrieve the user info from the backend
    const dataRequest = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };

    return this.http.get<SvUserInfo>(`${WS_BASE}${WS_ROUTE}`, dataRequest).pipe(
      map(data => {
        // need to ensure the correct data has been returned
        if (!isNullOrUndefined(data) && !isNullOrUndefined(data.empId)) {
          console.log('valid USER INFO', data);
          this.svUserInfo = data;
          // console.log('USER role', this.svUserInfo.role);
          localStorage.setItem(USER_INFO_KEY, JSON.stringify(this.svUserInfo));
          localStorage.setItem(USER_SMIDENTITY, cookieSmIdentity);
          localStorage.setItem(USER_SMSESSION, cookieSmSession);
          localStorage.setItem(LOGIN_TIME, Date.now().toString());
          this.setupLogoutTimer(this.svUserInfo.loginDurationMillis);
        } else {
          // console.log('invalid USER INFO', data);
        }
        // return true;
        return callbackSuccess();
      }), catchError((error) => {
        console.log('USER INFO error', error);
        // return of(true);
        return callbackFailure(error);
      })
    );
  }

  private setupLogoutTimer(durationMillis: number) {
    console.log(`setupLogoutTimer durationMillis=${durationMillis}`);
    if (isNullOrUndefined(durationMillis)) {
      console.log('setting default timeout', DEFAULT_TIMEOUT);
      durationMillis = DEFAULT_TIMEOUT;
    }

    const loginTimeStr = localStorage.getItem(LOGIN_TIME);
    const loginTime = +loginTimeStr;
    const timeLeft = durationMillis - (Date.now() - loginTime);
    console.log(`determined remaining time loginTimeStr=${loginTimeStr}, loginTime=${loginTime}, timeLeft=${timeLeft}`, this.logoutTimeout);

    if (!isNullOrUndefined(this.logoutTimeout)) { clearTimeout(this.logoutTimeout); }
    this.logoutTimeout = setTimeout(() => { this.svLogOut.logout(); }, timeLeft);
  }
}

//
// TRKR828a
//
class User {
  employeeId: number;
  userRole: string;
}