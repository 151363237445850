import { Injectable } from '@angular/core';
import { Router, ActivationStart, ActivationEnd, NavigationEnd, ActivatedRouteSnapshot } from '@angular/router';

import { filter } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class SvRouteService {

  private activationStartSource = new Subject<ActivatedRouteSnapshot>();
  activationStarted$ = this.activationStartSource.asObservable();
  activationStartSnapshot: ActivatedRouteSnapshot;

  private activationEndSource = new Subject<ActivatedRouteSnapshot>();
  activationEnded$ = this.activationEndSource.asObservable();
  activationEndSnapshot: ActivatedRouteSnapshot;

  // private navigationEndSource = new Subject();
  // navigationEnded$ = this.navigationEndSource.asObservable();

  constructor(private router: Router) {
    // only process the ActivationStart event
    this.router.events
      // with ActivationStart, the title will change at the same time as the body content and not after like it would with ActivationEnd
      .pipe(filter(event => event instanceof ActivationStart), filter((event: ActivationStart) => event.snapshot.outlet === 'primary'))
      .subscribe((event: ActivationStart) => {
        this.activationStartSnapshot = event.snapshot;
        this.activationStartSource.next(this.activationStartSnapshot);
      });

    // only process the ActivationEnd event
    this.router.events
      .pipe(filter(event => event instanceof ActivationEnd), filter((event: ActivationEnd) => event.snapshot.outlet === 'primary'))
      .subscribe((event: ActivationEnd) => {
        this.activationEndSnapshot = event.snapshot;
        this.activationEndSource.next(this.activationEndSnapshot);
      });

    // // only process the NavigationEnd event
    // this.router.events
    //   .pipe(filter(event => event instanceof NavigationEnd))
    //   .subscribe((event: NavigationEnd) => { this.navigationEndSource.next(); });
  }
}
