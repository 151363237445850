import { Injectable, OnDestroy } from '@angular/core';
// import { Observable } from 'rxjs/Observable';
// import { Subject } from 'rxjs/Subject';
// import 'rxjs/add/operator/share';
import { Subject } from 'rxjs';
import { share } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class SvStorageService implements OnDestroy {

    private onSubject = new Subject<{ key: string, value: any }>();
    public changes$ = this.onSubject.asObservable().pipe(share());

    constructor() {
        // console.log('StorageService constructor');
        this.start();
    }

    ngOnDestroy() {
        this.stop();
    }

    public getStorage() {
        const s = [];
        for (let i = 0; i < localStorage.length; i++) {
            s.push({
                key: localStorage.key(i),
                value: JSON.parse(localStorage.getItem(localStorage.key(i)))
            });
        }
        return s;
    }

    public store(key: string, data: any): void {
        localStorage.setItem(key, JSON.stringify(data));
        // the local application doesn't seem to catch changes to localStorage...
        this.onSubject.next({ key: key, value: data });
    }

    public clear(key) {
        localStorage.removeItem(key);
        // the local application doesn't seem to catch changes to localStorage...
        this.onSubject.next({ key: key, value: null });
    }


    private start(): void {
        // console.log('starting storage listener');
        window.addEventListener('storage', this.storageEventListener.bind(this));
    }

    private storageEventListener(event: StorageEvent) {
        // console.log('checking storageEventListener');
        // tslint:disable-next-line:triple-equals
        const result1 = event.storageArea == localStorage;
        const result2 = event.storageArea === localStorage;
        // console.log(`storageEventListener result1=${result1}; result2=${result2}`, event);
        // tslint:disable-next-line:triple-equals
        if (event.storageArea == localStorage) {
            let v;
            try { v = JSON.parse(event.newValue); }
            catch (e) { console.log('error parsing event value', e); v = event.newValue; }
            this.onSubject.next({ key: event.key, value: v });
        }
    }

    private stop(): void {
        window.removeEventListener('storage', this.storageEventListener.bind(this));
        this.onSubject.complete();
    }
}
