import { SvFlightLegKey } from './sv-flight-leg';

export class SvTransferFlightsRequest {
    employeeId: number;
    startTime: number;
    toDesk: string;
    comment: string;
    flights: SvTransferFlight[];
    constructor(employeeId: number, startTime: number, toDesk: string, comment: string, flights: SvTransferFlight[]) {
        this.employeeId = employeeId;
        this.startTime = startTime;
        this.toDesk = toDesk;
        this.comment = comment;
        this.flights = flights;
    }
}
export class SvTransferFlight {
    depStation: string;
    flightNumber: number;
    originDate: number;
    dupDepNumber: number;
    arrStation: string;
    previousDesk: string;
    constructor(depStation: string, flightNumber: number, originDate: number, dupDepNumber: number, arrStation: string, previousDesk: string) {
        this.depStation = depStation;
        this.flightNumber = flightNumber;
        this.originDate = originDate;
        this.dupDepNumber = dupDepNumber;
        this.arrStation = arrStation;
        this.previousDesk = previousDesk;
    }
}

export class SvTransferStatus {
    flights: SvTransferHistoryFlight[];
}
export class SvTransferHistoryFlight {
    pk: TransferHistoryFlightPK;
    arrStation: string;
    previousDesk: string;
    transferStatus: boolean;
    transferStatusDetails: string;
    wxyuEntry: string;
    wxyuResponse: string;
    currentStatus: TransferStatus;
}
export class TransferHistoryFlightPK {
    transferHistoryId: number;
    uniqueFlightPK: SvFlightLegKey;
}
export enum TransferStatus {
    PENDING_TRANSFER = 'PENDING_TRANSFER',
    TRANSFERRED = 'TRANSFERRED',
    TRANSFER_ERROR = 'TRANSFER_ERROR',
    PENDING_RESTORE = 'PENDING_RESTORE',
    RESTORED = 'RESTORED',
    RESTORE_ERROR = 'RESTORE_ERROR',
    UNKNOWN = 'UNKNOWN'
}
export class SvTransferHistory {
    id: number;
    employeeId: number;
    datetime: string;
    destinationDesk: string;
    comment: string;
    action: string;
    flights: SvTransferHistoryFlight[];
    constructor(flights: SvTransferHistoryFlight[]) { this.flights = flights; }
}
export class SvRestoreFlightsRequest {
    transferHistoryId: number;
    employeeId: number;
    flights: SvTransferHistoryFlight[];
    constructor(transferHistoryId: number, employeeId: number, flights: SvTransferHistoryFlight[]) {
        this.transferHistoryId = transferHistoryId;
        this.employeeId = employeeId;
        this.flights = flights;
    }
}
