import { SvFlightLeg } from '../model/sv-flight-leg';
import { isNullOrUndefined } from 'util';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { SvDateStrPipe } from '../pipes/sv-date.pipe';
import { SvDialogService } from '../services/services-dialog/sv-dialog.service';
import { HttpErrorResponse } from '@angular/common/http';

export class SvTemperatureDialog {

    visible: boolean;
    station: string;
    header: string;
    temperature: string;
    loading = true;
    loadError: boolean;
    positionLeft: number;
    positionTop: number;

    constructor(private svDialogService: SvDialogService) { }

    showTemperature(station: string) {
        // TODO could enhance to only retrieve if:
        // 1. First station opened
        // 2. Different station
        // 3. Same station, but stale data (more than XX minutes old)
        this.visible = true;
        this.station = station;
        this.header = `RW ${station}`;
        this.loading = true;

        this.svDialogService.retrieveTemperature(station).subscribe(
            (data) => {
                console.log('temperature:', data);
                // set the temperature
                if (isNullOrUndefined(data) || isNullOrUndefined(data.value)) { this.loadError = true; }
                else { this.temperature = data.value; }
                this.loading = false;
            },

            (error: HttpErrorResponse) => {
                console.log('temperature error:', error);
                this.loading = false;
                this.loadError = true;
            }
        );
    }
}
