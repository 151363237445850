import { SvUserManagementService, User } from '../services/sv-user-management.service';
import { Message } from 'primeng/components/common/api';
// import { MessageService } from 'primeng/components/common/messageservice';
import { AuthGuardService } from '../guards/auth-guard.service';
import { isNullOrUndefined } from 'util';
export class SvUserManagementDialog {

  // Settings for Dialog Box
  visible: boolean;
  header: string;
  positionLeft: number;
  positionTop: number;

  // Fields for the user
  employeeId: any;
  selectedRole = '';
  //TRKR828a roleOptions = ['ADMIN', 'LEAD', 'USER'];
  roleOptions = ['ADMIN', 'LEAD', 'USER', 'SUPPORT']; //TRKR828a

  // Table variables
  currentUserManager: string;
  selectedRow;
  userList: User[] = [];

  // Modes for Text Fields
  addMode = true;
  editMode = false;
  deleteMode = false;
  submitButtonValue = 'Add User';

  // Variables for messaging service
  msgs: Message[] = [];
  // msgClass: string;

  constructor(private svUserManagementService: SvUserManagementService, private empId: number) {
    this.retrieveAllUsers();
  }

  /** Dialog manipulations */
  openUserMgmtDialog() {
    this.reset();
    this.retrieveAllUsers();
    this.visible = true;
    this.header = 'User Management';
    this.clearMsgs();
  }

  reset() {
    this.selectedRow = -1;
    this.employeeId = undefined;
    this.selectedRole = '';
    this.addMode = true;
    this.editMode = false;
    this.deleteMode = false;
    this.submitButtonValue = 'Add User';
  }

  selectedUser(index: number, user: User, mode: string) {
    this.selectedRow = index;
    this.employeeId = user.employeeId;
    this.selectedRole = user.userRole;

    this.addMode = false;
    if (mode === 'edit') {
      this.editMode = true;
      this.deleteMode = false;
      this.submitButtonValue = 'Update User';
    } else if (mode === 'delete') {
      this.deleteMode = true;
      this.editMode = false;
      this.submitButtonValue = 'Delete User';
    }
  }

  performAction() {
    if (this.addMode) { this.createNewUser(); }
    else if (this.editMode) { this.updateSelectedUser(); }
    else if (this.deleteMode) { this.deleteSelectedUser(); }
    else { this.showWarning('Invalid option selected'); }
  }

  isInvalidEmployeeId(): boolean {
    return isNullOrUndefined(this.employeeId) || isNaN(this.employeeId) || isNaN(parseInt(this.employeeId, 10))
      || isNullOrUndefined(this.selectedRole) || this.selectedRole.trim() === '' || this.selectedRole === 'Select role';
  }

  /** API Call Methods */
  private retrieveAllUsers() {
    // console.log('retrieving all users');
    this.svUserManagementService.retrieveUsers().subscribe(
      (value) => { this.userList = value; console.log('retrieved users'); },
      (error) => { console.log('error retrieving users', error); },
      // () => { console.log('retrieve complete'); }
    );
  }

  private createNewUser() {
    const userInfo = {
      employeeId: this.employeeId,
      userRole: this.selectedRole,
      modifiedUser: this.empId,
      // modifiedTimestamp: '',
    };
    this.svUserManagementService.createUser(userInfo).subscribe(
      (value) => {
        this.showSuccess(`Created user ${userInfo.employeeId}`);
        // this.msgClass = 'success';
        // set updated user list
        this.userList = value;
      },
      (error) => {
        this.showError(`Error creating user ${userInfo.employeeId}`);
        // this.msgClass = 'error';
      },
      // () => { console.log('create complete'); }
    );
  }

  private updateSelectedUser() {
    const userInfo = {
      employeeId: this.employeeId,
      userRole: this.selectedRole,
      modifiedUser: this.empId,
      // modifiedTimestamp: '',
    };
    this.svUserManagementService.updateUser(userInfo).subscribe(
      (value) => {
        // this.userList.splice(this.selectedRow, 1, value);
        this.showSuccess(`Changed user ${userInfo.employeeId} to ${userInfo.userRole}`);
        // this.msgClass = 'success';
        // set updated user list
        this.userList = value;
      },
      (error) => {
        this.showError(`Error updating user ${userInfo.employeeId}`);
        // this.msgClass = 'error';
      },
      // () => { console.log('update complete'); }
    );
  }

  private deleteSelectedUser() {
    //TRKR839
    const userInfo = {
      employeeId: this.employeeId,
      userRole: this.selectedRole,
      modifiedUser: this.empId,
    };
    this.svUserManagementService.deleteUser(this.employeeId).subscribe(
//  this.svUserManagementService.deleteUser(userInfo).subscribe( //TRKR839
      (value) => {
        // this.userList.splice(this.selectedRow, 1);
        this.reset();
  //TRKR839 this.showSuccess(`Deleted user ${this.employeeId}`);
        this.showSuccess(`Deleted user ${userInfo.employeeId}`); //TRKR839
  //      this.reset(); //TRKR839
        // this.msgClass = 'success';
        // set updated user list
        this.userList = value;
      },
      (error) => {
        this.showError(`Error deleting user ${this.employeeId}`);
        // this.msgClass = 'error';
      },
      // () => { console.log('delete complete'); }
    );
  }

  /** Methods used for messaging services. */
  private showSuccess(msg) {
    this.msgs = [{ severity: 'success', summary: 'Success', detail: msg }];
    // this.msgs.push({ severity: 'success', summary: 'Success', detail: msg });
  }

  private showWarning(msg) {
    this.msgs = [{ severity: 'warn', summary: 'Warning', detail: msg }];
    // this.msgs.push({ severity: 'error', summary: 'Error', detail: msg });
  }

  private showError(msg) {
    this.msgs = [{ severity: 'error', summary: 'Error', detail: msg }];
    // this.msgs.push({ severity: 'error', summary: 'Error', detail: msg });
  }

  private clearMsgs() {
    this.msgs = [];
    // this.msgClass = '';
  }
}


