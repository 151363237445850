import { Component, OnInit, OnDestroy } from '@angular/core';

@Component({
  selector: 'sv-content-desk-load',
  templateUrl: './sv-content-desk-load.component.html'
})
// export class SvContentDeskLoadComponent implements OnInit, OnDestroy {
export class SvContentDeskLoadComponent {

  constructor() { }

  // ngOnInit() {
  // console.log('ngOnInit');
  /** TODO start auto-update */
  // }

  // ngOnDestroy() {
  //   console.log('ngOnDestroy');
  /** TODO cancel auto-update */
  // }
}
