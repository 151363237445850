import { Component } from '@angular/core';

@Component({
  selector: 'sv-header-links',
  templateUrl: './sv-header-links.component.html'
})
export class SvHeaderLinksComponent {

  constructor() { }
}
