import { SvFlightLeg } from '../model/sv-flight-leg';
import { isNullOrUndefined } from 'util';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { SvDateStrPipe } from '../pipes/sv-date.pipe';

export class SvWbdcDialog {

    visible: boolean;
    keepOpen: boolean;
    flight: SvFlightLeg;
    header: string;
    flightCheck: string;
    positionLeft: number;
    positionTop: number;

    hideDialogSubject$: Subject<SvFlightLeg>;
    hideDialogSubscription: Subscription;

    constructor() {
        this.hideDialogSubject$ = new Subject<SvFlightLeg>();
        this.hideDialogSubscription = this.hideDialogSubject$.pipe(debounceTime(200))
            .subscribe((flight) => { this.hideFlight0(flight); });
    }

    showFlight(flight: SvFlightLeg, keepOpen: boolean) {
        // console.log(`showFlight  this.keepOpen=${this.keepOpen};keepOpen=${keepOpen};this.visible=${this.visible}`);
        // console.dir(flight);

        // open a new/override the current dialog if:
        // 1. this is a new dialog the user wants to keep open
        // 2. the current dialog is not expected to stay open
        // 3. the dialog that was expected to stay open has been closed
        if (keepOpen || !this.keepOpen || (this.keepOpen && !this.visible)) {
            this.visible = true;
            if (!isNullOrUndefined(flight)) {
                this.flight = flight;
                this.header = `WBDC ${flight.flightKey.flightNumber}/${flight.originDayStr} ${flight.flightKey.depStation}`;
                this.flightCheck = `${flight.flightKey.depStation}_${flight.flightKey.dupDepNumber}_` +
                    `${flight.flightKey.flightNumber}_${flight.flightKey.originDate}`;
                this.keepOpen = keepOpen;
            }
        }
    }

    hideFlight(flight: SvFlightLeg) {
        // console.log(`hideFlight this.keepOpen=${this.keepOpen}`);
        // console.dir(flight);
        if (!this.keepOpen) { this.hideDialogSubject$.next(flight); }
    }
    private hideFlight0(flight: SvFlightLeg) {
        const paramFlightCheck = `${flight.flightKey.depStation}_${flight.flightKey.dupDepNumber}_` +
            `${flight.flightKey.flightNumber}_${flight.flightKey.originDate}`;
        // TODO hide the dialog if this is the same flight
        if (this.flightCheck === paramFlightCheck) { this.visible = false; }
    }

    updateDetails(flight: SvFlightLeg) {
        // TODO complete this logic
        // this.flight = flight;
    }
}
