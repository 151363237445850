import { EventEmitter } from '@angular/core';
import { Options, LabelType } from 'ng5-slider';
import { isNullOrUndefined } from 'util';

export class SvDoubleSliderFlightNumber {

    defaultStart: number;
    minValue: number;
    defaultEnd: number;
    maxValue: number;

    startValue: number;
    startInput: number;
    endValue: number;
    endInput: number;
    flightOptions: Options;
    flightManualRefresh = new EventEmitter<void>();

    constructor(defaultStart: number, minValue: number, defaultEnd: number, maxValue: number) {
        this.defaultStart = defaultStart;
        this.minValue = minValue;
        this.defaultEnd = defaultEnd;
        this.maxValue = maxValue;

        this.startValue = this.defaultStart;
        this.startInput = this.defaultStart;
        this.endValue = this.defaultEnd;
        this.endInput = this.defaultEnd;
        this.flightOptions = {
            floor: this.minValue,
            ceil: this.maxValue,
            step: 1,
            noSwitching: true,
            translate: (value: number, label: LabelType): string => {
                switch (label) {
                    case LabelType.Low:
                        return '#' + value;
                    case LabelType.High:
                        return '#' + value;
                    // case LabelType.Floor:
                    //   return Math.abs(value) + 'min before ETD';
                    // case LabelType.Ceil:
                    //   return value + 'min after ETD';
                    default:
                        return '';
                }
            },
            // export declare type CombineLabelsFunction = (minLabel: string, maxLabel: string) => string;
            combineLabels: (minLabel: string, maxLabel: string): string => {
                return minLabel === maxLabel ? minLabel : `${minLabel} to ${maxLabel}`;
            }
        };
    }



    startInputModelChange() {
        // only update the slider values for this type of change
        if (!isNullOrUndefined(this.startInput) && this.startInput >= this.minValue && this.startInput <= this.endValue) {
            // update the slider value only if this number is valid
            this.startValue = this.startInput;
        }
    }
    startInputChange() {
        if (isNullOrUndefined(this.startInput)) { this.startInput = Math.min(this.defaultStart, this.endValue); }
        else if (this.startInput < this.minValue) { this.startInput = this.minValue; }
        else if (this.startInput > this.endValue) { this.startInput = this.endValue; }
        // set the slider value
        this.startValue = this.startInput;
    }
    endInputModelChange() {
        // only update the slider values for this type of change
        if (!isNullOrUndefined(this.endInput) && this.endInput <= this.maxValue && this.endInput >= this.startValue) {
            // update the slider value only if this number is valid
            this.endValue = this.endInput;
        }
    }
    endInputChange() {
        if (isNullOrUndefined(this.endInput)) { this.endInput = Math.max(this.defaultEnd, this.startValue); }
        else if (this.endInput < this.startValue) { this.endInput = this.startValue; }
        else if (this.endInput > this.maxValue) { this.endInput = this.maxValue; }
        // set the slider value
        this.endValue = this.endInput;
    }
    sliderChange() {
        this.startInput = this.startValue;
        this.endInput = this.endValue;
    }
}
