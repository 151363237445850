import { Component, OnInit, OnDestroy, AfterViewInit, ViewChild } from '@angular/core';
import { Observable, Subject, Subscription, fromEvent } from 'rxjs';
import { debounceTime, retry } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { SvPageRequest } from 'src/app/model/sv-models';
import { SvFlightCargoStatus, SvFlightLockType, SvFlightPageData, SvFlightLeg, SvFlightLegKey } from 'src/app/model/sv-flight-leg';
import { SvRouteService } from 'src/app/services/sv-route.service';
import { SvFlightLegService } from 'src/app/services-ws/sv-flight-leg.service';
import { DatatableComponent, DataTablePagerComponent } from 'src/datatable';
import { SvRouteParser, ROUTE_CONSTANTS } from 'src/app/helper/sv-route-parser';
import { SvSidebarToggleService } from 'src/app/services/sv-sidebar-toggle.service';
import { SvTimeZoneService } from 'src/app/services-ws/sv-timezone.service';
import { SvWbdcDialog } from 'src/app/dialog/sv-fos-wbdc-dialog';
import { isNullOrUndefined } from 'util';
import { SvTemperatureDialog } from 'src/app/dialog/sv-fos-temp-dialog';
import { SvDialogService } from 'src/app/services/services-dialog/sv-dialog.service';
import { RoleGuardService } from 'src/app/guards/role-guard.service';
import { SvUserInfo, SvUserRole } from 'src/app/model/sv-user-info';
import { SvOpenTransferFlightService } from 'src/app/services/sv-open-transfer-flight.service';
import { SvTransferFlightDialog } from 'src/app/dialog/sv-transfer-flight-dialog';
import { SvTransferFlightHistoryDialog } from 'src/app/dialog/sv-transfer-flight-history-dialog';
import { SvOpenTransferHistoryService } from 'src/app/services/sv-open-transfer-history.service';
import { MessageService } from 'primeng/api';
import { SvReminderService } from 'src/app/components/sv-reminder/sv-reminder.services';
import { SvUtilities } from 'src/app/helper/sv-utilities';
import * as _ from 'lodash';
import { SvSupervisorNoteDialog } from 'src/app/dialog/sv-supervisor-note-dialog';
import { SvUserManagementDialog } from 'src/app/dialog/sv-user-management-dialog';
import { SvUserManagementService } from 'src/app/services/sv-user-management.service';
import { DomSanitizer } from '@angular/platform-browser';


const minPageRows = 30;
// const minPageRows = 5;
// TODO change back to showing more pages when scrolling has been improved
const minPageBack = 3,
  minPageForward = 3;
// const minPageBack = 1, minPageForward = 1;
const defaultMessage = 'No data for this criteria';
const errorTimeMilliseconds = 2 * 60 * 1000;

const TRANSFER_LIMIT = 100;

@Component({
  selector: 'sv-content-flight-view',
  templateUrl: './sv-content-flight-view.component.html',
  providers: [MessageService]
})
export class SvContentFlightViewComponent
  implements OnInit, OnDestroy, AfterViewInit {
  /* **** VARIABLES TO DEFINE WHAT THIS USER HAS ACCESS TO VIEW **** */
  private userHasPopupAccess = this.roleGuardService.hasAccessSingle(
    SvUserRole.LEAD
  );
  userHasNoteAcess = this.roleGuardService.hasAccessSingle(SvUserRole.LEAD);

  windowOpened: Date;
  loading: boolean;
  private loadingTime: Date;
  lastUpdateTime: Date;
  lastUpdateTimeClass: string;

  // variable to manage new filter criteria
  private tableFilterActivationEndSubscription: Subscription;

  // variables to initialy retrieve table data
  private tableInitFilterSubject$: Subject<Date>;
  private tableInitFilterSubscription: Subscription;
  private lastInitFilterUpdateStartTime: Date;
  private lastInitFilterUpdateEndTime: Date;

  // variables to manage on-demand table updates
  private tableOnDemandSubject$: Subject<Date>;
  private tableOnDemandSubscription: Subscription;
  private lastOnDemandUpdateStartTime: Date;
  private lastOnDemandUpdateEndTime: Date;

  // variables to manage automatic updates
  private tableAutoUpdateInterval: any;
  private tableAutoUpdateSubject$: Subject<Date>;
  private tableAutoUpdateSubscription: Subscription;
  private lastAutoUpdateStartTime: Date;
  private lastAutoUpdateEndTime: Date;

  // variables for the resize event
  private tableResizeEvent$: Observable<Event>;
  private tableResizeSubscription: Subscription;

  private sidebarToggledSubscription: Subscription;
  private sidebarTogglingSubscription: Subscription;

  private openTransferDialogSubscription: Subscription;
  private openTransferHistoryDialogSubscription: Subscription;

  pageRequest: SvPageRequest;
  pagedData: SvFlightPageData;
  tableMessages = { emptyMessage: defaultMessage };

  // variables to track row selection
  selectedFlights: SvFlightLeg[] = [];
  private internalSelectedFlight = [];

  // set the default sort order
  columnSort = [
    {
      prop: ROUTE_CONSTANTS.defaultSortProperty,
      dir: ROUTE_CONSTANTS.defaultSortDirection
    }
  ];

  // TODO is it possible to go directly to a page?

  // gain access to the ngx table
  @ViewChild('supervisorViewTableMain') supervisorTableMain: DatatableComponent;
  @ViewChild('supervisorViewTablePager') supervisorTablePager: DataTablePagerComponent;

  /** NEED TO DETERMINE EXACTLY WHAT WILL BE DISPLAYED IN EACH COLUMN AND HOW THAT IS GOING TO BE SETUP */
  // If column contains multiple elements, each element should be aligned with every row. For example, the flight column might look like this:
  // "1111m"
  // "  10 "
  // --------------------------------------------------------------------------------
  // 2.  Icons
  //     a. display passenger icon if passengers have been finalized
  //     b. display black briefcase if cargo has been finalized
  //                green breifcase if DRP contains the phrase 'FINAL'
  //     c. display fuel released
  // 8.  Phase
  //     a. cross-loraine symbol if auto-release has been disabled
  //     b. character displaying how the DRP animal/restriction is setup
  //        (1) 'x' if (animal AND restricted)
  //        (2) 'a' if (animal)
  //        (3) 'r' if (restricted)
  //        (4) ' ' if none
  //     c. phase
  //     d. circle if some of the ALP locks are set; dot if all of the ALP locks are set
  
  //TRKR825a - Added fuelInfo and wbiFuelEnvCargoInfo
  
  userColumns = [];
  availableColumns = [];
  allNgxColumns = [
    { name: 'SF', userAccess: true, property: 'supervisorNoteIcon', width: '40', resizeable: false, sortable: false }, //TRKR851
    { name: 'DESK', userAccess: true, property: 'loadDesk', width: '57', resizeable: false, sortable: true, openFlightWindow: true },
    { name: 'IND', userAccess: true, property: 'icons', width: '52', resizeable: false, sortable: false }, //TRKR851
    { name: 'FLT', userAccess: true, property: 'flightMelInfo', width: '59', resizeable: false, sortable: true, commonClass: 'align-right' },
    { name: 'DEP', userAccess: true, property: 'flightKey.depStation', width: '47', resizeable: false, sortable: true, openFlightWindow: true },
    { name: 'ARV', userAccess: true, property: 'arrStation', width: '47', resizeable: false, sortable: true, openFlightWindow: true },
    { name: 'SKD', userAccess: true, property: 'schedDepTime', width: '57', resizeable: false, sortable: true, openFlightWindow: true },
    { name: 'ETD', userAccess: true, property: 'latestDepTime', width: '57', resizeable: false, sortable: true, openFlightWindow: true },
    { name: 'PHASE', userAccess: true, property: 'phaseStatus', width: '77', resizeable: false, sortable: true, commonClass: 'align-left' },
    { name: 'EQP', userAccess: true, property: 'equipInfo', width: '55', resizeable: false, sortable: true },
    { name: 'RNWY fc', userAccess: true, property: 'runwayFieldCondition', width: '87', resizeable: false, sortable: true, commonClass: 'align-right' },
    { name: 'TEMP', userAccess: true, property: 'temperatureInfo', width: '57', resizeable: false, sortable: true, commonClass: 'align-right' },
    { name: 'PSGRS', userAccess: true, property: 'passengerInfo', width: '67', resizeable: false, sortable: true, commonClass: 'align-right' },
    { name: 'FUEL', userAccess: true, property: 'fuelInfo', width: '79', resizeable: false, sortable: true, commonClass: 'align-right' }, // TRKR872 - increased width from 67 to 79 
    { name: 'CARGO', userAccess: true, property: 'cargoInfo', width: '67', resizeable: false, sortable: true, commonClass: 'align-right' },
    { name: 'WBI', userAccess: true, property: 'wbiFuelEnvCargoInfo', width: '108', resizeable: false, sortable: true, commonClass: 'align-center' }, 
    { name: 'SPR', userAccess: true, property: 'spreadInfo', width: '72', resizeable: false, sortable: true, commonClass: 'align-right' },
    { name: 'MTOW', userAccess: true, property: 'mtowInfo', width: '79', resizeable: false, sortable: true, commonClass: 'align-right' },
    { name: 'TAIL', userAccess: true, property: 'equipTail', width: '57', resizeable: false, sortable: true },
    { name: 'F-A', userAccess: true, property: 'fma', width: '47', resizeable: false, sortable: true },
    { name: 'ALERT', userAccess: true, property: 'alert', width: '85', resizeable: false, sortable: true, commonClass: 'align-left' },
    { name: 'USER NOTES', userAccess: this.userHasNoteAcess, hideShow: true, property: 'userNote', width: '300', resizeable: true, sortable: true, commonClass: 'align-left' },
    { name: 'SV NOTES', userAccess: this.userHasNoteAcess, hideShow: true, property: 'supervisorNote', width: '300', resizeable: true, sortable: true, commonClass: 'align-left' }
  ];

  /* **** VARIABLE FOR THE FLIGHT DETAILS DIALOG **** */
  wbdcDialog = new SvWbdcDialog();
  temperatureDialog = new SvTemperatureDialog(this.svDialogService);
  // private transferDialog: SvTransferFlightDialog;
  transferFlightDialogs: SvTransferFlightDialog[] = [];
  // transferFlightHistoryDialogs: SvTransferFlightHistoryDialog[] = [];
  transferFlightHistoryDialog = new SvTransferFlightHistoryDialog(this.svDialogService, this.svTimeZoneService,
    this.roleGuardService.authGuardService, this.messageService);
  // baconTapsilogWithRice = [];
  transferCount = 1;
  supervisorNoteDialog = new SvSupervisorNoteDialog(this.svDialogService, this.roleGuardService.authGuardService.svUserInfo.empId);
  userMgtDialog = new SvUserManagementDialog(this.svUserManagementService, this.roleGuardService.authGuardService.svUserInfo.empId);
  openUserManagementDialogSubscription: Subscription;
  employeeId: number;

  constructor(
    private sanitizer: DomSanitizer, //DXC-Coverity
    private router: Router, private svRouteService: SvRouteService, private svFlightLegService: SvFlightLegService,
    private svSidebarToggleService: SvSidebarToggleService, public svTimeZoneService: SvTimeZoneService,
    private svDialogService: SvDialogService, private svOpenTransferFlightService: SvOpenTransferFlightService,
    private svOpenTransferHistoryService: SvOpenTransferHistoryService, private roleGuardService: RoleGuardService,
    private messageService: MessageService, private reminderService: SvReminderService,
    private svUserManagementService: SvUserManagementService) {
    this.loading = true;
    this.resetFilterUpdate();
    this.availableColumns = this.allNgxColumns.filter(column => column.userAccess);
    this.userColumns = [...this.availableColumns];
  }

  ngOnInit() {
    this.windowOpened = new Date();
    // console.log('ngOnInit');
    // be notified of changes to the URL and reset the filter criteria
    this.resetFilterUpdate();

    // initialize variables for manage page updates
    this.tableInitFilterSubject$ = new Subject();
    this.tableInitFilterSubscription = this.tableInitFilterSubject$
      .pipe(retry(2))
      .subscribe(() => { this.manageInitFilterUpdate(new Date()); });

    // initialize variables for manage page updates
    this.tableOnDemandSubject$ = new Subject();
    this.tableOnDemandSubscription = this.tableOnDemandSubject$
      .pipe(debounceTime(400), retry(2))
      .subscribe(() => { this.manageOnDemandUpdate(new Date()); });

    // initialize variables to auto-update the table
    this.tableAutoUpdateSubject$ = new Subject();
    this.tableAutoUpdateSubscription = this.tableAutoUpdateSubject$
      .pipe(retry(2))
      .subscribe(() => { this.manageAutoUpdate(new Date()); });

    // initialize the resize event
    this.tableResizeEvent$ = fromEvent(window, 'resize');
    this.tableResizeSubscription = this.tableResizeEvent$
      .pipe(debounceTime(200))
      .subscribe(event => { this.onResize(event); });
  }
  ngAfterViewInit() {
    // console.log('ngAfterViewInit');
    // if the table is empty and the sidbar is minimized, then the header will not span the width of the screen;
    // therefore, we need to manually update the table
    const toggleDebounceTime = 350;
    this.sidebarToggledSubscription = this.svSidebarToggleService.sidebarToggled$
      .pipe(debounceTime(toggleDebounceTime))
      .subscribe(() => { this.recalculateTableWidth(); });
    this.sidebarTogglingSubscription = this.svSidebarToggleService.sidebarToggling$
      .pipe(debounceTime(toggleDebounceTime))
      .subscribe(() => { this.recalculateTableWidth(); });

    // listener for open modal action on desk transfer
    this.openTransferDialogSubscription = this.svOpenTransferFlightService.openedTransferDialog$.subscribe(
      () => { this.openTransferFlightDialog(); }
    );

    // listener for open modal action on desk transfer history
    this.openTransferHistoryDialogSubscription = this.svOpenTransferHistoryService.openedTransferHistoryDialog$.subscribe(
      () => { this.openTransferHistoryDialog(); }
    );

    // listener for open modal action for user management
    this.openUserManagementDialogSubscription = this.svUserManagementService.openedUserManagement$.subscribe(
      () => { this.openUserMangamentDialog(); }
    );

    // check if any reminders have been dismissed/removed
    this.reminderService.reminderRemoved$.subscribe(flightKey => { this.updateReminderStatus(flightKey, true); });
    // check if any reminders have been modified
    this.reminderService.reminderAdded$.subscribe(flightKey => { this.updateReminderStatus(flightKey, true); });
  }

  private openUserMangamentDialog() {
    this.userMgtDialog.openUserMgmtDialog();
  }

  private updateReminderStatus(flightKey: SvFlightLegKey, updateStatus: boolean) {
    // find the  the table
    if (
      !isNullOrUndefined(this.pagedData) &&
      !isNullOrUndefined(this.pagedData.pageResults)
    ) {
      for (const flight of this.pagedData.pageResults) {
        if (_.isEqual(flight.flightKey, flightKey)) {
          flight.hasReminder = updateStatus;
          break;
        }
      }
    }
  }
  private recalculateTableWidth() {
    // console.log('recalculateTableWidth (tableWidth)' + this.supervisorTableMain._innerWidth);
    // only update if needed
    const oldWidth = this.supervisorTableMain._innerWidth;
    this.supervisorTableMain.recalculate();
    const newWidth = this.supervisorTableMain._innerWidth;
    if (oldWidth !== newWidth) {
      this.pagedData.pageResults = [...this.pagedData.pageResults];
      this.svSidebarToggleService.togglingSidebar();
    }
  }
  private resetFilterUpdate() {
    // console.log(`resetFilterUpdate - ${this.tableFilterActivationEndSubscription}`);
    // only setup if it has not been already
    // this is only done so the activation can occur from the constructor and ngOnInit after the subscription is reset in ngOnDestroy
    if (!this.tableFilterActivationEndSubscription) {
      this.tableFilterActivationEndSubscription = this.svRouteService.activationEnded$.subscribe(
        snapshot => {
          // console.log('activation end subscription');
          // console.dir(this.supervisorTableMain);
          // console.dir(this.pageRequest);
          // console.dir(this.pagedData);

          const svRouteData = SvRouteParser.parseRoute(snapshot);
          // update the sort order to match the URL information
          this.columnSort = [
            { prop: svRouteData.sortProperty, dir: svRouteData.sortDirection }
          ];

          // set fields to retrieve data for the first page and reset all the table data
          this.pageRequest = new SvPageRequest(minPageBack, minPageForward);
          // TRKR872 this.pagedData = new SvFlightPageData(0, 0, []);
          this.pagedData = new SvFlightPageData(0, 0, 0, []); // TRKR872

          if (!isNullOrUndefined(this.supervisorTableMain)) {
            this.onPage({
              count: this.supervisorTableMain.count,
              lastMsgUpdt: this.supervisorTableMain.lastMsgUpdt, // TRKR872
              pageSize: this.supervisorTableMain.pageSize,
              limit: undefined,
              offset: this.supervisorTableMain.offset
            });
          }
        }
      );
    }
  }
  ngOnDestroy() {
    // console.log('ngOnDestroy');
    this.clearAutoUpdateInterval();
    this.tableAutoUpdateSubscription.unsubscribe();
    this.tableAutoUpdateSubscription = null;
    clearInterval(this.tableAutoUpdateInterval);

    // cancel filter subscriptions
    this.tableFilterActivationEndSubscription.unsubscribe();
    this.tableFilterActivationEndSubscription = null;

    // cancel all updates
    this.tableInitFilterSubscription.unsubscribe();
    this.tableInitFilterSubscription = null;
    this.tableInitFilterSubject$ = null;

    this.tableOnDemandSubscription.unsubscribe();
    this.tableOnDemandSubscription = null;
    this.tableOnDemandSubject$ = null;

    this.tableResizeSubscription.unsubscribe();
    this.tableResizeSubscription = null;
    this.tableResizeEvent$ = null;

    // TODO remove all data
    // this.tablePageSubject = new Subject();
    // this.tablePageObservable = this.tablePageSubject.pipe(debounceTime(400), retry(2));

    // unset all variables possible to save memory and help processing
    this.pageRequest = null;
    this.pagedData = null;

    //  remove the sidebar toggle subscriptions
    this.sidebarToggledSubscription.unsubscribe();
    this.sidebarToggledSubscription = null;
    this.sidebarTogglingSubscription.unsubscribe();
    this.sidebarTogglingSubscription = null;
    this.openTransferDialogSubscription.unsubscribe();
    this.openTransferDialogSubscription = null;
    this.openTransferHistoryDialogSubscription.unsubscribe();
    this.openTransferHistoryDialogSubscription = null;
  }

  /**
   * Populate the table with new data based on the page number
   */
  onPage(event) {
    // console.log(`onPage offset=${event.offset}; pageSize=${event.pageSize}; `
    // + `pageRowCount=${this.pageRequest.pageRowCount}; pageNumber=${this.pageRequest.pageNumber}`);
    // console.dir(this.pageRequest);
    // console.dir(this.pagedData);

    let updateTable: Subject<Date>;
    if (!this.pageRequest.pageRowCount) {
      // this is the first time this table is being retrieved since these values are reset after every filter
      updateTable = this.tableInitFilterSubject$;
      // console.log('init filter');
    } else {
      // TODO need to make sure data should be displayed before refreshing the page

      // get new data if the start or end index of this page does not contain any data
      // there will not be any skipped indexes; therefore, checking the start and end index is all that is needed
      const tableBodyComponent = this.supervisorTableMain.bodyComponent;
      // ensure every displayed row contains data
      for (
        let index = tableBodyComponent.indexes.first;
        index <= tableBodyComponent.indexes.last;
        index++
      ) {
        if (isNullOrUndefined(this.pagedData.pageResults[index])) {
          // need to retrieve updated data
          updateTable = this.tableOnDemandSubject$;
          break;
        }
      }
    }

    // check if the table needs to be updated
    if (updateTable) {
      // update the page request
      this.pageRequest.pageNumber = event.offset;
      this.pageRequest.pageRowCount = event.pageSize;
      // update the table
      updateTable.next(new Date());
      // console.log(`updateTable pageRowCount=${this.pageRequest.pageRowCount}; pageNumber=${this.pageRequest.pageNumber}`);
    }
  }
  onScroll(event) {
    this.loading = true;
    // this.rowHeightCache.tableOffsetY = event.offsetY;
  }
  onResize(event) {
    // console.log('onResize');
    // console.dir(event);
    // this call will update the current offset to the correct value when the window is resized
    this.supervisorTableMain.bodyComponent.updatePage('up');
    this.onPage({
      count: this.supervisorTableMain.count,
      lastMsgUpdt: this.supervisorTableMain.lastMsgUpdt, // TRKR872
      pageSize: this.supervisorTableMain.pageSize,
      limit: undefined,
      offset: this.supervisorTableMain.offset
    });
  }
  onSort(event) {
    const svRouteData = SvRouteParser.parseRoute(
      this.svRouteService.activationEndSnapshot
    );
    // console.log('onSort', svRouteData);
    // console.dir(event);
    // update the sort information
    svRouteData.sortColumn = event.column.name;
    svRouteData.sortProperty = event.column.prop;
    svRouteData.sortDirection = event.newValue;

//DXC-Coverity const routeStr = SvRouteParser.createUrl(svRouteData, this.router);
    const routeStr = SvRouteParser.createUrl(svRouteData, this.router, this.sanitizer); //DXC-Coverity
    // const routeStr = this.router.createUrlTree(SvRouteParser.builNavigationCommand(svRouteData)).toString();
    this.router.navigateByUrl(routeStr);
  }
  onActivate(event) {
    // console.log('onActivate', event);
    // if (event.type === 'dblclick') {
    //   // this.selectedFlights
    //   // _.pull(reminders, value);
    // }
  }
  onSelect(event) {
    // console.log('onSelect', event);
    // console.dir(event);
    // console.dir(this.selectedFlights);
    // if (event.selected && event.selected[0]) {
    //   // if()
    //   const flightsEqual = this.internalSelectedFlight[0] === event.selected[0];
    //   console.log(flightsEqual);
    //   if (flightsEqual) { this.selectedFlight = []; }
    //   this.internalSelectedFlight = [...this.selectedFlight];
    //   //     this.getRowIdentity(event.selected[0]);
    //   //   }
    // }
    // TODO disable selection for the first column
    // TODO undo selection when double clicking
    // this.selectedFlights = [];
  }
  // onTableContextMenu(event) {
  //   console.log('onTableContextMenu');
  //   console.dir(event);
  //   //   console.dir(event.event);

  //   // this.rawEvent = event.event;
  //   if (event.type === 'body') {
  //     // this.contextMenuFlight = event.content;
  //     //   this.contextmenuColumn = undefined;
  //     // } else {
  //     //   this.contextmenuColumn = event.content;
  //     //   this.contextmenuRow = undefined;
  //   }

  //   // const mouseEvent: MouseEvent = event.event;
  //   // this.contextMenu.toggle(mouseEvent);
  //   // mouseEvent.x = mouseEvent.screenX;
  //   // mouseEvent.y = mouseEvent.screenY;

  //   // let testEvent: MouseEvent;
  //   // const testEvent = {
  //   //   x: mouseEvent.screenX,
  //   //   y: mouseEvent.screenY,
  //   // };
  //   // this.contextMenu.toggle(testEvent);
  //   // this.contextMenu.toggle(mouseEvent);

  //   event.event.preventDefault();
  //   event.event.stopPropagation();
  // }

  goToPage(event) {
    // console.dir(event);
    // this.supervisorTablePager.selectPage(3);
  }

  getRowIdentity = (row: SvFlightLeg) => {
    return SvUtilities.getUniqueKeyStr(row.flightKey);
  }
  getRowClass(row: SvFlightLeg) {
    return row.rowFontColor + ' '
      + (!isNullOrUndefined(row.rowStyle) ? (row.rowStyle + ' ') : '')
      + (row.rowSeparator ? 'flightSeparator' : '');
  }
  validCargoImage(svFlightCargoStatus: SvFlightCargoStatus): boolean {
    return (
      svFlightCargoStatus === SvFlightCargoStatus.BOTH || // TRKR872
      svFlightCargoStatus === SvFlightCargoStatus.DRP ||
      svFlightCargoStatus === SvFlightCargoStatus.FINALIZED
    );
  }
  getCargoTooltip(svFlightCargoStatus: SvFlightCargoStatus): string {
    if (svFlightCargoStatus === SvFlightCargoStatus.DRP) {
      return 'DRP finalized';
    }
    if (svFlightCargoStatus === SvFlightCargoStatus.FINALIZED) {
      return 'cargo finalized';
    }
    // TRKR872 - Indicate both as finalized
    if (svFlightCargoStatus === SvFlightCargoStatus.BOTH) {
      return 'WBP and DRP finalized';
    }
  }
  imgTooltipDelay(): number {
    return 800;
  }
  flightCellStyle = (flight: SvFlightLeg) => {
    if (!isNullOrUndefined(flight.rowStyleColor)) {
      return { color: `#${flight.rowStyleColor}` };
    }
  }
  flightCellStyleLockType = (flight: SvFlightLeg) => {
    if (!isNullOrUndefined(flight.rowStyleColor)) {
      if (flight.lockType === SvFlightLockType.ALL) {
        return {
          color: `#${flight.rowStyleColor}`,
          border: `2px solid #${flight.rowStyleColor}`,
          background: `#${flight.rowStyleColor}`
        };
      }
      if (flight.lockType === SvFlightLockType.SOME) {
        return {
          color: `#${flight.rowStyleColor}`,
          border: `2px solid #${flight.rowStyleColor}`
        };
      }

      return { color: `#${flight.rowStyleColor}` };
    }
  }

  private manageInitFilterUpdate(dataKey: Date) {
    // console.log(`manageInitFilterUpdate - ${dataKey}`);
    // console.dir(this.pageRequest);
    // console.dir(this.pagedData);
    this.lastInitFilterUpdateStartTime = dataKey;
    // stop auto-updates
    this.clearAutoUpdateInterval();
    this.manageUpdate(true, this.callbackInitFilterUpdate, dataKey, 0);

    // change to the first page
    this.supervisorTableMain.offset = 0;
    // set the scroll bar to the top
    this.supervisorTableMain.bodyComponent.offsetY = 0;
  }
  private manageOnDemandUpdate(dataKey: Date) {
    // console.log(`manageOnDemandUpdate - ${dataKey}`);
    this.lastOnDemandUpdateStartTime = dataKey;
    // stop auto-updates
    this.clearAutoUpdateInterval();
    this.manageUpdate(true, this.callbackOnDemandUpdate, dataKey);
  }
  private manageAutoUpdate(dataKey: Date) {
    // console.log(`manageAutoUpdate - ${dataKey}`);
    // TODO only process if filter or on demand have not executed within last 20 seconds

    this.lastAutoUpdateStartTime = dataKey;
    // do not display loading bar for auto-updates
    this.manageUpdate(false, this.callbackAutoUpdate, dataKey);
  }
  private manageUpdate(displayLoading: boolean, callbackData: (flightData: SvFlightPageData, dataKey: Date) => any, dataKey: Date, startIndex?: number) {
    // set variables and then retrieve flights
    if (displayLoading) {
      this.loading = true;
    }
    this.loadingTime = dataKey;

    // get the start of the current page
    const currPageStartIndex = !isNullOrUndefined(startIndex)
      ? startIndex
      : this.supervisorTableMain.bodyComponent.indexes.first;
    const retrieveRowCount = Math.max(
      this.pageRequest.pageRowCount,
      minPageRows
    );
    let flightStartIndex =
      currPageStartIndex - this.pageRequest.pageBack * retrieveRowCount;
    if (flightStartIndex < 0) {
      flightStartIndex = 0;
    }
    // current page plus pages back and pages forward
    const flightMaxRows =
      (1 + this.pageRequest.pageBack + this.pageRequest.pageForward) *
      retrieveRowCount;

    // TODO this process has not been completed yet
    this.svFlightLegService.retrieveFlights(
      SvRouteParser.buildFlightHttpParams(
        this.svRouteService.activationEndSnapshot,
        flightStartIndex,
        flightMaxRows,
        this.svTimeZoneService.localTimeZone
      ),
      callbackData,
      this.callbackSuccess,
      this.callbackFailed,
      this.callbackComplete,
      dataKey
    );
  }

  /** ONLY CONTINUE THE CALLBACKS IF THE PRIORITIES ARE CORRECT (e.g. do not continue on demand or auto if the init call back has been set) */

  private callbackInitFilterUpdate = (flightData: SvFlightPageData, dataKey: Date, error?: HttpErrorResponse): boolean => {
    // this is valid if it is the latest filter update
    const valid = this.lastInitFilterUpdateStartTime === dataKey;
    if (valid) {
      // only execute the update methods if this is not an error
      if (!error) {
        // reset the selected flights
        this.selectedFlights = [];
        this.callbackUpdate(flightData, dataKey);
      }
      this.lastInitFilterUpdateEndTime = new Date();
      // still process the auto-updates even if an error occurred
      this.setAutoUpdateInterval();
    }

    // return whether it is valid to continue with the processing
    return valid;
  }
  private callbackOnDemandUpdate = (flightData: SvFlightPageData, dataKey: Date, error?: HttpErrorResponse): boolean => {
    // this is valid if it is the latest on-demand update AND a filter update has not started since this update
    // a filter update will have occurred; therefore, do not need to check if it even exists
    const valid =
      this.lastOnDemandUpdateStartTime === dataKey &&
      dataKey > this.lastInitFilterUpdateStartTime;
    if (valid) {
      // only execute the update methods if this is not an error
      if (!error) {
        this.callbackUpdate(flightData, dataKey);
      }
      this.lastOnDemandUpdateEndTime = new Date();
      // still process the auto-updates even if an error occurred
      this.setAutoUpdateInterval();
    }

    // return whether it is valid to continue with the processing
    return valid;
  }
  private callbackAutoUpdate = (flightData: SvFlightPageData, dataKey: Date, error?: HttpErrorResponse): boolean => {
    // this is valid if it is the latest auto-update AND if a filter or on-demand update have not started since this update
    const valid =
      this.lastAutoUpdateStartTime === dataKey &&
      // a filter update will have occurred; therefore, do not need to check if it even exists
      dataKey > this.lastInitFilterUpdateStartTime &&
      // an on-demand update might not have occurred; therefore, check if it even exists
      (!this.lastOnDemandUpdateStartTime ||
        dataKey > this.lastOnDemandUpdateStartTime);
    if (valid) {
      // only execute the update methods if this is not an error
      if (!error) {
        this.callbackUpdate(flightData, dataKey);
      }
      // still process the auto-updates even if an error occurred
      this.lastAutoUpdateEndTime = new Date();
    }

    // return whether it is valid to continue with the processing
    return valid;
  }
  private callbackUpdate = (flightData: SvFlightPageData, dataKey: Date): void => {
    // console.log(flightData);
    // console.log(`callbackUpdate startIndex=${flightData.startIndex} pageResults.length=${flightData.pageResults.length}`);
    // console.dir(this.pageRequest);
    // console.dir(this.pagedData);
    
    // TRKR872
    // console.log('**************************** LastMsgUpdt: ' + flightData.lastMsgUpdt);
    //if(flightData.lastMsgUpdt > 0){
      // this.displayMsgWarning('ALPSV is not getting messages'); //TRKR872
    //}

    const rowData: SvFlightLeg[] = [];
    const startIndex = flightData.startIndex;
    // let updateFlight;
    if (flightData.totalRowCount > 0) {
      for (let i = startIndex; i < startIndex + flightData.pageResults.length; i++) {
        const flight = flightData.pageResults[i - startIndex];
        rowData[i] = flight;
        rowData[i].hasReminder = this.reminderService.reminderSetForFlight(
          flight.flightKey
        );
        // TODO check if the display is for one of these flights
        // if (false) { updateFlight = rowData[i]; }
      }
    }

    this.pagedData.totalRowCount = flightData.totalRowCount;
    this.pagedData.lastMsgUpdt = flightData.lastMsgUpdt; // TRKR872
    this.pagedData.startIndex = startIndex;
    this.pagedData.pageResults = [...rowData];
    // console.dir('this.pagedData.pageResults');
    // console.dir(this.pagedData.pageResults);
    // this.svFlightTableHelper.updateFlights(this.pagedData);
    this.lastUpdateTime = dataKey;
    // if (!isNullOrUndefined(updateFlight)) { this.updateDetails(updateFlight); }
    // console.dir('*********************** lastUpdt:' + flightData.lastMsgUpdt);
    // console.log('*********************** rowCount:' + flightData.totalRowCount);
  }
  private setAutoUpdateInterval() {
    // update the table every 20 seconds
    this.tableAutoUpdateInterval = setInterval(() => {
      this.tableAutoUpdateSubject$.next();
    }, 20000);
  }
  private clearAutoUpdateInterval() {
    clearInterval(this.tableAutoUpdateInterval);
  }

  /** these probably need to be called from the data callback methods or a boolean needs to be returned from the data callback */
  private callbackSuccess = (dataKey: Date): void => {
    this.tableMessages.emptyMessage = defaultMessage;
    this.lastUpdateTimeClass = '';
  }
  private callbackFailed = (error: HttpErrorResponse, dataKey: Date): void => {
    // console.dir(error);

    // this error will only be displayed if the table is empty
    this.tableMessages.emptyMessage = `<span class='table-error-message'>Error retrieving data</span>`;
    // TODO display error if success has not occurred within XX minutes

    if (
      !this.lastUpdateTime ||
      this.lastUpdateTime.getTime() < Date.now() - errorTimeMilliseconds
    ) {
      this.lastUpdateTimeClass = 'error';
    }
  }
  private callbackComplete = (dataKey: Date): void => {
    // console.log(`callback complete ${this.lastUpdateTime} ;;;  ${this.lastUpdateTimeStr}`);
    if (dataKey === this.loadingTime) {
      this.loading = false;
    }
  }

  // TODO
  /* ***** MANAGE WHAT COLUMNS THER USERS CAN VIEW ***** */
  // toggle(col) {
  //   const isChecked = this.isChecked(col);

  //   if (isChecked) {
  //     this.columns = this.columns.filter(c => {
  //       return c.name !== col.name;
  //     });
  //   } else {
  //     this.columns = [...this.columns, col];
  //   }
  // }

  // isChecked(col) {
  //   return (
  //     this.columns.find(c => {
  //       return c.name === col.name;
  //     }) !== undefined
  //   );
  // }

  /* **** DETAILS ABOUT THE FLIGHT DIALOG ***** */
  openReminderDialog(flight: SvFlightLeg) {
    this.reminderService.openReminderDialog(flight);
  }

  // private updateDetails(flight: SvFlightLeg) {
  //   this.fosDetailsDialog.updateDetails(flight);
  // }
  showWbdc(flight: SvFlightLeg) {
    if (this.userHasPopupAccess) {
      this.wbdcDialog.showFlight(flight, false);
    }
  }
  openWbdc(flight: SvFlightLeg) {
    if (this.userHasPopupAccess) {
      this.wbdcDialog.showFlight(flight, true);
    }
  }
  hideWbdc(flight: SvFlightLeg) {
    if (this.userHasPopupAccess) {
      this.wbdcDialog.hideFlight(flight);
    }
  }

  openFlightInfo(openWindow: boolean, flight: SvFlightLeg) {
    if (this.userHasPopupAccess && openWindow) {
      // console.log(JSON.stringify(flight), flight);
      const flightKey = flight.flightKey;
      const url =
        `/flightinfo?flightNumber=${flightKey.flightNumber}&depStation=${flightKey.depStation}` +
        `&originDate=${flightKey.originDate}&dupDepNumber=${flightKey.dupDepNumber}`;
      window.open(
        url,
        `flight_info_${flightKey.flightNumber}_${flightKey.depStation}_${flightKey.dupDepNumber}_${flightKey.originDate}`,
        'width=950,height=900,toolbar=0,titlebar=myWindow'
      );
    }
  }

  openTemperature(flight: SvFlightLeg) {
    if (this.userHasPopupAccess) {
      this.temperatureDialog.showTemperature(flight.flightKey.depStation);
    }
  }

  openSupervisorNoteDialog(flight: SvFlightLeg) {
    if (this.userHasPopupAccess) {
      this.supervisorNoteDialog.openDialog(flight);
    }
  }

  private openTransferFlightDialog() {
    let openDialog = false;
    // TODO remove test data
    // this.selectedFlights.push(this.addFlight());
    const flightCount = this.selectedFlights.length;


  //TRKR839
  if(SvUserRole.SUPPORT === this.roleGuardService.authGuardService.svUserInfo.role){
   this.displayRoleTransferWarning();
  }else{
    if (flightCount === 0) {
      this.displayMinSelectionTransferWarning();
    } else if (flightCount > TRANSFER_LIMIT) {
      this.displayMaxSelectionTransferWarning(flightCount);
    } else {
      const transferFlightCount = this.transferFlightDialogs.length;
      if (transferFlightCount > 0) {
        const lastElem = this.transferFlightDialogs[transferFlightCount - 1];
        if (lastElem.visible && !lastElem.transferStarted) {
          this.displayMaxTransferWarning();
        } else {
          openDialog = true;
        }
      } else {
        openDialog = true;
      }
    }

    if (openDialog) {
      const transferDialog = new SvTransferFlightDialog(
        this.svDialogService, this.svTimeZoneService,
        this.selectedFlights, this.transferCount,
        this.roleGuardService.authGuardService,
        this.transferSelectedCallback
      );
      transferDialog.openTransferDialog();
      this.transferFlightDialogs.push(transferDialog);
      this.transferCount++; // increase the dialog count
    }
  }
}

  private transferSelectedCallback = (flightKey: SvFlightLegKey) => {
    // console.log('transferSelectedCallback', flightKey);
    const removeIndex = this.findSelectedIndex(flightKey);
    // console.log('transferSelectedCallback removeIndex', removeIndex);
    if (removeIndex >= 0) { this.selectedFlights.splice(removeIndex, 1); }
  }
  private findSelectedIndex(flightKey: SvFlightLegKey): number {
    for (let index = 0; index < this.selectedFlights.length; index++) {
      if (SvUtilities.isSameFlight(this.selectedFlights[index].flightKey, flightKey, this.svTimeZoneService)) {
        // console.log('isSameFlight', index, this.selectedFlights[index].flightKey, flightKey);
        return index;
      }
    }

    return -1;
  }

  private openTransferHistoryDialog() {
    this.transferFlightHistoryDialog.openTransferHistoryDialog();
  }

  openSupervisorNote(flight: SvFlightLeg) {
    if (this.userHasPopupAccess) {
      this.supervisorNoteDialog.openDialog(flight);
    }
  }

  // private addFlight(): SvFlightLeg {
  //   const flight = new SvFlightLeg();
  //   flight.flightKey = {
  //     depStation: 'DFW',
  //     flightNumber: 5,
  //     originDate: 123,
  //     dupDepNumber: 0
  //   };

  //   return flight;
  // }
  private displayMaxTransferWarning() {
    this.displayTransferWarning(
      'Start or cancel the last transfer before beginning another'
    );
  }
  private displayMinSelectionTransferWarning() {
    this.displayTransferWarning('Select at least one flight');
  }

  //TRKR839
  private displayRoleTransferWarning() {
    this.displayTransferWarning('Transfers not allowed for your role');
  }

  private displayMaxSelectionTransferWarning(flightCount: number) {
    this.displayTransferWarning(
      `Can not transfer more than ${TRANSFER_LIMIT} flights in one transaction and there are ${flightCount} flights selected.`
    );
  }
  private displayTransferWarning(message: string) {
    this.messageService.clear('transferWarning');
    this.messageService.add({
      key: 'transferWarning',
      life: 10000,
      severity: 'warn',
      summary: 'Transfer Warning',
      detail: message
    });
    // this.messageService.add({ severity: 'warn', summary: 'Transfer Warning', detail: 'message' });
  }

  public leadingZeroes(value) {
    let zCtr = 0;

    if (!_.isNil(value)) {
      const valueLength = (value.toString().length);
      zCtr = 8 - valueLength;
      if (zCtr !== 0) {
        for (let i = 0; i < zCtr; i++) {
          value = '0' + value.toString();
        }
      }
    }

    return value;
  }
}
