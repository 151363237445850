import { Component, OnInit, OnDestroy, AfterViewInit, ViewChild, Renderer2 } from '@angular/core';
import { MessageService, MenuItem } from 'primeng/api';
import { Subscription } from 'rxjs';
import { SvFlightLeg } from 'src/app/model/sv-flight-leg';
import { SvStorageService } from 'src/app/services/sv-storage-listener.service';
import { SvReminderService, SvReminderFlight, SvReminderAckFlight, SvCreateReminder } from './sv-reminder.services';
import { SvReminderDialog } from './sv-reminder-dialog';
import { SvDialogService } from 'src/app/services/services-dialog/sv-dialog.service';
import { AuthGuardService } from 'src/app/guards/auth-guard.service';
import { isNullOrUndefined } from 'util';
import * as _ from 'lodash';
import { Toast } from 'primeng/toast';
import { PingAuthenticationService } from '@techops-ui/ping-authentication';  //TRKR839

const minPageRows = 30;
// const minPageRows = 5;
// TODO change back to showing more pages when scrolling has been improved
const minPageBack = 3, minPageForward = 3;
// const minPageBack = 1, minPageForward = 1;
const defaultMessage = 'No data for this criteria';
const errorTimeMilliseconds = 2 * 60 * 1000;

const TRANSFER_LIMIT = 100;

@Component({
  selector: 'sv-reminder',
  templateUrl: './sv-reminder.component.html',
  providers: [MessageService]
})
export class SvReminderComponent implements OnInit, OnDestroy, AfterViewInit {

  // private svReminderDialog = new SvReminderDialog(this.svDialogService, this.storageService,
  //   this.roleGuardService.authGuardService.svUserInfo.empId);

  private reminderDialogSubscription: Subscription;
  private reminderWsTimerSubscription: Subscription;

  // gain access to the ngx table
  @ViewChild('reminderToast') reminderToasts: Toast;

  // reminders: SvReminderToast[] = [];
  reminderDialog = new SvReminderDialog(this.svDialogService, this.reminderService, this.authGuardService.svUserInfo.empId);

  constructor(private authService: PingAuthenticationService, //TRKR839
    private reminderService: SvReminderService, private messageService: MessageService,
    private storageService: SvStorageService, private svDialogService: SvDialogService,
    private authGuardService: AuthGuardService, private renderer: Renderer2) {
  }
  // constructor(
  //   private router: Router, private svRouteService: SvRouteService, private svFlightLegService: SvFlightLegService,
  //   private svSidebarToggleService: SvSidebarToggleService, public svTimeZoneService: SvTimeZoneService,
  //   private svDialogService: SvDialogService,
  //   private roleGuardService: RoleGuardService, private messageService: MessageService,
  //   private storageService: StorageService) {

  // }
  ngOnInit() {
    // this.reminderService.
  }
  ngAfterViewInit() {
    // add subscription to know when to open the noditifaction dialog for a flight
    this.reminderDialogSubscription = this.reminderService.openDialog$.subscribe(flight => this.openReminder(flight));

    // console.log('reminderToasts', this.reminderToasts);

    // start timer to retrieve reminders after this view has been initialized since the timer can modify the view
    this.reminderService.activeAdd$.subscribe(reminderFlight => {
      const messageData = { reminderFlight, snoozeMenuitems: this.buildSnoozeMenuItems(reminderFlight) };
      // console.log('adding toast with flight', reminderFlight, messageData);
      this.messageService.add({ key: 'reminders', closable: false, data: messageData, sticky: true });
      this.reminderService.reminderAdded(reminderFlight.flight);
    });
    this.reminderService.activeRemove$.subscribe(reminderFlight => {
      // console.log('removing toast with flight', reminderFlight);
      this.close(reminderFlight);
      this.reminderService.reminderRemoved(reminderFlight.flight);
    });

    // set liseteners for pending reminders
    this.reminderService.pendingAdd$.subscribe(reminderFlight => { this.reminderService.reminderAdded(reminderFlight.flight); });
    this.reminderService.pendingRemove$.subscribe(reminderFlight => { this.reminderService.reminderRemoved(reminderFlight.flight); });

    // start checking for reminders
    this.reminderService.startWsInterval();
  }
  buildSnoozeMenuItems(flight: SvReminderFlight): MenuItem[] {
    const snoozeMenuitems: MenuItem[] = [
      { label: '5 minutes', command: () => { this.snoozeReminder(flight, 5); } },
      { label: '10 minutes', command: () => { this.snoozeReminder(flight, 10); } },
      { label: '15 minutes', command: () => { this.snoozeReminder(flight, 15); } }
    ];

    return snoozeMenuitems;
  }
  ngOnDestroy() {
    this.authService.startSignOut(); //TRKR839
    //------ Commented out under TRKR839 ------
    //this.messageService.clear();
    //this.reminderDialogSubscription.unsubscribe();
    //this.reminderWsTimerSubscription.unsubscribe();
  }

  private close(reminderFlight: SvReminderFlight) {
    // console.log('close data', reminderFlight);

    if (!isNullOrUndefined(this.reminderToasts.messages)) {
      for (let i = 0; i < this.reminderToasts.messages.length; i++) {
        const message = this.reminderToasts.messages[i];
        // console.log('comparing object', message);
        if (!isNullOrUndefined(message) && !isNullOrUndefined(message.data)) {
          // console.log('comparing flight', message.data.reminderFlight);
          if (_.isEqual(message.data.reminderFlight.flight, reminderFlight.flight)) {
            // console.log('found match', message.data.flight, reminderFlight.flight);
            this.reminderToasts.onMessageClose({ index: i, message: message });
            break;
          }
        }
      }
    }
  }

  private snoozeReminder(reminder: SvReminderFlight, minutes: number = 5) {
    // console.log(`snooze for ${minutes} minutes`, reminder);
    const snooze = new SvCreateReminder(reminder.flight, reminder.note, minutes, this.authGuardService.svUserInfo.empId);
    // this.renderer.addClass();
    reminder.working = true;
    // console.log('snooze test', _.isEqual(snooze, snooze), snooze);

    this.svDialogService.snoozeReminder(snooze).subscribe(
      // TODO display a message if the snooze failed
      data => {
        if (!isNullOrUndefined(data)) {
          // console.log(`flight successfully snoozed ${reminder.working}`, snooze, data);
          this.close(reminder);
          this.reminderService.updateReminders();
          // this.reminderService.reminderModified(snooze.flight);
          // } else {
          // console.log('snooze failed', snooze, data);
        }
      },
      error => { console.log('snooze error', snooze, error); },
      () => { reminder.working = false; }
    );
  }
  acknowledgeReminder(reminder: SvReminderFlight) {
    const ackFlight = new SvReminderAckFlight(reminder.flight, this.authGuardService.svUserInfo.empId);
    reminder.working = true;
    // console.log(`acknowledge flight`, ackFlight);
    this.svDialogService.acknowledgeReminder(ackFlight).subscribe(
      // TODO display a message if the ack failed
      data => {
        if (!isNullOrUndefined(data)) {
          // console.log('flight successfully acknowledged', ackFlight, data);
          this.close(reminder);
          this.reminderService.updateReminders();
          this.reminderService.reminderRemoved(ackFlight.flight);
          // } else {
          //   console.log('ack failed', ackFlight, data);
        }
      },
      error => { console.log('ack error', ackFlight, error); },
      () => { reminder.working = false; }
    );
  }

  private openReminder(flight: SvFlightLeg) {
    this.reminderDialog.open(flight);
  }
  // saveReminder() {
  //   this.reminderDialog.saveChanges();
  // }
}

// class SvReminderToast {
//   flightStr: string;
//   // opened: boolean;
//   constructor(public reminderFlight: SvReminderFlight) {
//     // this.title = `Reminder - Flight ${flight.flight.flightNumber}/${flight.originDayStr} ${flight.flightKey.depStation}`;
//     this.flightStr = SvUtilities.getUniqueKeyStr(reminderFlight.flight);
//   }
// }
