import { SvDialogService } from '../services/services-dialog/sv-dialog.service';
import { SvFlightLegKey, SvFlightLeg } from '../model/sv-flight-leg';
import { HttpErrorResponse } from '@angular/common/http';
import { isNullOrUndefined } from 'util';

export class SvSupervisorNoteDialog {

    visible: boolean;
    header: string;
    flight: SvFlightLeg;
    // loading = true;
    // loadError: boolean;
    supervisorNote: string;
    savingData: boolean;
    positionLeft: number;
    positionTop: number;

    messages = [];

    constructor(private svDialogService: SvDialogService, private empId: number) { }

    openDialog(flight: SvFlightLeg) {
        // TODO could enhance to only retrieve if:
        // 1. First station opened
        // 2. Different station
        // 3. Same station, but stale data (more than XX minutes old)
        this.visible = true;
        // this.station = station;
        this.header = `SV Note ${flight.flightKey.flightNumber}/${flight.originDayStr} ${flight.flightKey.depStation}`;
        this.flight = flight;
        // this.loading = true;
        this.supervisorNote = flight.supervisorNote;
    }

    save() {
        console.log('saving note', this.supervisorNote);
        this.savingData = true;

        this.svDialogService.saveSupervisorNote(new SvSupervisorNoteFlight(this.flight.flightKey, this.supervisorNote, this.empId)).subscribe(
            (data) => {
                console.log('saving note', data);
                // save the supervisor note
                if (isNullOrUndefined(data)) { this.messages = [{ severity: 'error', summary: 'Error', detail: 'Error saving note; please retry' }]; }
                else {
                    if (isNullOrUndefined(this.supervisorNote) || this.supervisorNote.trim() === '') { this.flight.supervisorNote = undefined; }
                    else { this.flight.supervisorNote = this.supervisorNote; }
                    this.closeDialog();
                }
            },
            (error: HttpErrorResponse) => {
                console.log('saving note error:', error);
                this.messages = [{ severity: 'error', summary: 'Error', detail: 'Error saving note; please retry' }];
            },
            () => { this.savingData = false; }
        );
    }

    closeDialog() {
        this.visible = false;
    }
}

export class SvSupervisorNoteFlight {
    flight: SvFlightLegKey;
    supervisorNote: string;
    empId: number;

    constructor(flight: SvFlightLegKey, supervisorNote: string, empId: number) {
        this.flight = flight;
        this.supervisorNote = supervisorNote;
        this.empId = empId;
    }
}
