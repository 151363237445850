import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { WS_BASE } from '../services-ws/sv-ws-constants';

const WS_USER_MANAGEMENT_BASE = '/user-info-details';
const WS_USER_MANAGEMENT_RETRIEVE_ALL = '/retrieve-all-users';
const WS_USER_MANAGEMENT_CREATE = '/create-new-user';
const WS_USER_MANAGEMENT_UPDATE = '/update-user';
const WS_USER_MANAGEMENT_DELETE = '/delete-user';

@Injectable({ providedIn: 'root' })
export class SvUserManagementService {

  private openUserManagementSource = new Subject();
  openedUserManagement$ = this.openUserManagementSource.asObservable();

  constructor(private http: HttpClient) { }

  openUserManagement() { this.openUserManagementSource.next(); }

  public retrieveUsers() {
    // const dataRequest = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this.http.get<User[]>(`${WS_BASE}${WS_USER_MANAGEMENT_BASE}${WS_USER_MANAGEMENT_RETRIEVE_ALL}`);
  }

  public createUser(userInfo: User) {
    const dataRequest = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this.http.post<User[]>(`${WS_BASE}${WS_USER_MANAGEMENT_BASE}${WS_USER_MANAGEMENT_CREATE}`, userInfo, dataRequest);
  }

  public updateUser(userInfo: User) {
    const dataRequest = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this.http.put<User[]>(`${WS_BASE}${WS_USER_MANAGEMENT_BASE}${WS_USER_MANAGEMENT_UPDATE}`, userInfo, dataRequest);
    // //TRKR839 - Switched to POST, since PUT was returning 501 error
    // return this.http.put<User[]>(`${WS_BASE}${WS_USER_MANAGEMENT_BASE}${WS_USER_MANAGEMENT_UPDATE}`, userInfo, dataRequest); //TRKR839
  }

 public deleteUser(employeeId: number) {
//  public deleteUser(userInfo: User) { //TRKR839
 let httpParams = new HttpParams();
 httpParams = httpParams.append('employeeId', employeeId.toString());
 const dataRequest = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }), params: httpParams };
    //const dataRequest = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) }; //TRKR839
    return this.http.delete<User[]>(`${WS_BASE}${WS_USER_MANAGEMENT_BASE}${WS_USER_MANAGEMENT_DELETE}`, dataRequest);
    // return this.http.post<User[]>(`${WS_BASE}${WS_USER_MANAGEMENT_BASE}${WS_USER_MANAGEMENT_DELETE}`, userInfo, dataRequest); //TRKR839
  }
}

export class User {
  employeeId: number;
  userRole: string;
  modifiedUser: number;
  // this is not used by the frontend
  // modifiedTimestamp: string;
}
