import {
  Component, Input, Output, EventEmitter, HostListener, DoCheck,
  ChangeDetectionStrategy, KeyValueDiffer, ChangeDetectorRef, KeyValueDiffers, OnInit, OnDestroy
} from '@angular/core';
import { MouseEvent } from '../../events';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'datatable-row-wrapper',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './body-row-wrapper.component.html',
  host: { class: 'datatable-row-wrapper' }
})
export class DataTableRowWrapperComponent implements OnInit, DoCheck, OnDestroy {

  @Input() innerWidth: number;
  @Input() rowDetail: any;
  @Input() groupHeader: any;
  @Input() offsetX: number;
  @Input() detailRowHeight: any;
  @Input() row: any = 0; //TRKR839
  @Input() groupedRows: any;
  @Output() rowContextmenu = new EventEmitter<{ event: MouseEvent, row: any }>(false);

  @Input() set rowIndex(val: number) {
    this._rowIndex = val;
    this.rowContext.rowIndex = val;
    this.groupContext.rowIndex = val;
    this.cd.markForCheck();
  }

  get rowIndex(): number {
    return this._rowIndex;
  }

  @Input() set expanded(val: boolean) {
    this._expanded = val;
    this.groupContext.expanded = val;
    this.rowContext.expanded = val;
    this.cd.markForCheck();
  }

  get expanded(): boolean {
    return this._expanded;
  }

  groupContext: any = {
    group: this.row,
    expanded: this.expanded,
    rowIndex: this.rowIndex
  };

  rowContext: any = {
    row: this.row,
    expanded: this.expanded,
    rowIndex: this.rowIndex
  };

  private rowDiffer: KeyValueDiffer<{}, {}>;
  private _expanded: boolean = false;
  private _rowIndex: number;

  // private doCheckSubject$: Subject<any>;
  // private doCheckSubscription: Subscription;

  constructor(private cd: ChangeDetectorRef, private differs: KeyValueDiffers) {
    this.rowDiffer = differs.find({}).create();
  }

  ngOnInit() {
    // console.log('row ngOnInit');
    // this.doCheckSubject$ = new Subject();
    // this.doCheckSubscription = this.doCheckSubject$.pipe(debounceTime(50))
    //   .subscribe((row) => { this.ngDoCheckChanges(row); });
  }

  ngDoCheck(): void {
    // console.log('body-row-wrapper doCheck');
    if (this.rowDiffer.diff(this.row)) {
      // this.doCheckSubject$.next(this.row);
      this.ngDoCheckChanges(this.row);
    }
  }

  private ngDoCheckChanges(row: any) {
    // console.log('body-row-wrapper doCheck inner');
    // if (this.rowDiffer.diff(row)) {
    // console.log('body-row-wrapper doCheck inner CHANGES');
    this.rowContext.row = row;
    this.groupContext.group = row;
    this.cd.markForCheck();
    // }
  }

  ngOnDestroy() {
    // console.log('row ngOnDestroy');
    // this.doCheckSubscription.unsubscribe();
    // this.doCheckSubscription = null;
    // this.doCheckSubject$ = null;
  }

  @HostListener('contextmenu', ['$event'])
  onContextmenu($event: MouseEvent): void {
    this.rowContextmenu.emit({ event: $event, row: this.row });
  }

  getGroupHeaderStyle(): any {
    const styles = {};

    styles['transform'] = 'translate3d(' + this.offsetX + 'px, 0px, 0px)';
    styles['backface-visibility'] = 'hidden';
    styles['width'] = this.innerWidth;

    return styles;
  }
}
