import { Component } from '@angular/core';

@Component({
  selector: 'sv-header-desk-load',
  templateUrl: './sv-header-desk-load.component.html'
})
export class SvHeaderDeskLoadComponent {

  constructor() { }
}
